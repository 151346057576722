import {Component, ElementRef, HostListener, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {ContactUsService} from "../../../shared/services/contact-us.service";
import {AlertService} from "../../../shared/services/alert.service";

@Component({
    selector: 'app-server-migration-monitoring',
    templateUrl: './server-migration-monitoring.component.html',
    styleUrls: ['./server-migration-monitoring.component.scss']
})
export class ServerMigrationMonitoringComponent implements OnInit {

    @ViewChild('contactFormModal') contactFormModal!: TemplateRef<any>;
    @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
    isModalOpen = false;
    contactForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    successMessage: string;
    service1 = [
        {text: 'AWS'},
        {text: 'GCP'},
        {text: 'Azure'},
    ];
    service2 = [
        {text: 'E-mail systems'},
        {text: 'Cloud Migration ( P2V, V2V )'},
        {text: 'Data center migration to Co-Lo or Cloud'},
        {text: 'Installation of new IT systems on large, medium or small scale'},
    ];
    service3 = [
        {text: 'Network Monitoring'},
        {text: 'Service Monitoring'},
        {text: 'Endpoint Monitoring'},
    ];
    modules = [
        {text: 'Admissions Management'},
        {text: 'Attendance Management'},
        {text: 'Fees Management'},
        {text: 'Exams Management'},
        {text: 'Library Management'},
        {text: 'Payroll Management'},
        {text: 'Organized Department'},
        {text: 'Record Management'},
    ];
    features = [
        {icon: 'assets/img/landing-new/argus/monitoring.png', text: 'Server Setup and Configuration'},
        {icon: 'assets/img/landing-new/argus/analytics.png', text: 'Performance Monitoring'},
        {icon: 'assets/img/landing-new/argus/custom.png', text: 'Security Management'},
        {icon: 'assets/img/landing-new/argus/user.png', text: 'Backup and Disaster Recovery'},
        {icon: 'assets/img/landing-new/argus/multi.png', text: 'Updates and Patch Management'},
        {icon: 'assets/img/landing-new/argus/hybrid.png', text: 'Load Balancing'},
        {icon: 'assets/img/landing-new/argus/automated.png', text: 'Scalability'},
        {icon: 'assets/img/landing-new/argus/scalability.png', text: 'Remote Management'},
        {icon: 'assets/img/services/ongoing_support.jpeg', text: 'Resource Allocation'}

    ];
    whyChoose1 = [
        {
            header: 'Amazon Web Services (AWS)',
            text: 'AWS offers a wide array of serverless services, including AWS Lambda, which enables you to run code in response to events without provisioning or managing servers. This pay-as-you-go model allows businesses to scale applications effortlessly and only pay for the compute time consumed. With AWS, you can easily integrate with other services like Amazon S3 for storage, Amazon DynamoDB for databases, and Amazon API Gateway for creating and managing APIs, providing a comprehensive ecosystem for building modern applications.'
        },
        {
            header: 'Google Cloud Platform (GCP)',
            text: 'GCP\'s serverless offerings, such as Cloud Functions and Cloud Run, allow developers to create applications without worrying about the underlying infrastructure. Cloud Functions is designed for event-driven workloads, enabling automatic scaling based on incoming requests, while Cloud Run provides a fully managed environment for deploying containerized applications. GCP also integrates seamlessly with other Google services, such as Firebase for mobile app development and BigQuery for data analytics, making it an ideal choice for businesses looking to leverage data-driven insights.'
        },
        {
            header: 'Microsoft Azure',
            text: 'Azure\'s serverless computing capabilities include Azure Functions, which enable you to execute code in response to triggers such as HTTP requests or timers without managing servers. With Azure Logic Apps, you can automate workflows and integrate applications effortlessly, enhancing productivity. Azure\'s serverless solutions support multiple programming languages and are highly scalable, allowing businesses to respond to varying workloads while minimizing costs. Additionally, Azure integrates well with services like Azure Storage and Azure Event Grid, creating a flexible environment for building robust applications.'
        }
    ];
    whyChoose2 = [
        {
            header: 'E-mail Systems Migration',
            text: 'Our E-mail Systems Migration service helps organizations transition from legacy email systems to modern cloud-based platforms such as Microsoft 365 or Google Workspace. We conduct a thorough assessment of your current email environment, ensuring that all data, including emails, contacts, and calendars, are securely migrated with minimal disruption to daily operations. Our team utilizes best practices to ensure data integrity and continuity during the migration process, allowing your organization to enhance collaboration and productivity with advanced features and improved security.'
        },
        {
            header: 'Data Center Migration to Co-Lo or Cloud',
            text: 'We offer comprehensive Data Center Migration services that assist businesses in relocating their on-premises data centers to colocation (Co-Lo) facilities or cloud environments. Our experienced team evaluates your current data center setup and develops a customized migration strategy tailored to your specific needs. We manage the entire migration process, from initial planning and risk assessment to execution and post-migration support, ensuring that your applications remain online throughout the transition. By moving to Co-Lo or the cloud, your organization can benefit from greater scalability, improved cost-efficiency, and enhanced disaster recovery options.'
        },
        {
            header: 'Installation of New IT Systems',
            text: 'Our Installation of New IT Systems service caters to organizations of all sizes—large, medium, or small—looking to implement new technology solutions. We oversee the entire installation process, starting with understanding your business requirements and designing a solution that aligns with your goals. Our team ensures that the new systems are configured correctly, integrated with existing infrastructures, and optimized for performance. Whether you are upgrading your current systems or implementing new solutions, our expertise guarantees a smooth installation process that minimizes downtime and maximizes efficiency.'
        },
        {
            header: 'Cloud Migration (P2V, V2V)',
            text: 'Our Cloud Migration services include both Physical-to-Virtual (P2V) and Virtual-to-Virtual (V2V) migrations. In P2V migrations, we convert physical servers into virtual machines that can be hosted in cloud environments, enabling your organization to take advantage of cloud benefits like scalability and flexibility. In V2V migrations, we facilitate the transfer of virtual machines from one cloud platform to another, optimizing resource utilization and performance. Our cloud migration solutions are designed to ensure that your workloads are transitioned seamlessly, allowing you to leverage the power of cloud computing while maintaining operational continuity.'
        }
    ];
    whyChoose3 = [
        {
            header: 'Network Monitoring',
            text: 'Our Network Monitoring service enables organizations to gain visibility into their network performance and security. We utilize advanced tools to monitor network traffic, bandwidth usage, and device health, ensuring that any anomalies or potential issues are detected and addressed promptly. With real-time alerts and detailed reporting, our network monitoring helps businesses identify bottlenecks, optimize performance, and enhance overall network reliability. This proactive approach minimizes downtime and ensures that your network infrastructure supports your business operations effectively.'
        },
        {
            header: 'Service Monitoring',
            text: 'Our Service Monitoring service focuses on tracking the performance and availability of critical applications and services. We employ comprehensive monitoring solutions to assess key metrics such as response times, error rates, and user experience. By continuously monitoring the health of your applications, we can detect issues before they impact end users, allowing for swift remediation. Our service monitoring not only ensures that your applications are running optimally but also provides valuable insights into usage patterns and performance trends, enabling informed decision-making for future improvements.'
        },
        {
            header: 'Endpoint Monitoring',
            text: 'Our Endpoint Monitoring service provides visibility and control over all endpoints within your organization, including desktops, laptops, and mobile devices. We implement robust monitoring solutions that track device performance, security compliance, and software updates. This proactive approach helps identify potential vulnerabilities and unauthorized access attempts, ensuring that your endpoints remain secure. By keeping a close eye on endpoint activity, we can respond quickly to security threats and maintain compliance with organizational policies, providing peace of mind for your business operations.'
        }
    ];
    offerings = [
        {
            text: 'Holistic Approach'
        },
        {
            text: 'Modular Flexibility'
        },
        {
            text: 'Real-Time Insights'
        },
        {
            text: 'Enhanced Security'
        },
        {
            text: 'Scalability and Adaptability'
        },
    ];

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private contactService: ContactUsService,
        private alertService: AlertService
    ) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.contactForm = this.fb.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            contact: [''],
            subject: ['', Validators.required],
            message: ['', Validators.required]
        });
    }

    openModal() {
        this.isModalOpen = true;
    }

    closeModal() {
        this.isModalOpen = false;
    }

    handleOverlayClick(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (!target.closest('.modal-dialog')) {
            this.closeModal();
        }
    }

    submit() {
        this.submitted = true;
        if (!this.contactForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().name) ? this.contactForm.getRawValue().name : '',
                'email': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().email) ? this.contactForm.getRawValue().email : '',
                'contact': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().contact) ? this.contactForm.getRawValue().contact : '',
                'subject': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().subject) ? this.contactForm.getRawValue().subject : '',
                'message': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().message) ? this.contactForm.getRawValue().message : '',
            };
            this.contactService.create(params).subscribe(data => {
                this.successMessage = 'Your form has been submitted successfully!';
                this.submitted = false;
                this.contactForm.patchValue({
                    'name': '',
                    'email': '',
                    'contact': '',
                    'subject': '',
                    'message': ''
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                    if (error['error']['errors'].subject && error['error']['errors'].subject.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].subject[0]);
                        return;
                    }
                    if (error['error']['errors'].message && error['error']['errors'].message.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].message[0]);
                        return;
                    }
                }
            });
        }
    }

    playVideo() {
        const video = this.videoElement.nativeElement;
        const thumbnail = document.getElementById('video-thumbnail');
        if (video) {
            video.classList.remove('d-none');
            video.play();
            if (thumbnail) {
                thumbnail.style.display = 'none';
            }
            const playButton = document.querySelector('.play-button');
            if (playButton) {
                playButton.classList.add('d-none');
            }
        }
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }

    @HostListener('window:scroll', [])
    onWindowScroll() {
        const elements = document.querySelectorAll('.animate-on-scroll');
        const scrollPosition = window.innerHeight + window.scrollY;

        elements.forEach((element) => {
            const elementPosition = element.getBoundingClientRect().top + window.scrollY;
            if (scrollPosition >= elementPosition) {
                element.classList.add('visible'); // Add the visible class when in view
            }
        });

        const elements1 = document.querySelectorAll('.animate-on-scroll-right');
        const scrollPosition1 = window.innerHeight + window.scrollY;
        elements1.forEach((element) => {
            const elementPosition = element.getBoundingClientRect().top + window.scrollY;

            if (scrollPosition1 >= elementPosition) {
                element.classList.add('visible'); // Add the visible class when in view
            }
        });
    }


}
