<section class="banner">
    <div class="container-fluid bg-sea-green mt-5">
        <div class="container mt-5 pt-5">
            <div class="row g-5">
                <div class="col-md-6 d-flex text-center text-md-start align-items-center">
                    <div>
                        <h1 class="fw-bolder font-56"><span
                            class="brand-primary-text-color">Empower Your Business with</span>
                            Serverless & Integration Solutions</h1>
                        <p class="my-4 fs-6 text-secondary text-wrap f-20 justify-text">At Volmint Technologies, we
                            offer cutting-edge Serverless Computing solutions that empower businesses to build and scale
                            applications without the complexities of managing server infrastructure. Our Migration and
                            Integration services ensure a seamless transition to cloud environments, enabling clients to
                            leverage the full potential of cloud technologies. Additionally, we provide comprehensive
                            Monitoring services to maintain optimal performance and reliability of applications,
                            ensuring that your business operations run smoothly and efficiently. With our expertise, we
                            help you innovate and grow in the ever-evolving digital landscape.
                        </p>
                        <button class="btn bg-white px-5 rounded-0 border-0 shadow-lg fw-bold hover-effect"
                                (click)="openModal()">View Demo <i
                            class="ms-3 fa-solid fa-arrow-right"></i>
                        </button>
                        <ng-template #contactFormModal>
                            <app-contact-form [isModalOpen]="isModalOpen"
                                              (closeModal)="closeModal()"></app-contact-form>
                        </ng-template>
                        <div *ngIf="isModalOpen" class="modal-overlay" (click)="handleOverlayClick($event)">
                            <ng-container *ngTemplateOutlet="contactFormModal"></ng-container>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex justify-content-end py-5 d-flex justify-content-center">
                    <div
                        class=" md:mr-0 position-relative h-100 w-100 border-0  d-flex justify-content-center border-radius-10">
                        <img src="assets/img/landing-new/server-migration-monitoring/info.png"
                             alt="Image"
                             class="img-fluid border-radius-10"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container-fluid parallax1">
    <div class="container py-5">
        <div class="row animate-on-scroll">
            <div class="col-md-6 m-0 p-0 text-right">
                <img src="assets/img/landing-new/server-migration-monitoring/server.png"
                     class="img-fluid border-radius-10"
                     alt="Image">
            </div>
            <div class="col-md-6 ps-md-5 py-md-5 py-4">
                <h2 class=" fs-1 fw-bolder text-white">Streamline Your Operations
                    <span class="brand-primary-text-color">with Serverless Computing</span>
                </h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text text-white">At Volmint Technologies, our Serverless
                    Computing service allows businesses to develop and deploy applications efficiently without the
                    overhead of managing server infrastructure. By leveraging cloud providers' capabilities, we enable
                    clients to focus on writing code and delivering value while automatically scaling resources based on
                    demand. This cost-effective solution enhances agility, reduces operational complexity, and
                    accelerates time-to-market, empowering organizations to innovate rapidly and respond to changing
                    business needs seamlessly.</p>
                <div class="d-lg-flex justify-content-between w-90 flex-wrap d-none">
                    <div class="fw-bold my-1 px-5 py-3 brand-primary-bg text-white fixed-width text-center"
                         *ngFor="let item of service1">
                        {{ item.text }}
                    </div>
                </div>
                <div class="col-12 d-block d-lg-none mt-4">
                    <p class="fs-6 text-white" *ngFor="let item of service1"><img
                        src="assets/img/project/check.png"
                        alt="Image"
                        class="img-fluid"> {{ item.text }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid brand-primary-bg">
    <div class="container  py-5">
        <h1 class="text-white text-center mt-5 fw-bold justify-text">Comprehensive Serverless Computing Solutions</h1>
        <p class="text-white text-center">At Volmint Technologies, we offer robust Serverless Computing services
            utilizing the leading cloud platforms: Amazon Web Services (AWS), Google Cloud Platform (GCP), and Microsoft
            Azure. Each of these platforms provides unique features and benefits, allowing us to tailor solutions that
            best meet your business needs.
        </p>
        <div class="row gy-5 py-5">
            <div class="col-md-4" *ngFor="let item of whyChoose1; let i = index">
                <div class="h-w-40 text-center rounded-circle bg-white pt-2 fw-bold text-black ">{{ i + 1 }}</div>
                <h3 class="text-white my-4 fw-bold">{{ item.header }}</h3>
                <p class="text-white justify-text">{{
                        item.text
                    }}</p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid parallax2">
    <div class="container py-5">
        <div class="row mt-md-5 animate-on-scroll-right">
            <div class="col-md-6 py-md-5">
                <h2 class=" fs-1 fw-bolder text-white">Seamless Migration and
                    <span class="brand-primary-text-color"> Integration Services</span></h2>
                <p class="fs-6 text-white text-wrap justify-text ">At Volmint Technologies, our Migration and
                    Integration services are designed to facilitate a smooth transition to cloud environments and ensure
                    seamless connectivity between your applications and systems. We specialize in assessing your current
                    infrastructure and developing tailored migration strategies that minimize downtime and mitigate
                    risks. Our expert team employs best practices and advanced tools to migrate your data, applications,
                    and workloads efficiently to cloud platforms like AWS, GCP, and Azure. Additionally, we focus on
                    integrating existing systems and third-party applications to create a cohesive and streamlined
                    ecosystem, enabling your business to leverage cloud capabilities fully. With our comprehensive
                    approach, you can enhance operational efficiency, reduce costs, and accelerate innovation in a
                    secure and scalable environment.
                </p>
                <div class="d-lg-flex justify-content-between w-90 flex-wrap d-none">
                    <div class="fw-bold my-1 px-5 py-3 brand-primary-bg text-white fixed-width text-center"
                         *ngFor="let item of service2">
                        {{ item.text }}
                    </div>
                </div>
                <div class="col-12 d-block d-lg-none mt-4">
                    <p class="fs-6 text-white" *ngFor="let item of service2"><img
                        src="assets/img/project/check.png"
                        alt="Image"
                        class="img-fluid"> {{ item.text }}
                    </p>
                </div>
            </div>
            <div class="col-md-6 pt-5 d-flex justify-content-center">
                <img src="assets/img/landing-new/server-migration-monitoring/integration.png"
                     class="img-fluid w-100 border-radius-10" alt="">
            </div>
        </div>
    </div>
</div>
<div class="container-fluid brand-primary-bg">
    <div class="container  py-5">
        <h1 class="text-white text-center mt-5 fw-bold justify-text">Comprehensive Migration and Integration
            Services</h1>
        <p class="text-white text-center">At Volmint Technologies, our Migration and Integration services are designed
            to facilitate the seamless transition of your business operations to modern infrastructure. We specialize in
            a range of migration solutions, ensuring that your systems are effectively integrated and optimized for
            performance. Here’s a detailed overview of the services we offer:
        </p>
        <div class="row gy-5 py-5">
            <div class="col-md-4" *ngFor="let item of whyChoose2; let i = index">
                <div class="h-w-40 text-center rounded-circle bg-white pt-2 fw-bold text-black ">{{ i + 1 }}</div>
                <h3 class="text-white my-4 fw-bold">{{ item.header }}</h3>
                <p class="text-white justify-text">{{
                        item.text
                    }}</p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid parallax3">
    <div class="container py-5">
        <div class="row mt-md-5 animate-on-scroll">
            <div class="col-md-6 m-0 p-0 text-right">
                <img src="assets/img/landing-new/server-migration-monitoring/monitor.png"
                     class="img-fluid border-radius-10"
                     alt="Image">
            </div>
            <div class="col-md-6 ps-md-5 py-md-5 py-4">
                <h2 class=" fs-1 fw-bolder text-white">Comprehensive
                    <span class="brand-primary-text-color"> Monitoring Services</span></h2>
                <p class="my-4 fs-6 text-white text-wrap justify-text">At Volmint Technologies, our Monitoring
                    services are designed to provide businesses with real-time insights and proactive management of
                    their IT environments. We offer three key monitoring services that ensure the performance, security,
                    and availability of your systems and networks. Here’s a detailed overview of the services we
                    provide:</p>
                <div class="d-lg-flex justify-content-between w-90 flex-wrap d-none">
                    <div class="fw-bold my-1 px-5 py-3 brand-primary-bg text-white fixed-width text-center"
                         *ngFor="let item of service3">
                        {{ item.text }}
                    </div>
                </div>
                <div class="col-12 d-block d-lg-none mt-4">
                    <p class="fs-6 text-white" *ngFor="let item of service3"><img
                        src="assets/img/project/check.png"
                        alt="Image"
                        class="img-fluid"> {{ item.text }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid brand-primary-bg">
    <div class="container  py-5">
        <h1 class="text-white text-center mt-5 fw-bold justify-text">Holistic IT Monitoring Solutions for Enhanced
            Performance and Security</h1>
        <p class="text-white text-center">
            At Volmint Technologies, we deliver Comprehensive IT Monitoring Solutions designed to safeguard and enhance
            the performance of your technological infrastructure. Our proactive monitoring services provide unparalleled
            visibility and control over your IT environment, enabling you to respond swiftly to potential issues while
            optimizing operational efficiency. With a focus on three critical areas—network, service, and endpoint
            monitoring—we ensure that your systems operate at peak performance, security, and reliability.
        </p>
        <div class="row gy-5 py-5">
            <div class="col-md-4" *ngFor="let item of whyChoose3; let i = index">
                <div class="h-w-40 text-center rounded-circle bg-white pt-2 fw-bold text-black ">{{ i + 1 }}</div>
                <h3 class="text-white my-4 fw-bold">{{ item.header }}</h3>
                <p class="text-white justify-text">{{
                        item.text
                    }}</p>
            </div>
        </div>
    </div>
</div>


<div class="container-fluid">
    <div class="container">
        <div class="row gx-4">
            <div class="col-md-6 py-5">
                <h2 class=" fs-1 fw-bolder">
                    IT Consulting Services <span
                    class="brand-primary-text-color">Transforming Business Potential with  </span>Our IT Consulting
                    Services
                </h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">Volmint Technologies' IT Consulting Services
                    empower businesses to unlock their full potential by providing tailored technology solutions across
                    all domains, including application development. By leveraging our expertise, clients can streamline
                    processes, enhance operational efficiency, and innovate their offerings. Our Time and Material-based
                    contracts offer flexibility for dynamic projects, enabling organizations to adapt quickly to
                    changing requirements without incurring unnecessary costs. In contrast, our SLA-based contracts
                    provide peace of mind with defined service levels, ensuring reliability and performance for critical
                    business operations. Together, these services equip our clients with the tools and strategies needed
                    to thrive in a competitive landscape, driving sustainable growth and long-term success.
                </p>
                <button class="btn text-white brand-primary-bg border px-5 rounded-0 my-5 hover-white fw-bold"
                        (click)="openModal()">Book Appointment
                    <i
                        class="ms-3 fa-solid fa-arrow-right"></i>
                </button>
            </div>
            <div class="col-md-6 pt-5 text-right animate-on-scroll">
                <img src="assets/img/landing-new/server-migration-monitoring/business.png"
                     class="ms-2 img-fluid border-radius-10" alt="Image"/>
            </div>
            <div class="col-md-6 pt-3 text-right animate-on-scroll-right">
                <img src="assets/img/landing-new/server-migration-monitoring/why.png"
                     class="w-100" alt="">
            </div>
            <div class="col-md-6 py-5 ps-md-5">
                <h2 class="fs-1 fw-bolder">
                    Why Choose<span
                    class="brand-primary-text-color">  Volmint Technologies?</span></h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">At Volmint Technologies, we pride ourselves
                    on being a trusted partner in your digital transformation journey. Our commitment to excellence is
                    reflected in our comprehensive range of services, tailored solutions, and industry expertise. We
                    leverage cutting-edge technology and best practices to ensure that your business operates at its
                    optimal capacity while maintaining robust security measures. Our team of seasoned professionals
                    brings a wealth of experience and a customer-centric approach, ensuring that we understand your
                    unique needs and deliver solutions that drive tangible results. With a focus on innovation,
                    reliability, and continuous improvement, we empower your organization to stay ahead of the curve in
                    today’s competitive landscape. Choose Volmint Technologies as your strategic partner, and experience
                    the difference of working with a dedicated team that is invested in your success.
                </p>
            </div>
        </div>
    </div>
</div>


<div class="container-fluid">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="col-12">
                    <img src="assets/img/landing-new/mobile-development/faq.png" alt="Image" class="img-fluid w-50"/>
                    <p class="fs-1 fw-bold">Frequently Asked Questions</p>
                    <p class="text-secondary">Have a question that is not answered? You can contact us at</p>
                    <p class="brand-primary-text-color fw-bold">Email: <a href="mailto:support@volmint.com"
                                                                          class="text-decoration-underline brand-primary-text-color">support@volmint.com</a>
                    </p>
                    <button type="submit"
                            class="brand-primary-bg border-0 color-white px-5 py-2 fs-6 mt-4 fw-bold hover-effect"
                            (click)="goTo('/faq')">
                        View All Faqs
                    </button>
                </div>
            </div>
            <div class="col-12 col-md-6 mt-4">
                <div class="accordion" id="faqAccordion">
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span class="fs-6 fw-bold" style="color: #03738C;">
                                    <div class="row">
                                        <div class="col-1">
                                          <div class="encircled-icon float-start">
                                                <i class="fas fa-question fw-bold"></i>
                                          </div>
                                        </div>
                                        <div class="col-11">
                                        What types of cloud platforms do you support for Serverless Computing?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                At Volmint Technologies, we support major cloud platforms including AWS, Google Cloud
                                (GCP), and Microsoft Azure. Our team is well-versed in deploying and managing serverless
                                architectures on these platforms, ensuring scalability, cost-efficiency, and
                                performance.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <span
                            class="fs-6 fw-bold" style="color: #03738C;">
                             <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon float-start">
                                                <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                 <div class="col-11">
                                     What is the benefit of migrating our data center to the cloud or Co-Lo?
                                 </div>
                             </div>
                            </span>
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Migrating your data center to a cloud or colocation facility offers enhanced
                                scalability, reduced operational costs, improved disaster recovery options, and better
                                resource management. We ensure a seamless transition with minimal downtime and provide
                                post-migration support to optimize your new infrastructure.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <span class="fs-6 fw-bold" style="color: #03738C;">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon float-start">
                                                <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                        <div class="col-11">
                                            How do you ensure minimal disruption during IT system installations?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Our IT system installation service is meticulously planned and executed, focusing on
                                minimizing downtime and ensuring smooth integration with existing systems. We conduct
                                thorough testing and provide detailed timelines to ensure minimal impact on your daily
                                operations.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <span class="fs-6 fw-bold" style="color: #03738C;">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon">
                                                <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                        <div class="col-11">
                                          What is the difference between Physical-to-Virtual (P2V) and Virtual-to-Virtual (V2V) cloud migrations?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h2>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Physical-to-Virtual (P2V) migration involves converting physical servers into virtual
                                machines, allowing them to run in cloud environments. Virtual-to-Virtual (V2V) migration
                                involves transferring virtual machines from one cloud platform to another. Both methods
                                offer flexibility and scalability, and we tailor the migration approach based on your
                                specific needs.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingFive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <span class="fs-6 fw-bold" style="color: #03738C;">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon">
                                        <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                        <div class="col-11">
                                           How does your monitoring service improve network and application performance?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h2>
                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Our monitoring services provide real-time insights into the performance of your network,
                                applications, and endpoints. By proactively identifying bottlenecks, potential security
                                threats, and system inefficiencies, we help optimize your infrastructure for peak
                                performance and ensure swift resolution of any issues.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid ums-bottom-bg ps-5 mt-5">
    <div class="container">
        <div class="row align-items-center py-5">
            <div class="col-md-10 col-12">
                <h1 class="text-white">Ready to Take Your Business Growth to the Next Level?</h1>
            </div>
            <div class="col-md-2 col-12">
                <button class="btn bg-white rounded-0 px-5 py-3 text-black fw-bold hover-effect"
                        (click)="goTo('/contact')">Contact Us
                </button>
            </div>
        </div>
    </div>
</div>
