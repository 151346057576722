<section class="banner">
    <div class="container-fluid bg-sea-green mt-5">
        <div class="container mt-5 pt-5">
            <div class="row g-5">
                <div class="col-md-6 d-flex text-center text-md-start align-items-center">
                    <div>
                        <h1 class="fw-bolder font-56"><span
                            class="brand-primary-text-color">Elevate Your Business with</span>
                            Our Premier Public Cloud & Delivery Solutions</h1>
                        <p class="my-4 fs-6 text-secondary text-wrap f-20 justify-text">At Volmint Technologies, we
                            offer comprehensive Public Cloud & Delivery Services designed to enhance your business's
                            efficiency and scalability. Our Managed Public Cloud Services ensure that your cloud
                            infrastructure is expertly managed, allowing you to focus on your core business operations
                            while we handle the technical complexities. Our Database Service provides robust and secure
                            solutions for data storage and management, ensuring that your valuable information is
                            accessible and well-protected. Additionally, our Content Delivery Network (CDN) optimizes
                            the delivery of your content globally, enhancing user experience with faster load times and
                            reliable access. Together, these services empower businesses to leverage the full potential
                            of cloud technology, ensuring seamless operations and improved performance.
                        </p>
                        <button class="btn bg-white px-5 rounded-0 border-0 shadow-lg fw-bold hover-effect"
                                (click)="openModal()">View Demo <i
                            class="ms-3 fa-solid fa-arrow-right"></i>
                        </button>
                        <ng-template #contactFormModal>
                            <app-contact-form [isModalOpen]="isModalOpen"
                                              (closeModal)="closeModal()"></app-contact-form>
                        </ng-template>
                        <div *ngIf="isModalOpen" class="modal-overlay" (click)="handleOverlayClick($event)">
                            <ng-container *ngTemplateOutlet="contactFormModal"></ng-container>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex justify-content-end py-5 d-flex justify-content-center">
                    <div
                        class="  md:mr-0 position-relative h-100 w-100 border-0  d-flex justify-content-center border-radius-10">
                        <img src="assets/img/landing-new/cloud-db-cdn/info.png"
                             alt="Image"
                             class="img-fluid border-radius-10"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container-fluid parallax1">
    <div class="container py-5">
        <div class="row animate-on-scroll">
            <div class="col-md-6 m-0 p-0 text-right">
                <img src="assets/img/landing-new/cloud-db-cdn/cloud.png"
                     class="img-fluid border-radius-10"
                     alt="Image">
            </div>
            <div class="col-md-6 ps-md-5 py-md-5 py-4">
                <h2 class=" fs-1 fw-bolder text-white">Managed Public
                    <span class="brand-primary-text-color"> Cloud Services</span>
                </h2>
                <p class="my-4 fs-6 text-white text-wrap justify-text">Volmint Technologies' Managed Public Cloud
                    Services provide a seamless and efficient cloud experience tailored to your business needs. We take
                    the complexity out of cloud management by handling all aspects of your public cloud infrastructure,
                    from deployment and configuration to monitoring and optimization. Our expert team ensures that your
                    cloud environment is secure, scalable, and cost-effective, allowing you to focus on innovation and
                    growth. With proactive management and 24/7 support, we empower your organization to harness the full
                    potential of the cloud, enhancing operational efficiency and enabling rapid response to market
                    demands.</p>
                <div class="d-lg-flex justify-content-between w-90 flex-wrap d-none">
                    <div class="fw-bold my-1 px-5 py-3 brand-primary-bg text-white fixed-width text-center"
                         *ngFor="let item of service1">
                        {{ item.text }}
                    </div>
                </div>
                <div class="col-12 d-block d-lg-none mt-4">
                    <p class="fs-6 text-white" *ngFor="let item of service1"><img
                        src="assets/img/project/check.png"
                        alt="Image"
                        class="img-fluid "> {{ item.text }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid brand-primary-bg">
    <div class="container  py-5">
        <h1 class="text-white text-center mt-5 fw-bold justify-text">Managed Public Cloud Services</h1>
        <p class="text-white text-center">At Volmint Technologies, our Managed Public Cloud Services encompass a wide
            range of leading cloud platforms, tailored to meet your business needs. We specialize in managing your cloud
            infrastructure across multiple platforms, ensuring optimal performance, security, and scalability.
        </p>
        <div class="row gy-5 py-5">
            <div class="col-md-4" *ngFor="let item of whyChoose1; let i = index">
                <div class="h-w-40 text-center rounded-circle bg-white pt-2 fw-bold text-black ">{{ i + 1 }}</div>
                <h3 class="text-white my-4 fw-bold">{{ item.header }}</h3>
                <p class="text-white justify-text">{{
                        item.text
                    }}</p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid parallax2">
    <div class="container py-5">
        <div class="row mt-md-5 animate-on-scroll-right">
            <div class="col-md-6 py-md-5">
                <h2 class=" fs-1 fw-bolder text-white">Comprehensive Database Services for Modern Applications
                    <span class="brand-primary-text-color"> for Modern Applications</span></h2>
                <p class="fs-6 text-white text-wrap justify-text">At Volmint Technologies, we provide robust
                    Database Services designed to support your business's data management needs effectively. Our
                    solutions encompass leading database technologies from major cloud providers, ensuring your
                    applications are powered by reliable, scalable, and secure databases.
                </p>
                <div class="d-lg-flex justify-content-between w-90 flex-wrap d-none">
                    <div class="fw-bold my-1 px-5 py-3 brand-primary-bg text-white fixed-width text-center"
                         *ngFor="let item of service2">
                        {{ item.text }}
                    </div>
                </div>
                <div class="col-12 d-block d-lg-none mt-4">
                    <p class="fs-6 text-white" *ngFor="let item of service2"><img
                        src="assets/img/project/check.png"
                        alt="Image"
                        class="img-fluid"> {{ item.text }}
                    </p>
                </div>
            </div>
            <div class="col-md-6 pt-5 d-flex justify-content-center">
                <img src="assets/img/landing-new/cloud-db-cdn/serve.png"
                     class="img-fluid w-100 border-radius-10" alt="">
            </div>
        </div>
    </div>
</div>
<div class="container-fluid brand-primary-bg">
    <div class="container  py-5">
        <h1 class="text-white text-center mt-5 fw-bold justify-text">Tailored Database Services for Enhanced Data
            Management</h1>
        <p class="text-white text-center">At Volmint Technologies, we offer specialized Database Services designed to
            meet the diverse needs of modern businesses. Our expertise spans major cloud platforms, ensuring that your
            data management solutions are both efficient and reliable.
        </p>
        <div class="row gy-5 py-5">
            <div class="col-md-4" *ngFor="let item of whyChoose2; let i = index">
                <div class="h-w-40 text-center rounded-circle bg-white pt-2 fw-bold text-black ">{{ i + 1 }}</div>
                <h3 class="text-white my-4 fw-bold">{{ item.header }}</h3>
                <p class="text-white justify-text">{{
                        item.text
                    }}</p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid parallax3">
    <div class="container py-5">
        <div class="row mt-md-5 animate-on-scroll">
            <div class=" col-md-6 m-0 p-0 text-right">
                <img src="assets/img/landing-new/cloud-db-cdn/cdn.png"
                     class="img-fluid border-radius-10"
                     alt="Image">
            </div>
            <div class="col-md-6 ps-md-5 py-md-5 py-4">
                <h2 class=" fs-1 fw-bolder text-white">Robust Content Delivery Network (CDN) Solutions
                    <span class="brand-primary-text-color"> for Optimized Performance</span></h2>
                <p class="my-4 fs-6 text-white text-wrap justify-text">At Volmint Technologies, we provide
                    comprehensive Content Delivery Network (CDN) services designed to enhance the speed and reliability
                    of your digital content delivery. Our CDN solutions ensure that your website and applications
                    deliver content quickly and efficiently to users around the globe, improving user experience and
                    engagement.</p>
                <div class="d-lg-flex justify-content-between w-90 flex-wrap d-none">
                    <div class="fw-bold my-1 px-5 py-3 brand-primary-bg text-white fixed-width text-center"
                         *ngFor="let item of service3">
                        {{ item.text }}
                    </div>
                </div>
                <div class="col-12 d-block d-lg-none mt-4">
                    <p class="fs-6 text-white" *ngFor="let item of service3"><img
                        src="assets/img/project/check.png"
                        alt="Image"
                        class="img-fluid"> {{ item.text }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid brand-primary-bg">
    <div class="container  py-5">
        <h1 class="text-white text-center mt-5 fw-bold justify-text">Comprehensive Content Delivery Network (CDN)
            Services for Enhanced User Experience</h1>
        <p class="text-white text-center">
            At Volmint Technologies, we offer a wide array of Content Delivery Network (CDN) services designed to
            optimize the delivery of your digital content. Our robust CDN solutions ensure that your website and
            applications perform at peak efficiency, delivering content quickly and reliably to users across the globe.
        </p>
        <div class="row gy-5 py-5">
            <div class="col-md-4" *ngFor="let item of whyChoose3; let i = index">
                <div class="h-w-40 text-center rounded-circle bg-white pt-2 fw-bold text-black ">{{ i + 1 }}</div>
                <h3 class="text-white my-4 fw-bold">{{ item.header }}</h3>
                <p class="text-white justify-text">{{
                        item.text
                    }}</p>
            </div>
        </div>
    </div>
</div>


<div class="container-fluid">
    <div class="container">
        <div class="row gx-4">
            <div class="col-md-6 py-5">
                <h2 class=" fs-1 fw-bolder">
                    Accelerating <span
                    class="brand-primary-text-color">Business Growth Through </span>Enhanced Content Delivery
                </h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">Volmint Technologies' Content Delivery
                    Network (CDN) services empower businesses to significantly improve their online presence and
                    performance. By leveraging leading CDN solutions, our clients experience faster loading times and
                    reduced latency, which directly translate to enhanced user satisfaction and engagement. With
                    seamless media streaming and high-quality content delivery, businesses can captivate their audience,
                    leading to higher retention rates and conversions. Additionally, our CDN services provide robust
                    security features that protect against cyber threats, ensuring that client data remains secure. By
                    optimizing the delivery of digital content globally, we enable our clients to scale effortlessly,
                    adapt to traffic fluctuations, and enhance their competitive edge in today’s digital marketplace.
                </p>
                <button class="btn text-white brand-primary-bg border px-5 rounded-0 my-5 hover-white fw-bold"
                        (click)="openModal()">Book Appointment
                    <i
                        class="ms-3 fa-solid fa-arrow-right"></i>
                </button>
            </div>
            <div class="col-md-6 pt-5 text-right animate-on-scroll">
                <img src="assets/img/landing-new/cloud-db-cdn/business.png"
                     class="ms-2 img-fluid border-radius-10" alt="Image"/>
            </div>
            <div class="col-md-6 pt-5 text-right animate-on-scroll-right">
                <img src="assets/img/landing-new/cloud-db-cdn/why.png"
                     class="w-100" alt="">
            </div>
            <div class="col-md-6 py-5 ps-md-5">
                <h2 class="fs-1 fw-bolder">
                    Why Choose<span
                    class="brand-primary-text-color"> Volmint Technologies for Your CDN Services?</span></h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">Choosing Volmint Technologies for your
                    Content Delivery Network (CDN) services means partnering with a team of dedicated experts committed
                    to driving your business success. Our extensive experience with leading CDN providers allows us to
                    tailor solutions specifically to your needs, ensuring optimal performance, security, and
                    reliability. We prioritize customer satisfaction and work closely with you to understand your unique
                    requirements, offering personalized support every step of the way. Our proactive approach to
                    monitoring and maintenance guarantees that your content delivery remains seamless, even during peak
                    traffic times. With Volmint Technologies, you gain a trusted ally who not only enhances your digital
                    presence but also helps you stay ahead in the competitive landscape, ensuring your content reaches
                    your audience quickly and effectively.
                </p>
            </div>
        </div>
    </div>
</div>


<div class="container-fluid">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="col-12">
                    <img src="assets/img/landing-new/mobile-development/faq.png" alt="Image" class="img-fluid w-50"/>
                    <p class="fs-1 fw-bold">Frequently Asked Questions</p>
                    <p class="text-secondary">Have a question that is not answered? You can contact us at</p>
                    <p class="brand-primary-text-color fw-bold">Email: <a href="mailto:support@volmint.com"
                                                                          class="text-decoration-underline brand-primary-text-color">support@volmint.com</a>
                    </p>
                    <button type="submit"
                            class="brand-primary-bg border-0 color-white px-5 py-2 fs-6 mt-4 fw-bold hover-effect"
                            (click)="goTo('/faq')">
                        View All Faqs
                    </button>
                </div>
            </div>
            <div class="col-12 col-md-6 mt-4">
                <div class="accordion" id="faqAccordion">
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span class="fs-6 fw-bold" style="color: #03738C;">
                                    <div class="row">
                                        <div class="col-1">
                                          <div class="encircled-icon float-start">
                                                <i class="fas fa-question fw-bold"></i>
                                          </div>
                                        </div>
                                        <div class="col-11">
                                        What is a Content Delivery Network (CDN), and how does it work?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                A CDN is a network of servers distributed across various geographical locations designed
                                to deliver web content efficiently to users. It works by caching your content on
                                multiple servers close to your users, reducing latency and ensuring faster load times.
                                When a user requests content, the CDN serves it from the nearest server, improving the
                                overall user experience.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <span
                            class="fs-6 fw-bold" style="color: #03738C;">
                             <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon float-start">
                                                <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                 <div class="col-11">
                                     How can a CDN improve my website's performance?
                                 </div>
                             </div>
                            </span>
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                A CDN enhances your website's performance by minimizing load times and reducing latency.
                                By caching static content, such as images and videos, on edge servers closer to your
                                users, a CDN decreases the distance data travels, leading to quicker response times and
                                a smoother browsing experience. This can significantly reduce bounce rates and increase
                                user engagement.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <span class="fs-6 fw-bold" style="color: #03738C;">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon float-start">
                                                <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                        <div class="col-11">
                                            What security features do your CDN services provide?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Our CDN services come equipped with robust security features, including DDoS protection,
                                web application firewalls (WAF), and secure token authentication. These measures help
                                safeguard your content and user data from cyber threats, ensuring a secure online
                                experience for your customers.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <span class="fs-6 fw-bold" style="color: #03738C;">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon">
                                                <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                        <div class="col-11">
                                         Will using a CDN affect my SEO?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h2>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Yes, using a CDN can positively impact your SEO. Faster load times improve user
                                experience, which is a crucial ranking factor for search engines. Additionally, CDNs can
                                enhance site reliability and uptime, further contributing to improved search engine
                                rankings.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingFive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <span class="fs-6 fw-bold" style="color: #03738C;">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon">
                                        <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                        <div class="col-11">
                                           How do I get started with your CDN services?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h2>
                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Getting started is easy! Simply contact our team, and we’ll schedule a consultation to
                                understand your specific needs. We will guide you through the implementation process,
                                ensuring a seamless integration of our CDN services with your existing infrastructure.
                                Our dedicated support team will be there to assist you at every step.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid ums-bottom-bg ps-5 mt-5">
    <div class="container">
        <div class="row align-items-center py-5">
            <div class="col-md-10 col-12">
                <h1 class="text-white">Ready to Take Your Business Growth to the Next Level?</h1>
            </div>
            <div class="col-md-2 col-12">
                <button class="btn bg-white rounded-0 px-5 py-3 text-black fw-bold hover-effect"
                        (click)="goTo('/contact')">Contact Us
                </button>
            </div>
        </div>
    </div>
</div>
