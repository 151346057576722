import {Component, ElementRef, OnInit, TemplateRef, ViewChild, AfterViewInit, HostListener} from '@angular/core';
import {Router} from "@angular/router";
import {Meta, Title} from "@angular/platform-browser";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ContactUsService} from "../../../shared/services/contact-us.service";
import {AlertService} from "../../../shared/services/alert.service";

@Component({
    selector: 'app-vcalling',
    templateUrl: './vcalling.component.html',
    styleUrls: ['./vcalling.component.scss']
})
export class VcallingComponent implements OnInit, AfterViewInit {
    @ViewChild('contactFormModal') contactFormModal!: TemplateRef<any>;
    @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
    isModalOpen = false;
    contactForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    buttons = [
        {label: 'Capture Leads', key: 'capture'},
        {label: 'Unified Communication', key: 'unified'},
        {label: 'Efficient Management', key: 'efficient'},
        {label: 'Support Team', key: 'support'}
    ];
    design = {
        capture: [
            {
                image: 'assets/img/landing-new/vcalling/capture.avif',
                description: 'Accomplish your monthly targets with the V-Calling quickly. V-Calling allows users to effortlessly gather leads from popular social media platforms, ensuring they never miss out on potential prospects for their social media campaigns.'
            }
        ],
        unified: [
            {
                image: 'assets/img/landing-new/vcalling/uc.jpg',
                description: 'Make yourself more productive to ensure fast conversation. V-Calling allows users to manage calls from any device, simplifying lead interactions and ensuring consistent follow-up without the need for multiple tools.'
            }
        ],
        efficient: [
            {
                image: 'assets/img/landing-new/vcalling/efficient.webp',
                description: 'Monitor lead activity, set reminders, and automate follow-up tasks, ensuring that no opportunity slips through the cracks. This holistic approach to lead management enhances your ability to convert prospects into customers effectively.'
            }
        ],
        support: [
            {
                image: 'assets/img/landing-new/vcalling/support.avif',
                description: 'Work with efficiency, minimize errors, and resolve customer queries rapidly. The solution helps to maximize customer support center availability and response rate with our V-Calling support team system. '
            }
        ],
    };
    currentDesign = this.design.capture;
    switch = [
        {
            image: 'assets/img/landing-new/vcalling/vcalling2.5.webp',
            title: 'VoIP Phone System',
            description: 'Elevate your communication game with our VoIP Phone System, which turns your internet connection into a powerful communication tool.'
        },
        {
            image: 'assets/img/landing-new/vcalling/vcalling2.2.webp',
            title: 'Cloud Contact Center',
            description: 'Transform your customer interactions with our Cloud Contact Center, designed to deliver seamless support and engagement from anywhere.'
        },
        {
            image: 'assets/img/landing-new/vcalling/vcalling2.3.webp',
            title: 'Auto Sales Dialer',
            description: 'Boost your sales productivity with our Auto Sales Dialer, a tool crafted to streamline your calling process, automate and optimize your outreach.'
        },
        {
            image: 'assets/img/landing-new/vcalling/vcalling2.4.png',
            title: 'Conversational AI',
            description: 'Conversational AI, is an advanced system designed to understand and respond to customer queries with human-like interaction.'
        },
    ];

    /*mobile = [
        {
            image: 'assets/img/landing-new/vcalling/vcalling12.webp',
            heading: 'Download Desktop Apps',
            text: 'Download Desktop App'
        },
        {
            image: 'assets/img/landing-new/vcalling/vcalling13.webp',
            heading: 'Download Mobile Apps',
            text: 'TeleCMI for Android'
        },
        {
            image: 'assets/img/landing-new/vcalling/vcalling14.webp',
            heading: 'At Your Desk',
            text: 'Get Your IP Phone'
        },
    ];
*/
    steps = [
        {
            heading: 'Purchase A Number',
            image : 'fa-brands fa-usps',
            description: 'Select and buy a number that suits your business needs. Whether local or international, we’ve got you covered.'
        },
        {
            heading: 'Register Users',
            image : 'fa-solid fa-users',
            description: 'Add users to your system effortlessly. Set up accounts for your team members to ensure smooth communication and collaboration.'
        },
        {
            heading: 'Manage Calls',
            image : 'fa-solid fa-phone',
            description: 'Start handling calls with ease. V-Calling’s intuitive interface makes call management straightforward, so you can focus on what really matters.'
        },
        {
            heading: 'Monitor and Optimize',
            image : 'fa-solid fa-layer-group',
            description: 'Track performance with analytics tools. Gain insights and make data-driven decisions to continuously enhance your communication strategy.'
        },
    ];

    constructor(private router: Router,
                private meta : Meta,
                private title : Title,
                private fb : FormBuilder,
                private contactService : ContactUsService,
                private alertService : AlertService) {
    }

    ngOnInit(): void {
        this.updateMetaTags()
        this.contactForm = this.fb.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            contact: [''],
            message: ['', Validators.required]
        });
    }

    openModal() {
        this.isModalOpen = true;
    }

    closeModal() {
        this.isModalOpen = false;
    }

    handleOverlayClick(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (!target.closest('.modal-dialog')) {
            this.closeModal();
        }
    }

    changeDesign(section: string) {
        this.currentDesign = this.design[section];
    }

    submit() {
        this.submitted = true;
        if (!this.contactForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().name) ? this.contactForm.getRawValue().name : '',
                'email': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().email) ? this.contactForm.getRawValue().email : '',
                'contact': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().contact) ? this.contactForm.getRawValue().contact : '',
                // 'subject': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().subject) ? this.contactForm.getRawValue().subject : '',
                'message': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().message) ? this.contactForm.getRawValue().message : '',
            };
            this.contactService.create(params).subscribe(data => {
                this.submitted = false;
                this.contactForm.patchValue({
                    'name': '',
                    'email': '',
                    'contact': '',
                    'subject': '',
                    'message': ''
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                    // if (error['error']['errors'].subject && error['error']['errors'].subject.length > 0) {
                    //     this.alertService.showErrors(error['error']['errors'].subject[0]);
                    //     return;
                    // }
                    if (error['error']['errors'].message && error['error']['errors'].message.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].message[0]);
                        return;
                    }
                }
            });
        }
    }



    goTo(url) {
        this.router.navigateByUrl(url);
    }

    playVideo() {
        // const video = this.videoElement.nativeElement;
        // const thumbnail = document.getElementById('video-thumbnail');
        // if (video) {
        //     video.classList.remove('d-none');
        //     video.play();
        //     if (thumbnail) {
        //         thumbnail.style.display = 'none';
        //     }
        //     const playButton = document.querySelector('.play-button');
        //     if (playButton) {
        //         playButton.classList.add('d-none');
        //     }
        // }
    }

    ngAfterViewInit(): void {
        const video: HTMLVideoElement = this.videoElement.nativeElement;
        video.muted = true;
        video.playsInline = true;

        this.playVideoIfVisible(video);
    }

    @HostListener('window:scroll', [])
    onWindowScroll(): void {
        const video: HTMLVideoElement = this.videoElement.nativeElement;
        this.playVideoIfVisible(video);
    }

    playVideoIfVisible(video: HTMLVideoElement): void {
        const rect = video.getBoundingClientRect();
        const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;

        if (isVisible) {
            video.play().catch(error => {
                console.log('Autoplay failed:', error);
            });
        } else {
            video.pause();
        }
    }

    updateMetaTags() {
        this.title.setTitle('VCalling - Virtual Calling System From Volmint Technology');
        this.meta.updateTag({name: 'description', content: 'Experience seamless communication with advanced features, enhancing connectivity and efficiency in every call with VCalling, a virtual calling system by Volmint'});
        this.meta.updateTag({name: 'keyword', content: 'angular, seo, meta tags'});
    }

}
