<section class="banner">
    <div class="container-fluid bg-sea-green mt-5">
        <div class="container mt-5 pt-5">
            <div class="row g-5">
                <div class="col-md-6 d-flex text-center text-md-start align-items-center">
                    <div>
                        <h1 class="fw-bolder font-56"><span
                            class="brand-primary-text-color">Comprehensive Security & Compliance Solutions  </span>
                            for Enhanced Business Resilience</h1>
                        <p class="my-4 fs-6 text-secondary text-wrap f-20 justify-text">At Volmint Technologies, our
                            Security & Compliance services focus on ensuring that your digital infrastructure is secure,
                            compliant, and optimized. Through our DevSecOps solutions, we integrate security into every
                            phase of the development process, minimizing risks while enhancing efficiency. Our
                            Compliance Audit and Consulting services provide a thorough analysis of your systems to
                            ensure they meet industry regulations and standards. Additionally, our IT Consulting
                            Services deliver expert guidance to fortify your business operations and maintain regulatory
                            compliance, keeping your organization resilient in a rapidly evolving technological
                            landscape.
                        </p>
                        <button class="btn bg-white px-5 rounded-0 border-0 shadow-lg fw-bold hover-effect"
                                (click)="openModal()">View Demo <i
                            class="ms-3 fa-solid fa-arrow-right"></i>
                        </button>
                        <ng-template #contactFormModal>
                            <app-contact-form [isModalOpen]="isModalOpen"
                                              (closeModal)="closeModal()"></app-contact-form>
                        </ng-template>
                        <div *ngIf="isModalOpen" class="modal-overlay" (click)="handleOverlayClick($event)">
                            <ng-container *ngTemplateOutlet="contactFormModal"></ng-container>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex justify-content-end py-5 d-flex justify-content-center">
                    <div
                        class="  md:mr-0 position-relative h-100 w-100 border-0 d-flex justify-content-center border-radius-10">
                        <img src="assets/img/landing-new/db-devops-serverless/info.png"
                             alt="Image"
                             class="img-fluid border-radius-10"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container-fluid parallax1">
    <div class="container py-5">
        <div class="row animate-on-scroll">
            <div class="col-md-6 m-0 p-0 text-right">
                <img src="assets/img/landing-new/db-devops-serverless/devsecops.png"
                     class="img-fluid border-radius-10"
                     alt="Image">
            </div>
            <div class="col-md-6 ps-md-5 py-md-5 py-4">
                <h2 class=" fs-1 fw-bolder text-white">DevSecOps
                    <span class="brand-primary-text-color">Integrating Security into Your Development Lifecycle</span>
                </h2>
                <p class="my-4 fs-6 text-white text-wrap justify-text">Volmint Technologies offers DevSecOps
                    services that seamlessly integrate security practices into every phase of the development lifecycle.
                    By embedding security measures from the very start, we ensure that applications are built with
                    robust protection against vulnerabilities, reducing risks and enhancing compliance. Our approach
                    empowers teams to develop, test, and deploy secure applications faster, without compromising
                    performance or innovation.</p>
                <div class="d-lg-flex justify-content-between w-90 flex-wrap d-none">
                    <div class="fw-bold my-1 px-5 py-3 brand-primary-bg text-white fixed-width text-center"
                         *ngFor="let item of service1">
                        {{ item.text }}
                    </div>
                </div>
                <div class="col-12 d-block d-lg-none mt-4">
                    <p class="fs-6 text-white" *ngFor="let item of service1"><img
                        src="assets/img/project/check.png"
                        alt="Image"
                        class="img-fluid"> {{ item.text }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid brand-primary-bg">
    <div class="container  py-5">
        <h1 class="text-white text-center mt-5 fw-bold justify-text">DevSecOps A Holistic Approach to Secure
            Development</h1>
        <p class="text-white text-center">At Volmint Technologies, our DevSecOps services are designed to integrate
            security throughout the development process. We focus on three key areas: SAST integration, DAST
            integration, and Web security, ensuring your applications are secure from code to deployment.
        </p>
        <div class="row gy-5 py-5">
            <div class="col-md-4" *ngFor="let item of whyChoose1; let i = index">
                <div class="h-w-40 text-center rounded-circle bg-white pt-2 fw-bold text-black ">{{ i + 1 }}</div>
                <h3 class="text-white my-4 fw-bold">{{ item.header }}</h3>
                <p class="text-white justify-text">{{
                        item.text
                    }}</p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid parallax2">
    <div class="container py-5">
        <div class="row mt-md-5 animate-on-scroll-right">
            <div class="col-md-6 py-md-5">
                <h2 class=" fs-1 fw-bolder text-white">Compliance Audit and Consulting
                    <span class="brand-primary-text-color"> Ensuring Your Business Meets Industry Standards</span></h2>
                <p class="fs-6 text-white text-wrap justify-text">Volmint Technologies provides comprehensive
                    Compliance Audit and Consulting services to help your business achieve and maintain critical
                    industry certifications. Our expertise in ISO 27001, SOC2, and CASA2 ensures that your organization
                    adheres to the highest standards of information security, privacy, and operational efficiency.
                </p>
                <div class="d-lg-flex justify-content-between w-90 flex-wrap d-none">
                    <div class="fw-bold my-1 px-5 py-3 brand-primary-bg text-white fixed-width text-center"
                         *ngFor="let item of service2">
                        {{ item.text }}
                    </div>
                </div>
                <div class="col-12 d-block d-lg-none mt-4">
                    <p class="fs-6 text-white" *ngFor="let item of service2"><img
                        src="assets/img/project/check.png"
                        alt="Image"
                        class="img-fluid"> {{ item.text }}
                    </p>
                </div>
            </div>
            <div class="col-md-6  d-flex justify-content-center">
                <img src="assets/img/landing-new/db-devops-serverless/consult.png"
                     class="img-fluid w-100 border-radius-10" alt="">
            </div>
        </div>
    </div>
</div>
<div class="container-fluid brand-primary-bg">
    <div class="container  py-5">
        <h1 class="text-white text-center mt-5 fw-bold justify-text">Compliance Audit and Consulting: Streamlining Your
            Path to Certification</h1>
        <p class="text-white text-center">Volmint Technologies offers specialized Compliance Audit and Consulting
            services to ensure your business adheres to the highest industry standards and regulatory requirements. We
            help organizations achieve key certifications, such as ISO 27001, SOC2, and CASA2, by providing in-depth
            audits and expert consulting. Our tailored approach helps you strengthen internal controls, enhance data
            security, and maintain ongoing compliance, empowering your business to build trust with customers and
            partners while mitigating risks.
        </p>
        <div class="row gy-5 py-5">
            <div class="col-md-4" *ngFor="let item of whyChoose2; let i = index">
                <div class="h-w-40 text-center rounded-circle bg-white pt-2 fw-bold text-black ">{{ i + 1 }}</div>
                <h3 class="text-white my-4 fw-bold">{{ item.header }}</h3>
                <p class="text-white justify-text">{{
                        item.text
                    }}</p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid parallax3">
    <div class="container py-5">
        <div class="row mt-md-5 animate-on-scroll">
            <div class="col-md-6 m-0 p-0 text-right">
                <img src="assets/img/landing-new/db-devops-serverless/business.png"
                     class="img-fluid border-radius-10"
                     alt="Image">
            </div>
            <div class="col-md-6 ps-md-5 py-md-5 py-4">
                <h2 class=" fs-1 fw-bolder text-white">IT Consulting Services
                    <span class="brand-primary-text-color"> Driving Innovation and Efficiency</span></h2>
                <p class="my-4 fs-6 text-white text-wrap justify-text">At Volmint Technologies, our IT Consulting
                    Services are designed to help businesses optimize their technology infrastructure and streamline
                    operations. We provide expert guidance on implementing cutting-edge solutions that enhance
                    performance, security, and scalability. Whether you need to modernize legacy systems, implement
                    cloud strategies, or ensure robust cybersecurity, our team of consultants delivers customized
                    solutions that align with your business goals, enabling you to stay competitive and agile in an
                    ever-evolving technological landscape.</p>
                <div class="d-lg-flex justify-content-between w-90 flex-wrap d-none">
                    <div class="fw-bold my-1 px-5 py-3 brand-primary-bg text-white fixed-width text-center"
                         *ngFor="let item of service3">
                        {{ item.text }}
                    </div>
                </div>
                <div class="col-12 d-block d-lg-none mt-4">
                    <p class="fs-6 text-white" *ngFor="let item of service3"><img
                        src="assets/img/project/check.png"
                        alt="Image"
                        class="img-fluid"> {{ item.text }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid brand-primary-bg">
    <div class="container  py-5">
        <h1 class="text-white text-center mt-5 fw-bold justify-text">IT Consulting Services: Tailored Solutions for
            Business Growth</h1>
        <p class="text-white text-center">
            Volmint Technologies offers a wide range of IT Consulting Services that are designed to support your
            business’s technology needs. Our services span across all domains, including application development, with
            flexible engagement models like Time and Material-based contracts and SLA-based contracts to suit your
            specific project requirements.
        </p>
        <div class="row gy-5 py-5">
            <div class="col-md-4" *ngFor="let item of whyChoose3; let i = index">
                <div class="h-w-40 text-center rounded-circle bg-white pt-2 fw-bold text-black ">{{ i + 1 }}</div>
                <h3 class="text-white my-4 fw-bold">{{ item.header }}</h3>
                <p class="text-white justify-text">{{
                        item.text
                    }}</p>
            </div>
        </div>
    </div>
</div>


<div class="container-fluid">
    <div class="container">
        <div class="row gx-4">
            <div class="col-md-6 py-5">
                <h2 class=" fs-1 fw-bolder">
                    IT Consulting Services <span
                    class="brand-primary-text-color">Empowering Businesses with  </span>Tailored Technology Solutions
                </h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">At Volmint Technologies, our IT Consulting
                    Services help clients enhance operational efficiency and achieve business objectives through
                    strategic technology implementations. By offering expertise across all domains, including
                    application development, we ensure that businesses have the right solutions to drive innovation and
                    growth. Our Time and Material-based contracts provide flexibility for dynamic projects, allowing
                    clients to adapt as needs evolve. Meanwhile, our SLA-based contracts offer reliability with defined
                    deliverables, ensuring that critical services are met with precision. Together, these services
                    empower businesses to remain agile, competitive, and secure in an ever-changing technological
                    landscape.
                </p>
                <button class="btn text-white brand-primary-bg border px-5 rounded-0 my-5 hover-white fw-bold"
                        (click)="openModal()">Book Appointment
                    <i
                        class="ms-3 fa-solid fa-arrow-right"></i>
                </button>
            </div>
            <div class="col-md-6 pt-5 text-right animate-on-scroll">
                <img src="assets/img/landing-new/db-devops-serverless/it_counsult.png"
                     class="ms-2 img-fluid border-radius-10" alt="Image"/>
            </div>
            <div class="col-md-6 pt-5 text-right animate-on-scroll-right">
                <img src="assets/img/landing-new/cloud-db-cdn/why.png"
                     class="w-100" alt="">
            </div>
            <div class="col-md-6 py-5 ps-md-5">
                <h2 class="fs-1 fw-bolder">
                    Why Choose Us<span
                    class="brand-primary-text-color"> Your Trusted Partner for Technology Solutions?</span></h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">At Volmint Technologies, we stand out as a
                    leader in the IT consulting landscape due to our unwavering commitment to excellence and client
                    satisfaction. Our team of experienced professionals brings a wealth of expertise across various
                    domains, ensuring that we deliver tailored solutions that align with your unique business needs. We
                    prioritize collaboration and transparency, fostering strong partnerships with our clients to achieve
                    their objectives effectively. With a focus on innovation, security, and scalability, we leverage the
                    latest technologies and best practices to drive your business forward. By choosing us, you gain a
                    trusted partner dedicated to empowering your organization and ensuring sustainable growth in a
                    competitive environment.
                </p>
            </div>
        </div>
    </div>
</div>


<div class="container-fluid">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="col-12">
                    <img src="assets/img/landing-new/mobile-development/faq.png" alt="Image" class="img-fluid w-50"/>
                    <p class="fs-1 fw-bold">Frequently Asked Questions</p>
                    <p class="text-secondary">Have a question that is not answered? You can contact us at</p>
                    <p class="brand-primary-text-color fw-bold">Email: <a href="mailto:support@volmint.com"
                                                                          class="text-decoration-underline brand-primary-text-color">support@volmint.com</a>
                    </p>
                    <button type="submit"
                            class="brand-primary-bg border-0 color-white px-5 py-2 fs-6 mt-4 fw-bold hover-effect"
                            (click)="goTo('/faq')">
                        View All Faqs
                    </button>
                </div>
            </div>
            <div class="col-12 col-md-6 mt-4">
                <div class="accordion" id="faqAccordion">
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span class="fs-6 fw-bold" style="color: #03738C;">
                                    <div class="row">
                                        <div class="col-1">
                                          <div class="encircled-icon float-start">
                                                <i class="fas fa-question fw-bold"></i>
                                          </div>
                                        </div>
                                        <div class="col-11">
                                        What types of businesses can benefit from your IT Consulting Services?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Our IT Consulting Services are designed to cater to businesses of all sizes and
                                industries, including startups, SMEs, and large enterprises. We specialize in providing
                                tailored solutions that meet the specific needs of various sectors, including finance,
                                healthcare, retail, and technology.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <span
                            class="fs-6 fw-bold" style="color: #03738C;">
                             <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon float-start">
                                                <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                 <div class="col-11">
                                     How do you ensure the security of our data during the consulting process?
                                 </div>
                             </div>
                            </span>
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                At Volmint Technologies, we prioritize data security throughout our consulting services.
                                We adhere to industry best practices and compliance standards, implementing robust
                                security measures to protect your sensitive information during assessments,
                                implementations, and ongoing support.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <span class="fs-6 fw-bold" style="color: #03738C;">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon float-start">
                                                <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                        <div class="col-11">
                                            What is the typical duration of an IT consulting engagement?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                The duration of an engagement varies based on the project scope and complexity. We offer
                                flexible options, whether it’s a short-term project or a long-term partnership, and work
                                closely with you to establish a timeline that aligns with your business goals.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <span class="fs-6 fw-bold" style="color: #03738C;">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon">
                                                <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                        <div class="col-11">
                                          Can you help with ongoing support after the initial implementation?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h2>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Yes, we offer ongoing support and maintenance services following the initial
                                implementation of your IT solutions. Our team is dedicated to ensuring that your systems
                                remain optimized, secure, and aligned with your evolving business needs.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingFive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <span class="fs-6 fw-bold" style="color: #03738C;">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon">
                                        <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                        <div class="col-11">
                                           How do Time and Material-based contracts work?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h2>
                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Time and Material-based contracts allow you to pay for the actual time and resources
                                utilized during the project. This flexible engagement model is ideal for projects with
                                changing scopes, as it ensures you have the necessary expertise when needed while
                                controlling costs effectively.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid ums-bottom-bg ps-5 mt-5">
    <div class="container">
        <div class="row align-items-center py-5">
            <div class="col-md-10 col-12">
                <h1 class="text-white">Ready to Take Your Business Growth to the Next Level?</h1>
            </div>
            <div class="col-md-2 col-12">
                <button class="btn bg-white rounded-0 px-5 py-3 text-black fw-bold hover-effect"
                        (click)="goTo('/contact')">Contact Us
                </button>
            </div>
        </div>
    </div>
</div>
