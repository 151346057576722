import {Component, OnInit, Renderer2, TemplateRef, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {Meta, Title} from "@angular/platform-browser";

@Component({
    selector: 'app-acricent',
    templateUrl: './acricent.component.html',
    styleUrls: ['./acricent.component.scss']
})
export class AcricentComponent implements OnInit {
    @ViewChild('contactFormModal') contactFormModal!: TemplateRef<any>;
    isModalOpen = false;
    services = [
        {
            icon: 'fa fa-line-chart',
            color: '#FFA500',
            heading: 'Self Filing',
            button: 'Coming Soon',
            description: 'You can quickly fill out the information on your own using the self- filing option. By following the few simple steps, we have made filing taxes hassle- free and user-friendly for ITR submission.',
            listItems: ['Minimum manual practice and Auto-read system', 'Accurate pre-fill data as per Form 16', ' Maximum Returns and Savings', ' Simple, Secure, Confidential processes', 'Step-By-Step Guidance with real-time.']
        },
        {
            icon: 'fa fa-envelope-open',
            color: '#ffc107',
            heading: 'Assist Filing',
            button: 'Start Filing',
            description: 'If you find submitting an ITR difficult, you might elect to use a professional service. One of our specialists can help you fill out forms and data with accuracy and ease.',
            listItems: ['Complete end-to-end assistance', 'ITR review and assistance with corrections', '100% Satisfaction and security', 'Final Approval Requesting and Tracking', ' Draft Tax Computation document (in PDF format) will be shared by us.']
        },
        {
            icon: 'fa fa-laptop',
            color: '#009688',
            heading: 'Tax Planing',
            button: 'Coming Soon',
            description: 'With quick and easy steps, you can have professional tax planning done for you. You will have custom recommendations and suggestions to maximize tax savings and get higher refunds.',
            listItems: ['Individualized tax preparation for greater savings', 'Tactics to increase future wealth', 'Actionable insights in real-time', 'Quick response and suggestions from the dedicated team']
        },
    ];

    plans = [
        {
            bg: '#066bd3',
            description: 'File ITR on your own',
            heading: 'Basic Plan',
            price: 499,
            listItems: ['Minimum manual practice and Auto-read system', 'Customer support expert', 'Complete access to your file and data', 'Accurate pre-fill data as per Form 16', 'Maximum Returns and Savings Recommendations', 'Simple, Secure, Confidential processes', 'Step-By-Step Guidance with real-time results', 'Request for a professional at any time']
        },
        {
            bg: '#E3493E',
            description: 'File ITR with the Professional',
            heading: 'Premium Plan',
            price: 999,
            listItems: ['All features mentioned in Individual Plan', 'End-to-end assistance', 'ITR review and corrections', '100% Satisfaction and Privacy', 'Final Approval Requesting and Tracking', 'Draft Tax Computation document (in PDF format)', 'Quick response from your ITR Pro', 'Full-year subscription', 'Assured the greatest refunds and savings']
        },
    ];
    easySteps = [
        {
            icon: 'fa fa-line-chart',
            color: '#FFA500',
            heading: 'Auto-Filled Data',
            description: 'Get precise and pre-filled data from the IT department, including TDS details and deductions, by sharing the specifics of your most recent tax return.'
        },
        {
            icon: 'fa fa-envelope-open',
            color: '#ffc107',
            heading: 'Significant Tax Savings',
            description: 'Saving tax is never simple when submitting an ITR. We will handle IRS acceptance and the quickest refunds. Get advice and tips on how to handle your hard-earned money so that you can save the most possible.'
        },
        {
            icon: 'fa fa-laptop',
            color: '#009688',
            heading: 'Submit ITR',
            description: 'Once the documents and details have been completed accurately, your professional expert will file the ITR, and you will receive confirmation of this from the government website.'
        }
    ];
    trust = [
        {
            image: 'assets/img/landing-new/acricent/security.gif',
            heading: 'Security',
            description: 'We do NOT scrutinize or analyze your personal information, and your data is completely safe, secure, private, confidential, and protected.'
        },
        {
            image: 'assets/img/landing-new/acricent/accuracy.gif',
            heading: 'Accuracy',
            description: 'As one of the best software providers, we do not entertain Buggs and inaccuracy and provide essential consistent accuracy.'
        },
        {
            image: 'assets/img/landing-new/acricent/confidential.gif',
            heading: 'Confidentiality',
            description: 'We do not sell or rent your information to anyone at any level without your knowledge & acceptance, ensuring complete confidentiality.'
        },
        {
            image: 'assets/img/landing-new/acricent/privacy.gif',
            heading: 'Privacy',
            description: 'Priority: Safeguarding data with advanced tools, procedures, and policies. Achieve success by promptly addressing concerns.'
        },
    ];

    currentImage = 'assets/img/landing-new/acricent/acri-client1.png';
    currentHeading = 'Client Satisfaction';
    currentDescription = 'Client satisfaction is at the heart of everything we do. We strive to exceed expectations by providing prompt and responsive customer support and addressing queries and concerns promptly. We tailor our solutions to meet specific requirements, ensuring a personalized and efficient experience.';
    currentOfferings = [
        {text: '24/7 Client Support'},
        {text: 'Over the phone/ via email'},
        {text: 'Expert Advisor'},
        {text: 'Through Chatbots'},
        {text: 'Optimal Choices'}
    ];

    contentOptions = {
        1: {
            image: 'assets/img/landing-new/acricent/acri-client1.png',
            heading: 'Client Satisfaction',
            description: 'Client satisfaction is at the heart of everything we do. We strive to exceed expectations by providing prompt and responsive customer support and addressing queries and concerns promptly. We tailor our solutions to meet specific requirements, ensuring a personalized and efficient experience.',
            offerings: [
                {text: '24/7 Client Support'},
                {text: 'Over the phone/ via email'},
                {text: 'Expert Advisor'},
                {text: 'Through Chatbots'},
                {text: 'Optimal Choices'}
            ]
        },
        2: {
            image: 'assets/img/landing-new/acricent/acri-client2.png',
            heading: 'Quality Consistency',
            description: 'Our motto is to become the best and we believe that we can’t reach our goal without making quality our preference. our unwavering commitment to consistent quality, providing you with exceptional satisfaction every time.',
            offerings: [
                {text: 'User-friendly Portal'},
                {text: 'Complete Privacy'},
                {text: '100% Security'},
                {text: 'Best Quality Possible'},
                {text: 'User-Centric Layouts/Designs'}
            ]
        },
        3: {
            image: 'assets/img/landing-new/acricent/acri-client3.png',
            heading: 'Pocket Friendly Budget',
            description: 'As we are here to save you money, we make our services and products affordable as compared to others. Our products are limited in cost but not in quality which makes us more approachable and efficient.',
            offerings: [
                {text: 'No-hidden charges'},
                {text: 'Customizable Packages'},
                {text: 'Transparency'},
                {text: 'Reasonable Cost'},
                {text: 'Open Sources'}
            ]
        },
        4: {
            image: 'assets/img/landing-new/acricent/acri-client4.gif',
            heading: 'Economical Growth',
            description: 'While we are thinking about tax and finance, the thing that comes first to mind is ‘Savings.’ Discover remarkable savings that put more money back in your pocket, offering incredible value without compromising on quality.',
            offerings: [
                {text: 'Tax Savings'},
                {text: 'Higher Returns'},
                {text: 'Risk Capacity and Profile'},
                {text: 'Flexibility'},
                {text: 'Managing Targets'},
            ]
        }
    };

    reviews = [
        {
            description: '“I\'m happy with Acricent to a great extent. The follow-up email confirming that both Fed and State were approved was excellent because you made it so straightforward and quick to utilize. I appreciate you offering this stuff.”',
            name: 'Archit Gupta',
            image: 'assets/img/landing-new/acricent/acricent-profile1.png'
        },
        {
            description: '“I used their services for both tax returns and tax planning. I am pleased with their expert services. The staff who filed my returns for AY- 2022-2023 provided excellent help, and despite numerous iterations, they were able to do it without incident.”',
            name: 'Avinash Saini',
            image: 'assets/img/landing-new/acricent/acricent-profile2.png'
        },
        {
            description: '“I appreciate you handling my returns. Your system is really easy to use! I would suggest your service to anyone I know who is in my tax bracket and was looking for something simple and reasonably priced! Continue your wonderful effort”',
            name: 'Priyanka Singh',
            image: 'assets/img/landing-new/acricent/acricent-profile3.png'
        },
        {
            description: '“For me, Acricent has simplified and made it easy to code GST returns. Reconciliation no longer requires 7-8 days, but only a few hours. The folks who help us are capable and encouraging”',
            name: 'Abhishek Kumar',
            image: 'assets/img/landing-new/acricent/acricent-profile4.png'
        },
    ];

    constructor(
        private router: Router,
        private meta : Meta,
        private title : Title
    ) {
    }

    ngOnInit(): void {
        this.updateMetaTags()
    }

    changeContent(option: number): void {
        const content = this.contentOptions[option];
        if (content) {
            this.currentImage = content.image;
            this.currentHeading = content.heading;
            this.currentDescription = content.description;
            this.currentOfferings = content.offerings;
        }
    }

    openModal() {
        this.isModalOpen = true;
    }

    closeModal() {
        this.isModalOpen = false;
    }

    handleOverlayClick(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (!target.closest('.modal-dialog')) {
            this.closeModal();
        }
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }


    updateMetaTags() {
        this.title.setTitle('Acricent | Income tax ret. management system');
        this.meta.updateTag({name: 'description', content: 'This is the home page description.'});
        this.meta.updateTag({name: 'keyword', content: 'angular, seo, meta tags'});
    }
}
