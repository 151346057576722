<!-- Start Page Title Area -->
<div class="page-title-area item-bg1">
    <app-background-animation></app-background-animation>
    <div class="container">
        <div class="page-title-content">
            <p class="fs-5 color-white text-center glowing-heading">Our Projects</p>
            <p class="fs-1 color-white text-center fw-bold glowing-sub-heading">Innovation drives our technology
                forward.</p>
            <div class="mt-5 text-center">
                <button
                    class="bg-F5FCFF px-5 py-3 border-0 fs-6 hover-effect fw-bold glowing-button glowing-sub-heading"
                    (click)="goTo('/contact')">
                    Contact Us <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>
<section class="product-cards bg-pink">
    <div class="container-fluid mt-5 mb-20">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-12 d-flex justify-content-center">
                    <img src="assets/img/landing-new/projects/product-banner.jpg" alt="Innovative Products"
                         class="img-fluid shadow-lg border-0 rounded-4"/>
                </div>
                <div class="col-md-6 col-12">
                    <p class="fs-1 fw-bolder">Building Innovative Products for Seamless Operations</p>
                    <p class="text-secondary f-20 text-wrap justify-text fs-6">At Volmint Technologies, our vision is
                        rooted in creating products that simplify and enhance the way organizations function. Every
                        product we build is designed with the goal of solving real-world challenges through innovative
                        and efficient solutions. From University Management Systems to Enroll and E-Clinic platforms,
                        our software is crafted to automate internal processes with ease. By integrating the latest
                        technologies like AI, machine learning, and cloud computing, we ensure that each product is
                        tailored to meet the unique needs of every institution, driving digital transformation and
                        operational excellence.</p>
                </div>
            </div>
        </div>
    </div>
    <section class="projects">
        <div class="container-fluid p-5" style="background-color: #5236FF;">
            <div class="row justify-content-center">
                <p class="fs-1 text-center text-white fw-bolder ">Our Esteemed Clients</p>

                <!-- Card for each logo -->
                <div class="col-6 col-md-4 col-lg-3 mb-4">
                    <div class="card shadow-sm">
                        <div class="card-body d-flex justify-content-center align-items-center" style="height: 150px;">
                            <img src="assets/img/landing-new/company-logos/subharti-logo.png" alt="Subharti Logo"
                                 class="img-fluid" style="max-height: 100px; background-color: white; padding: 10px;"/>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 col-lg-3 mb-4">
                    <div class="card shadow-sm</div>">
                        <div class="card-body d-flex justify-content-center align-items-center" style="height: 150px;">
                            <img src="assets/img/landing-new/company-logos/Vivekananda-Global-University-Logo.png"
                                 alt="Vivekananda Global University Logo" class="img-fluid"
                                 style="max-height: 100px; background-color: white; padding: 10px;"/>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 col-lg-3 mb-4">
                    <div class="card shadow-sm">
                        <div class="card-body d-flex justify-content-center align-items-center" style="height: 150px;">
                            <img src="assets/img/landing-new/company-logos/arni.png" alt="SLDC Logo" class="img-fluid"
                                 style="max-height: 100px; background-color: white; padding: 10px;"/>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 col-lg-3 mb-4">
                    <div class="card shadow-sm">
                        <div class="card-body d-flex justify-content-center align-items-center" style="height: 150px;">
                            <img src="assets/img/landing-new/company-logos/sldc.png" alt="SLDC Logo" class="img-fluid"
                                 style="max-height: 100px; background-color: white; padding: 10px;"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">

                <!-- Card for each logo -->
                <div class="col-6 col-md-4 col-lg-4 mb-4">
                    <div class="card shadow-sm">
                        <div class="card-body d-flex justify-content-center align-items-center" style="height: 150px;">
                            <img src="assets/img/landing-new/company-logos/Educationmitra.webp"
                                 alt="Education Mitra Logo"
                                 class="img-fluid" style="max-height: 100px; background-color: white; padding: 10px;"/>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 col-lg-4 mb-4">
                    <div class="card shadow-sm">
                        <div class="card-body d-flex justify-content-center align-items-center" style="height: 150px;">
                            <img src="assets/img/landing-new/company-logos/edutantra_Logo.png" alt="Edutantra Logo"
                                 class="img-fluid" style="max-height: 100px; background-color: white; padding: 10px;"/>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 col-lg-4 mb-4">
                    <div class="card shadow-sm">
                        <div class="card-body d-flex justify-content-center align-items-center" style="height: 150px;">
                            <img src="assets/img/landing-new/company-logos/Gyan-Vihar-University.png" alt="SGVU Logo"
                                 class="img-fluid"
                                 style="max-height: 100px; background-color: white; padding: 10px;"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <!-- Card for each logo -->
                <div class="col-6 col-md-4 col-lg-3 mb-4">
                    <div class="card shadow-sm">
                        <div class="card-body d-flex justify-content-center align-items-center" style="height: 150px;">
                            <img src="assets/img/landing-new/company-logos/draxico-logo.gif" alt="Dr Axico Logo"
                                 class="img-fluid" style="max-height: 100px; background-color: white; padding: 10px;"/>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 col-lg-3 mb-4">
                    <div class="card shadow-sm">
                        <div class="card-body d-flex justify-content-center align-items-center" style="height: 150px;">
                            <img src="assets/img/landing-new/company-logos/ms-intitute.png" alt="MS Logo"
                                 class="img-fluid"
                                 style="max-height: 100px; background-color: white; padding: 10px;"/>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 col-lg-3 mb-4">
                    <div class="card shadow-sm">
                        <div class="card-body d-flex justify-content-center align-items-center" style="height: 150px;">
                            <img src="assets/img/landing-new/company-logos/nation-live.jpg" alt="Nation News Logo"
                                 class="img-fluid" style="max-height: 100px; background-color: white; padding: 10px;"/>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 col-lg-3 mb-4">
                    <div class="card shadow-sm">
                        <div class="card-body d-flex justify-content-center align-items-center" style="height: 150px;">
                            <img src="assets/img/landing-new/company-logos/axicoayurveda.jpg" alt="Nation News Logo"
                                 class="img-fluid" style="max-height: 100px; background-color: white; padding: 10px;"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="listed-products">
        <div class="container-fluid mt-5">
            <p class="fs-1 text-center color-purple fw-bolder my-5">Our Projects</p>
            <div class="container">
                <div class="row g-4">
                    <div class="col-lg-4 col-md-6 col-12" *ngFor="let card of products">
                        <!-- Card Wrapper with rounded corners -->
                        <div class="project-card position-relative overflow-hidden shadow-lg border-0"
                             style="cursor: pointer; height: 400px; border-radius: 15px;">

                            <!-- Project Image -->
                            <img src="{{card.image}}" alt="Project Image" class="card-img img-fluid w-100 h-100"
                                 style="object-fit: cover; border-radius: 15px;">

                            <!-- Content Wrapper (including details) with padding -->
                            <div
                                class="content-wrapper position-absolute top-0 start-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center text-center p-4"
                                style="transition: filter 0.3s ease; border-radius: 15px;">

                                <!-- Hover Overlay with lighter background and padding -->
                                <div
                                    class="overlay-content position-absolute top-0 start-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center text-white p-3"
                                    style="background: rgba(0, 0, 0, 0.5); z-index: 2; border-radius: 15px;">

                                    <!-- Logo -->
                                    <div class="logo-wrapper mb-3"
                                         style="width: 100px; height: 100px; background-color: white; border-radius: 50%; overflow: hidden; position: relative; z-index: 3;">
                                        <img src="{{card.logo}}" alt="Logo" class="img-fluid"
                                             style="height: 100%; width: 100%; object-fit: cover; padding: 25px">
                                    </div>

                                    <!-- Title -->
                                    <h2 class="card-title fs-5 fw-bold mb-3" style="color: white;">{{ card.title }}</h2>

                                    <!-- Description with lighter font color -->
                                    <p class="card-description mb-4"
                                       style="color: #f0f0f0;">{{ card.description | slice:0:100 }}...</p>
                                    <!-- Shortened description -->

                                    <!-- Know More Button -->
                                    <button class="btn btn-light fw-bold px-4 py-2"
                                            style="border-radius: 25px; transition: background-color 0.3s ease;"
                                            (click)="goTo(card.url)">
                                        Know More
                                    </button>
                                </div>
                            </div>
                        </div>

                        <!-- Card Title Below -->
                        <div class="text-center mt-3">
                            <h4 class="fs-5 fw-bold">{{ card.title }}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>

<div class="container-fluid py-5"
     style="background-image: url('/assets/img/landing-new/footer/footer-bg.png');">
    <div class="container">
        <div class="row text-center text-md-start">
            <div class="col-md-6 col-12 fs-1 color-white fw-bold mb-3 mb-md-0">
                Ready to take your Business’s IT to the next level?
            </div>
            <div class="col-md-6 col-12 text-md-end">
                <button class="bg-F5FCFF px-5 py-3 border-0 fs-6 hover-effect fw-bold" (click)="goTo('/contact')">
                    Contact Us <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    </div>
</div>
