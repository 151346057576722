import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AlertService} from "../../../shared/services/alert.service";
import {Router} from "@angular/router";
import {ContactUsService} from "../../../shared/services/contact-us.service";
import {Meta, Title} from "@angular/platform-browser";

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
    submitted: any = false;
    submitting: any = false;
    contactForm: FormGroup;
    successMessage: string;
    buttons = [
        {label: 'General', key: 'general'},
        {label: 'Products', key: 'products'},
        {label: 'Services', key: 'services'},
        {label: 'Contact Us', key: 'contact'}
    ];


    faqData = {
        general: [
            {
                question: ' What services does Volmint Technologies offer?',
                answer: 'Volmint Technologies is one of the most reliable giant IT hubs for various tech solutions. It offers services including website development, website design, market research, customized software, etc. Volmint’s cutting-edge solutions transformed business and automated internal processes to minimize human efforts and errors. Volmint has customized solutions for any kind of small, MSME, or large business related to any field, such as edtech, finance, etc.'
            },
            {
                question: ' What products or software do Volmint Technologies offer?',
                answer: 'Being one of the most reliable IT software companies, Volmint has different kinds of products for every business. The company offers university management system, college group management system, college management system, learning management system, enroll- admission management system, e-clinic software, student management system, and ITR management software.'
            },
            {
                question: ' Does Volmint provide customized solutions?',
                answer: 'Absolutely, being the most preferred among the many is not ‘luck by chance’; it is the dedication and hard work of the well-experienced team that delivered not only software but satisfaction to the clients. Our motto is client gratification, which motivates us to bring the most suitable solution to their table. We customize our products to align with customer needs.'
            },
            {
                question: 'What is Volmint’s UMS?',
                answer: 'University Management Systems is one of the best-known and most-buyable products by Volmint Technologies. Through the software, the universities can manage and control internal operations such as student enrollment, fee structure, accounts, and other processes with ease, which helps to decrease manpower and costs.'
            },
            {
                question: 'Is Enroll a Volmint’s product?',
                answer: 'Yes, Enroll is designed and owned by Volmint Technologies. The product automates the student admission management process. It has various features, such as multi-university management, documentation management, multi-counselors and users management, effective lead management, etc. One can manage and control various student admission phases under one roof, avoiding any kind of miscommunication.'
            },
            {
                question: 'How do Volmint Technologies Work?',
                answer: 'Deep market research involves understanding customer needs and desires to provide the best possible solution. It takes time and effort to complete. Designing the right software requires understanding the process and how to do it. Maintenance is crucial for maintaining the software system\'s functionality. Analyzing software performance and usage post-development is essential for ensuring its longevity. Our models use the latest technology and are robust and advanced.'
            },
            {
                question: 'What kind of support will clients get after purchasing the software?',
                answer: 'Volmint believes in upgrading business growth, and it is impossible without mutual collaboration. Thus, we have a dedicated team of problem solvers to help our clients at any stage, from installing software to becoming professionals. We will assign a dedicated manager to the customers along with 24/7 chat or call support, a user manual, and video. Moreover, lifetime free maintenance, training sessions, a free server, third-party tool integration, and resolution in 48 hours are like perks for the clients.'
            },
            {
                question: 'What is the cost of the software?',
                answer: 'To know more about our software or book a free consultation, get in touch with us via email or phone. We will discuss your business requirements and offer you a customized solution, along with the cost. Drop us a line at support@volmint.com or call us at +91 9310828501.'
            },
        ],
        products: [
            {
                question: 'What types of management systems does Volmint Technologies offer?',
                answer: 'We offer a diverse range of management systems tailored to various needs, including:' +
                    '<ul><li>University Management System: Streamline operations and enhance administrative efficiency for universities.</li> <li>College Management System: Manage student records, faculty, and academic activities seamlessly.</li> <li>Group College Management System: Manage and control group college students\' records, faculty, and activities under one roof.</li> <li> Admission Management System: Simplify the admission process with our intuitive and automated system.</li> <li> E-Clinic Management System: Optimize clinic operations, patient records, and appointments.</li> <li> Argus: Simplify exam proctoring, managing the entire examination process with ease of technology.</li> <li> ITR Filing System: Ensure accurate and efficient income tax return filings with our user-friendly software.</li></ul>'
            },
            {
                question: 'How can I choose the right system for my institution or business?',
                answer: 'Choosing the right system depends on your specific needs and goals. We recommend contacting our sales team for a personalized consultation. We’ll assess your requirements and suggest the most suitable solution based on your unique operational needs.'
            },
            {
                question: 'Are your management systems customizable?',
                answer: 'Yes, our systems are designed with flexibility in mind. We offer customization options to tailor each solution to fit your specific requirements. Whether you need additional features or integrations, our team can work with you to ensure the system meets your exact needs.'
            },
            {
                question: 'How can I request a demo or trial of your products?',
                answer: 'To request a demo or trial of any of our products, simply fill out the request form on our website or contact our sales team directly at support@volmint.com. We’ll arrange a personalized demonstration to showcase the features and benefits of the system you’re interested in.'
            },
            {
                question: 'What makes Volmint’s University Management System stand out?',
                answer: 'Our University Management System streamlines complex processes like student enrollment, fee management, and academic tracking, offering user-friendly interfaces and customizable features for efficient operations and smooth student-staff experience.'
            },
            {
                question: 'How does Enroll improve the student admission process? ',
                answer: 'Enroll streamlines the admission process from lead management to final enrollment, reducing manual work and errors, and allowing teams to focus on student success.'
            },
            {
                question: 'Can I integrate Volmint’s software with my existing systems or does Volmint offer third-party integration?',
                answer: 'Our products seamlessly integrate with your existing systems, ensuring a seamless transition and continuity in operations, adding new functionality, or enhancing existing processes.'
            },
            {
                question: 'Does E-Clinic Software follow regulations about medical data?',
                answer: 'Yes, our DCS software conforms to strict healthcare data regulations and security criteria to ensure patient data protection and compliance.'
            },
            {
                question: 'Is Acricent able to process different kinds of tax returns?',
                answer: 'Of course! Different tax return types, such as individual, company, and specialist returns like capital gains and overseas income, are supported by our software.'
            },
            {
                question: 'How easy are your goods to use?',
                answer: 'The foundation of our development is usability. Our products are simple to use, with thorough user guides and a user-friendly interface.'
            },
        ],
        services: [
            {
                question: 'What SEO services does Volmint Technologies offer, and how can they improve my website\'s visibility?',
                answer: 'At Volmint Technologies, our SEO services are designed to enhance your website\'s visibility on search engines like Google. We conduct in-depth keyword research, optimize on-page content, improve site speed, and build quality backlinks.'
            },
            {
                question: 'How does your SMO service differ from SMM, and how do they complement each other?',
                answer: 'Our Social Media Optimization (SMO) services focus on optimizing your social media profiles and content to increase engagement and visibility organically. In contrast, Social Media Marketing (SMM) involves paid strategies like ads and promotions to reach a broader audience. Together, SMO enhances your online presence, while SMM targets specific demographics, resulting in a comprehensive social media strategy that maximizes your brand\'s reach and impact.'
            },
            {
                question: 'What can I expect from your PPC services?',
                answer: 'Our Pay-Per-Click (PPC) services are designed to drive immediate and targeted traffic to your website. We start by understanding your business goals and then develop a customized campaign strategy, including keyword selection, ad creation, and budget management.'
            },
            {
                question: 'How does Volmint Technologies approach UI/UX design to enhance user experience?',
                answer: 'We conduct user research to understand your audience\'s needs and behaviors, then design and test prototypes to ensure ease of use and engagement. Our goal is to create a seamless and enjoyable experience that keeps users coming back to your site or application.'
            },
            {
                question: 'What strategies do you employ for brand promotion, and how do they align with my business goals?',
                answer: 'At Volmint Technologies, our brand promotion strategies are tailored to align with your business objectives. We utilize a mix of digital marketing techniques, including SEO, SMM, content marketing, and PPC, to build and promote your brand across various platforms. Our approach ensures consistent brand messaging, increased brand awareness, and stronger connections with your target audience, ultimately driving growth and success for your business.'
            },
        ],
        contact: [
            {
                question: 'How can I get in touch with Volmint Technologies?',
                answer: 'You can reach us through multiple channels: fill out the contact form on our website, call us directly at +91 9310828501, or email us at support@volmint.com. Our team is ready to assist you Monday through Friday from 9:30 AM to 6:30 PM.'
            },
            {
                question: 'What should I include in my message when contacting support?',
                answer: 'To help us assist you more effectively, please include a detailed description of your issue or inquiry, any relevant account or project information, and your preferred contact method. The more details you provide, the faster we can resolve your concerns.'
            },
            {
                question: 'How long does it typically take to get a response?',
                answer: 'We strive to respond to all inquiries within 24 hours on business days. For more urgent matters, please indicate the urgency in your message, and we’ll prioritize accordingly.'
            },
            {
                question: 'Can I schedule a consultation or demo?',
                answer: 'Absolutely! If you’re interested in learning more about our services or products, you can request a consultation or demo by contacting us through our form, mail, or by phone. We\'ll arrange a convenient time to discuss your needs and demonstrate how we can help.'
            },
            {
                question: 'What if I need support outside of regular business hours?',
                answer: 'For urgent issues that arise outside of our regular business hours, please reach out through our support email, and we’ll make every effort to address your needs as quickly as possible.'
            },
        ]
    };

    currentFaq = this.faqData.general;

    constructor(
        public fb: FormBuilder,
        public alertService: AlertService,
        private router: Router,
        private contactService: ContactUsService,
        private title : Title,
        private meta : Meta
    ) {
    }

    ngOnInit() {
        this.updateMetaTags()
        this.refresh();
    }

    refresh() {
        this.contactForm = this.fb.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            contact: ['', [Validators.required]],
            subject: ['', Validators.required],
            message: ['', Validators.required]
        });
    }

    submit() {
        this.submitted = true;
        if (!this.contactForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().name) ? this.contactForm.getRawValue().name : '',
                'email': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().email) ? this.contactForm.getRawValue().email : '',
                'contact': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().contact) ? this.contactForm.getRawValue().contact : '',
                'subject': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().subject) ? this.contactForm.getRawValue().subject : '',
                'message': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().message) ? this.contactForm.getRawValue().message : '',
            };
            this.contactService.create(params).subscribe(data => {
                this.successMessage = 'Your form has been submitted successfully!';
                this.submitted = false;
                this.contactForm.patchValue({
                    'name': '',
                    'email': '',
                    'contact': '',
                    'subject': '',
                    'message': ''
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                    if (error['error']['errors'].subject && error['error']['errors'].subject.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].subject[0]);
                        return;
                    }
                    if (error['error']['errors'].message && error['error']['errors'].message.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].message[0]);
                        return;
                    }
                }
            });
        }
    }

    changeFaq(section: string) {
        this.currentFaq = this.faqData[section];
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }
    updateMetaTags() {
        this.title.setTitle('FAQ - Volmint Technologies, an IT Software Company in India');
        this.meta.updateTag({name: 'description', content: `Find answers to common questions about Volmint, regarding their products and services. Explore how Volmint Technologies can boost your brand visibility and growth. `});
        // this.meta.updateTag({name: 'keyword', content: `IT company, education-tech, university`});
    }

}
