import {Component, ElementRef, HostListener, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {ContactUsService} from "../../../shared/services/contact-us.service";
import {AlertService} from "../../../shared/services/alert.service";

@Component({
    selector: 'app-db-devops-serverless',
    templateUrl: './db-devops-serverless.component.html',
    styleUrls: ['./db-devops-serverless.component.scss']
})
export class DbDevopsServerlessComponent implements OnInit {

    @ViewChild('contactFormModal') contactFormModal!: TemplateRef<any>;
    @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
    isModalOpen = false;
    contactForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    successMessage: string;
    service1 = [
        {text: 'SAST integration'},
        {text: 'DAST integration'},
        {text: 'Web security'},
    ];
    service2 = [
        {text: 'ISO 27001'},
        {text: 'SOC2'},
        {text: 'CASA2'},
    ];
    service3 = [
        {text: 'All domains including application development'},
        {text: 'Time and Material based contracts'},
        {text: 'SLA based contracts'},
    ];
    modules = [
        {text: 'Admissions Management'},
        {text: 'Attendance Management'},
        {text: 'Fees Management'},
        {text: 'Exams Management'},
        {text: 'Library Management'},
        {text: 'Payroll Management'},
        {text: 'Organized Department'},
        {text: 'Record Management'},
    ];
    features = [
        {icon: 'assets/img/landing-new/argus/monitoring.png', text: 'Server Setup and Configuration'},
        {icon: 'assets/img/landing-new/argus/analytics.png', text: 'Performance Monitoring'},
        {icon: 'assets/img/landing-new/argus/custom.png', text: 'Security Management'},
        {icon: 'assets/img/landing-new/argus/user.png', text: 'Backup and Disaster Recovery'},
        {icon: 'assets/img/landing-new/argus/multi.png', text: 'Updates and Patch Management'},
        {icon: 'assets/img/landing-new/argus/hybrid.png', text: 'Load Balancing'},
        {icon: 'assets/img/landing-new/argus/automated.png', text: 'Scalability'},
        {icon: 'assets/img/landing-new/argus/scalability.png', text: 'Remote Management'},
        {icon: 'assets/img/services/ongoing_support.jpeg', text: 'Resource Allocation'}

    ];
    whyChoose1 = [
        {
            header: 'SAST Integration (Static Application Security Testing)',
            text: 'Our SAST integration service involves analyzing your source code at the earliest stages of development to identify security vulnerabilities. By catching potential issues early, we help your development teams address risks proactively, reducing the cost and time associated with fixing vulnerabilities later in the process.'
        },
        {
            header: 'DAST Integration (Dynamic Application Security Testing)',
            text: 'DAST integration complements SAST by focusing on runtime security. We simulate real-world attacks on your application while it’s running, uncovering vulnerabilities that might be missed during static testing. This ensures your software is fortified against potential threats that may arise in live environments.'
        },
        {
            header: 'Web Security',
            text: 'Our Web security service provides comprehensive protection for your web applications. We deploy industry-leading techniques and tools to safeguard your application from common threats such as cross-site scripting (XSS), SQL injection, and other web-based attacks. This ensures your online assets remain secure and your users\' data is protected.'
        }
    ];
    whyChoose2 = [
        {
            header: 'ISO 27001',
            text: 'Our ISO 27001 service assists businesses in establishing, implementing, and maintaining an Information Security Management System (ISMS). We help your organization achieve compliance with this globally recognized standard, ensuring that your information security processes are robust and aligned with best practices. This protects your sensitive data and enhances trust with clients and partners.'
        },
        {
            header: 'SOC2',
            text: 'SOC2 compliance focuses on the security, availability, processing integrity, confidentiality, and privacy of customer data. We guide your organization through the SOC2 auditing process, ensuring that your internal controls meet these stringent criteria. Achieving SOC2 compliance demonstrates your commitment to data protection and regulatory adherence, which is critical for companies handling customer data in the cloud.'
        },
        {
            header: 'CASA2',
            text: 'Our CASA2 consulting service is tailored to organizations that need to comply with specific regulatory frameworks related to data privacy and cybersecurity. We help you understand and implement the necessary measures to meet these requirements, ensuring that your business remains compliant and secure in a constantly evolving regulatory landscape.'
        }
    ];
    whyChoose3 = [
        {
            header: 'All Domains, Including Application Development',
            text: 'We provide comprehensive consulting services across all domains, with a focus on application development. Whether you need custom software, mobile apps, or enterprise-level solutions, we help design and implement applications that meet your business objectives while ensuring scalability, security, and performance.'
        },
        {
            header: 'Time and Material-Based Contracts',
            text: 'Our Time and Material-based contracts offer flexible engagement for businesses with evolving project needs. This model allows you to pay for the actual time and resources used, ensuring that you get the right expertise at the right time. It’s ideal for projects where the scope might change, providing cost control and adaptability.'
        },
        {
            header: 'SLA-Based Contracts',
            text: 'For clients seeking more predictable outcomes, we offer SLA-based contracts, which guarantee service levels and performance metrics. We work with you to define key deliverables and ensure that our services meet or exceed the agreed-upon standards, providing reliability and peace of mind for critical business operations.'
        }
    ];
    offerings = [
        {
            text: 'Holistic Approach'
        },
        {
            text: 'Modular Flexibility'
        },
        {
            text: 'Real-Time Insights'
        },
        {
            text: 'Enhanced Security'
        },
        {
            text: 'Scalability and Adaptability'
        },
    ];

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private contactService: ContactUsService,
        private alertService: AlertService
    ) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.contactForm = this.fb.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            contact: [''],
            subject: ['', Validators.required],
            message: ['', Validators.required]
        });
    }

    openModal() {
        this.isModalOpen = true;
    }

    closeModal() {
        this.isModalOpen = false;
    }

    handleOverlayClick(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (!target.closest('.modal-dialog')) {
            this.closeModal();
        }
    }

    submit() {
        this.submitted = true;
        if (!this.contactForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().name) ? this.contactForm.getRawValue().name : '',
                'email': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().email) ? this.contactForm.getRawValue().email : '',
                'contact': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().contact) ? this.contactForm.getRawValue().contact : '',
                'subject': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().subject) ? this.contactForm.getRawValue().subject : '',
                'message': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().message) ? this.contactForm.getRawValue().message : '',
            };
            this.contactService.create(params).subscribe(data => {
                this.successMessage = 'Your form has been submitted successfully!';
                this.submitted = false;
                this.contactForm.patchValue({
                    'name': '',
                    'email': '',
                    'contact': '',
                    'subject': '',
                    'message': ''
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                    if (error['error']['errors'].subject && error['error']['errors'].subject.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].subject[0]);
                        return;
                    }
                    if (error['error']['errors'].message && error['error']['errors'].message.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].message[0]);
                        return;
                    }
                }
            });
        }
    }

    playVideo() {
        const video = this.videoElement.nativeElement;
        const thumbnail = document.getElementById('video-thumbnail');
        if (video) {
            video.classList.remove('d-none');
            video.play();
            if (thumbnail) {
                thumbnail.style.display = 'none';
            }
            const playButton = document.querySelector('.play-button');
            if (playButton) {
                playButton.classList.add('d-none');
            }
        }
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }

    @HostListener('window:scroll', [])
    onWindowScroll() {
        const elements = document.querySelectorAll('.animate-on-scroll');
        const scrollPosition = window.innerHeight + window.scrollY;

        elements.forEach((element) => {
            const elementPosition = element.getBoundingClientRect().top + window.scrollY;
            if (scrollPosition >= elementPosition) {
                element.classList.add('visible'); // Add the visible class when in view
            }
        });

        const elements1 = document.querySelectorAll('.animate-on-scroll-right');
        const scrollPosition1 = window.innerHeight + window.scrollY;
        elements1.forEach((element) => {
            const elementPosition = element.getBoundingClientRect().top + window.scrollY;

            if (scrollPosition1 >= elementPosition) {
                element.classList.add('visible'); // Add the visible class when in view
            }
        });
    }


}
