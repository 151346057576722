
<section class="banner">
    <div class="container-fluid bg mt-5">

        <div class="container mt-5 pt-5">
            <h1 class="text-dark ">Case Studies</h1>

            <div class="row ">
                <div class="col-sm-6 px-0">
                    <div class="h-40 shadow overflow-hidden ">
                        <img src="assets/img/case-study/case_study_v03_edutatantra.webp" class="mt-5 w-100" alt="Image">
                    </div>
                    <div class="h-60 shadow">
                        <div>
                            <h1 class="fw-bolder font-56">
                                CMS <span class="brand-primary-text-color"> Best Solution</span>
                                To Manage Colleges
                            </h1>
                            <p class="my-4 fs-6 text-secondary f-20 justify-text">A College Management System (CMS) is a
                                comprehensive
                                software
                                solution designed to streamline and optimize the administrative and academic functions
                                within an
                                educational institution. The cloud-based software caters to the needs of faculty members,
                                learners, parents, and other departmental staff.
                            </p>

                        </div>
                    </div>
                </div>
                <div class="col-sm-6 px-0">
                    <div class="h-60 bg-dark-grey">
                        <div>
                            <h1 class="fw-bolder font-56">
                                CMS <span class="brand-primary-text-color"> Best Solution</span>
                                To Manage Colleges
                            </h1>
                            <p class=" fs-6 text-secondary f-20 justify-text">A College Management System (CMS) is a
                                comprehensive
                                software
                                solution designed to streamline and optimize the administrative and academic functions
                                within an
                                educational institution. The cloud-based software caters to the needs of faculty members,
                                learners, parents, and other departmental staff.
                            </p>

                        </div>
                    </div>
                    <div class="h-40 bg-danger">
                        <img src="assets/img/project/cms-two.png" class="mt-5" alt="Image">

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


