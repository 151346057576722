import {Component, ElementRef, OnInit, TemplateRef, ViewChild, HostListener, AfterViewInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {ContactUsService} from "../../../shared/services/contact-us.service";
import {AlertService} from "../../../shared/services/alert.service";

@Component({
    selector: 'app-cloud-db-cdn',
    templateUrl: './cloud-db-cdn.component.html',
    styleUrls: ['./cloud-db-cdn.component.scss']
})
export class CloudDbCdnComponent implements OnInit, AfterViewInit {

    @ViewChild('contactFormModal') contactFormModal!: TemplateRef<any>;
    @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
    isModalOpen = false;
    contactForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    successMessage: string;
    service1 = [
        {text: 'AWS'},
        {text: 'GCP'},
        {text: 'Azure'},
        {text: 'DigitalOcean'},
        {text: 'Linode'},
        {text: 'Others'},
    ];
    service2 = [
        {text: 'AWS RDS, DynamoDB DocumentDB, Redis'},
        {text: 'Google Cloud Database'},
        {text: 'Azure Database Service'},
    ];
    service3 = [
        {text: 'Akamai'},
        {text: 'Limelight'},
        {text: 'Cloudflare'},
        {text: 'Amazon CloudFront'},
        {text: 'Fastly'},
        {text: 'Azure and Google CDN'},
        {text: 'CDN77'},
    ];
    modules = [
        {text: 'Admissions Management'},
        {text: 'Attendance Management'},
        {text: 'Fees Management'},
        {text: 'Exams Management'},
        {text: 'Library Management'},
        {text: 'Payroll Management'},
        {text: 'Organized Department'},
        {text: 'Record Management'},
    ];
    features = [
        {icon: 'assets/img/landing-new/argus/monitoring.png', text: 'Server Setup and Configuration'},
        {icon: 'assets/img/landing-new/argus/analytics.png', text: 'Performance Monitoring'},
        {icon: 'assets/img/landing-new/argus/custom.png', text: 'Security Management'},
        {icon: 'assets/img/landing-new/argus/user.png', text: 'Backup and Disaster Recovery'},
        {icon: 'assets/img/landing-new/argus/multi.png', text: 'Updates and Patch Management'},
        {icon: 'assets/img/landing-new/argus/hybrid.png', text: 'Load Balancing'},
        {icon: 'assets/img/landing-new/argus/automated.png', text: 'Scalability'},
        {icon: 'assets/img/landing-new/argus/scalability.png', text: 'Remote Management'},
        {icon: 'assets/img/services/ongoing_support.jpeg', text: 'Resource Allocation'}

    ];
    whyChoose1 = [
        {
            header: 'Amazon Web Services (AWS)',
            text: 'As one of the most widely adopted cloud platforms, AWS offers a comprehensive suite of services that includes computing power, storage, and databases, as well as machine learning and analytics capabilities. Our team expertly manages your AWS environment, ensuring it is optimized for performance and cost-effectiveness. With features like auto-scaling and robust security measures, we help you leverage AWS to accelerate your digital transformation.'
        },
        {
            header: 'Google Cloud Platform (GCP)',
            text: 'Known for its powerful data analytics and machine learning capabilities, GCP provides businesses with the tools needed to innovate and scale effectively. Our managed services for GCP include infrastructure setup, workload management, and seamless integration with other Google services. We ensure that your applications run efficiently on GCP, optimizing resource usage while maintaining high availability and security.'
        },
        {
            header: 'Microsoft Azure',
            text: 'Azure offers a versatile cloud environment, supporting various programming languages, frameworks, and tools. With our managed services, we help you navigate Azure\'s extensive capabilities, from virtual machines and storage solutions to advanced analytics and AI. Our team ensures that your Azure deployment is configured for maximum efficiency, security, and compliance, enabling your organization to harness the full potential of the Azure ecosystem.'
        },
        {
            header: 'DigitalOcean',
            text: 'Designed for simplicity and speed, DigitalOcean is an excellent choice for developers and startups. Our managed services provide you with a streamlined approach to deploying and managing applications on DigitalOcean, with a focus on scalability and cost control. We handle everything from server configuration to security monitoring, allowing you to focus on developing your applications without worrying about the underlying infrastructure.'
        },
        {
            header: 'Linode',
            text: 'Known for its high performance and cost-effective solutions, Linode is ideal for businesses looking for a reliable cloud platform. Our managed services for Linode include server setup, management, and optimization. We ensure your Linode environment is tailored to your specific needs, providing robust security measures and high availability to keep your applications running smoothly.'
        },
        {
            header: 'Others',
            text: 'In addition to these major cloud providers, we also offer managed services for other cloud platforms that meet your business requirements. Our flexible approach allows us to customize our services based on the specific features and capabilities of the cloud provider you choose, ensuring you receive the best support for your cloud journey.'
        }
    ];
    whyChoose2 = [
        {
            header: 'AWS Database Services',
            text: 'We specialize in managing various AWS database offerings, including Amazon RDS for relational databases, DynamoDB for NoSQL solutions, DocumentDB for document-based applications, and Redis for in-memory caching. Our team ensures that your AWS database environment is optimized for performance, cost, and security, allowing you to focus on leveraging your data for business insights.'
        },
        {
            header: 'Google Cloud Database',
            text: 'Our expertise extends to Google Cloud\'s powerful database services, which include fully managed solutions for both SQL and NoSQL databases. We help you implement and manage databases that align with your application\'s requirements, ensuring high availability, automatic scaling, and robust security features, all while integrating seamlessly with other Google Cloud services.'
        },
        {
            header: 'Azure Database Service',
            text: 'We offer comprehensive management of Azure\'s diverse database solutions, including Azure SQL Database and Cosmos DB. Our services ensure that your Azure database is configured for optimal performance, reliability, and security. We also provide monitoring and maintenance to keep your database running smoothly, empowering your organization to harness the full power of its data.'
        }
    ];
    whyChoose3 = [
        {
            header: 'Akamai',
            text: 'As a leader in the CDN space, Akamai offers a vast network that ensures rapid content delivery and robust security features. We manage your Akamai implementation to optimize performance and enhance security against DDoS attacks, ensuring your content reaches users with minimal latency.'
        },
        {
            header: 'Limelight',
            text: 'Limelight\'s CDN provides high-quality video streaming and content delivery capabilities. Our team specializes in configuring Limelight to maximize your media delivery, ensuring that your audience enjoys seamless experiences, whether on desktop or mobile devices.'
        },
        {
            header: 'Cloudflare',
            text: 'Known for its security and performance enhancements, Cloudflare\'s CDN helps protect your site while speeding up content delivery. We manage Cloudflare\'s services to improve site performance, enhance security features, and ensure that your content is reliably delivered to users worldwide.'
        },
        {
            header: 'Amazon CloudFront',
            text: 'CloudFront integrates seamlessly with other AWS services, offering a scalable and efficient way to deliver content. We ensure that your CloudFront distribution is optimized for low latency and high transfer speeds, improving your users\' experience.'
        },
        {
            header: 'Fastly',
            text: 'Fastly is designed for modern applications, providing real-time content delivery and instant updates. Our team optimizes your Fastly setup to enhance performance, enabling dynamic content delivery while maintaining security and reliability.'
        },
        {
            header: 'Azure and Google CDN',
            text: 'We provide management for Azure and Google Cloud\'s CDN services, allowing you to leverage their robust infrastructures for effective content delivery. Our expertise ensures that your CDN is optimized for both speed and performance.'
        },
        {
            header: 'CDN77',
            text: 'CDN77 offers an affordable and powerful solution for content delivery with a focus on video streaming and high-quality performance. We configure and manage CDN77 to provide fast delivery and enhance your digital media experiences.'
        },
    ];
    offerings = [
        {
            text: 'Holistic Approach'
        },
        {
            text: 'Modular Flexibility'
        },
        {
            text: 'Real-Time Insights'
        },
        {
            text: 'Enhanced Security'
        },
        {
            text: 'Scalability and Adaptability'
        },
    ];

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private contactService: ContactUsService,
        private alertService: AlertService
    ) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    ngAfterViewInit() {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // Add the "visible" class when the element enters the viewport
                    entry.target.classList.add('visible');
                } else {
                    // Remove the "visible" class when the element leaves the viewport
                    entry.target.classList.remove('visible');
                }
            });
        });
        // Select all elements with the class "animate-on-scroll"
        const elements = document.querySelectorAll('.animate-on-scroll, .animate-on-scroll-right');

        // Observe each element
        elements.forEach(element => {
            observer.observe(element);
        });
    }

    refresh() {
        this.contactForm = this.fb.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            contact: [''],
            subject: ['', Validators.required],
            message: ['', Validators.required]
        });
    }

    openModal() {
        this.isModalOpen = true;
    }

    closeModal() {
        this.isModalOpen = false;
    }

    handleOverlayClick(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (!target.closest('.modal-dialog')) {
            this.closeModal();
        }
    }

    submit() {
        this.submitted = true;
        if (!this.contactForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().name) ? this.contactForm.getRawValue().name : '',
                'email': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().email) ? this.contactForm.getRawValue().email : '',
                'contact': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().contact) ? this.contactForm.getRawValue().contact : '',
                'subject': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().subject) ? this.contactForm.getRawValue().subject : '',
                'message': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().message) ? this.contactForm.getRawValue().message : '',
            };
            this.contactService.create(params).subscribe(data => {
                this.successMessage = 'Your form has been submitted successfully!';
                this.submitted = false;
                this.contactForm.patchValue({
                    'name': '',
                    'email': '',
                    'contact': '',
                    'subject': '',
                    'message': ''
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                    if (error['error']['errors'].subject && error['error']['errors'].subject.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].subject[0]);
                        return;
                    }
                    if (error['error']['errors'].message && error['error']['errors'].message.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].message[0]);
                        return;
                    }
                }
            });
        }
    }

    playVideo() {
        const video = this.videoElement.nativeElement;
        const thumbnail = document.getElementById('video-thumbnail');
        if (video) {
            video.classList.remove('d-none');
            video.play();
            if (thumbnail) {
                thumbnail.style.display = 'none';
            }
            const playButton = document.querySelector('.play-button');
            if (playButton) {
                playButton.classList.add('d-none');
            }
        }
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }

    @HostListener('window:scroll', [])
    onWindowScroll() {
        const elements = document.querySelectorAll('.animate-on-scroll');
        const scrollPosition = window.innerHeight + window.scrollY;

        elements.forEach((element) => {
            const elementPosition = element.getBoundingClientRect().top + window.scrollY;
            if (scrollPosition >= elementPosition) {
                element.classList.add('visible'); // Add the visible class when in view
            }
        });

        const elements1 = document.querySelectorAll('.animate-on-scroll-right');
        const scrollPosition1 = window.innerHeight + window.scrollY;
        elements1.forEach((element) => {
            const elementPosition = element.getBoundingClientRect().top + window.scrollY;

            if (scrollPosition1 >= elementPosition) {
                element.classList.add('visible'); // Add the visible class when in view
            }
        });
    }

}
