import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CareerService} from '../../../../shared/services/career.service';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AlertService} from "../../../../shared/services/alert.service";
import {FileService} from "../../../../shared/services/file.service";
import {Meta, Title} from "@angular/platform-browser";

@Component({
    selector: 'app-career-details',
    templateUrl: './career-details.component.html',
    styleUrls: ['./career-details.component.scss']
})
export class CareerDetailsComponent implements OnInit {
    @ViewChild('successModal') successModal: any;
    @ViewChild('careerModal') careerModal: any;
    id: any;
    careerForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    fileUploadResumeDoc: any;
    onFileChanged: any;
    selectedJob: any;
    modalType: any;
    jobOpenings: any;
    data: any;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private careerService: CareerService,
                public modalService: NgbModal,
                public fb: FormBuilder,
                private alertService: AlertService,
                private fileService: FileService,
                private title : Title,
                private meta : Meta) {
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            this.id = params.id;
            this.getCareerData();
        });
        this.refresh();
    }

    refresh() {
        this.careerForm = this.fb.group({
            'name': ['', Validators.required],
            'email': ['', Validators.required],
            'contact': ['', Validators.required],
            'experience': ['', Validators.required],
        });
    }

    goTo(url) {
        window.open(url, '_blank');
    }

    getCareerData() {
        this.careerService.getCareerDataJson().subscribe(data => {
            this.jobOpenings = data.data;
            const selectedJob = this.jobOpenings.find(job => job.id === +this.id);
            if (selectedJob) {
                this.selectedJob = selectedJob;
            }
        });
    }

    goto(url) {
        this.router.navigateByUrl(url);
    }

    openSuccessModal(content) {
        this.modalType = 'SUCCESS';
        this.modalService.open(content, {size: 'md'});
    }

    submit() {
        this.submitted = true;
        /* console.log('123');*/
        if (!this.careerForm.valid) {
            console.log('321');
            return;
        }
        console.log('123');
        if (this.submitted) {
            const params = {
                'name': (this.careerForm && this.careerForm.getRawValue() && this.careerForm.getRawValue().name) ? this.careerForm.getRawValue().name : '',
                'email': (this.careerForm && this.careerForm.getRawValue() && this.careerForm.getRawValue().email) ? this.careerForm.getRawValue().email : '',
                'contact': (this.careerForm && this.careerForm.getRawValue() && this.careerForm.getRawValue().contact) ? this.careerForm.getRawValue().contact : '',
                'experience': (this.careerForm && this.careerForm.getRawValue() && this.careerForm.getRawValue().experience) ? this.careerForm.getRawValue().experience : '',
                'file_id': (this.fileUploadResumeDoc && this.fileUploadResumeDoc.id) ? this.fileUploadResumeDoc.id : '',
                'applied_for': (this.data && this.data.title) ? this.data.title : ''
            };
            console.log('params', params);
            this.careerService.create(params).subscribe(data => {
                console.log("params", params);
                this.submitted = false;
                this.careerForm.patchValue({
                    'name': '',
                    'email': '',
                    'contact': '',
                    'experience': ''
                });
                this.modalService.dismissAll(this.careerModal);
                this.modalService.open(this.successModal);
                this.fileUploadResumeDoc = undefined;
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                    if (error['error']['errors'].govt_photo_id_no && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                }
            });
        }
    }

    openApplyModal(data, content) {
        this.modalType = 'SUBMIT_FORM';
        this.data = data;
        this.modalService.open(content, {size: 'lg'});
    }

    triggerFileInput(): void {
        const fileInput = document.getElementById('fileInput') as HTMLInputElement;
        if (fileInput) {
            fileInput.click();
        }
    }

    onFileChange(e, type) {
        console.log('event', e);
        console.log('type', type);
        this.onFileChanged = e;
        console.log('5this.onFileChanged', this.onFileChanged);
        this.fileService.fileUpload({'type': type, 'fileType': 'NORMAL'}, this.onFileChanged).subscribe(data => {
            console.log('dtaatta', data);
            if (type === 'VOLMINT_RESUME_DOC') {
                this.fileUploadResumeDoc = data['data'];
                console.log('fileUploadResumeDoc', this.fileUploadResumeDoc);
            }
            this.alertService.showSuccess({title: 'Success', message: 'Uploaded Successfully'});
        }, error => {
            this.alertService.showErrors('Something went wrong, please try again');
        });
    }

    updateMetaTags() {
        this.title.setTitle('Exciting Career Opportunities await, Join Volmint Now! ');
        this.meta.updateTag({
            name: 'description',
            content: `Explore career opportunities at Volmint Technologies. Join Volmint Technologies and build your future with a leading IT solutions company. `});
        this.meta.updateTag({name: 'keyword', content: `Volminttechnologies, ITCompany`});
    }
}
