import {Component, ElementRef, OnInit, TemplateRef, ViewChild, AfterViewInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {ContactUsService} from "../../../shared/services/contact-us.service";
import {AlertService} from "../../../shared/services/alert.service";
import {Meta, Title} from "@angular/platform-browser";

@Component({
    selector: 'app-search-engine-optimization-service',
    templateUrl: './search-engine-optimization-service.component.html',
    styleUrls: ['./search-engine-optimization-service.component.scss']
})
export class SearchEngineOptimizationServiceComponent implements OnInit, AfterViewInit {

    @ViewChild('contactFormModal') contactFormModal!: TemplateRef<any>;
    @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
    isModalOpen = false;
    contactForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    successMessage: string;
    powerfullToUse = [
        {text: 'Enhanced Online Visibility'},
        {text: 'Targeted Traffic Acquisition'},
        {text: 'Comprehensive Keyword Research'},
        {text: 'Optimized Website Structure'},
        {text: 'High-Quality Content Creation'},
        {text: 'Measurable Results and Analytics'},
    ];
    modules = [
        {text: 'Admissions Management'},
        {text: 'Attendance Management'},
        {text: 'Fees Management'},
        {text: 'Exams Management'},
        {text: 'Library Management'},
        {text: 'Payroll Management'},
        {text: 'Organized Department'},
        {text: 'Record Management'},
    ];
    features = [
        {icon: 'assets/img/landing-new/argus/monitoring.png', text: 'Comprehensive Keyword Research'},
        {icon: 'assets/img/landing-new/argus/analytics.png', text: 'On-Page Optimization'},
        {icon: 'assets/img/landing-new/argus/custom.png', text: 'High-Quality Content Creation'},
        {icon: 'assets/img/landing-new/argus/user.png', text: 'Off-Page SEO Strategies'},
        {icon: 'assets/img/landing-new/argus/multi.png', text: 'Technical SEO'},
        {icon: 'assets/img/landing-new/argus/hybrid.png', text: 'Local SEO Optimization'},
        {icon: 'assets/img/landing-new/argus/automated.png', text: 'User Experience (UX) Enhancement'},
        {icon: 'assets/img/landing-new/argus/scalability.png', text: 'Data-Driven Analytics and Reporting'},
        {icon: 'assets/img/services/ongoing_support.jpeg', text: 'Continuous Strategy Adaptation'}

    ];
    whyChoose = [
        {
            header: 'Customized Strategies',
            text: 'We tailor our SEO solutions to meet your specific business goals and target audience, ensuring that our approach aligns with your unique needs and objectives.'
        },
        {
            header: 'Experienced Team',
            text: 'Our team consists of seasoned SEO professionals who stay up-to-date with the latest industry trends and algorithm changes, providing you with cutting-edge strategies for optimal results.'
        },
        {
            header: 'Holistic Approach',
            text: 'We implement a comprehensive SEO strategy that encompasses on-page optimization, technical SEO, quality content creation, and off-page tactics to enhance your overall online presence.'
        },
        {
            header: 'Data-Driven Insights',
            text: 'Our focus on analytics allows us to track performance metrics, adjust strategies as needed, and provide you with regular reports that demonstrate the effectiveness of our efforts.'
        },
        {
            header: 'Transparent Communication',
            text: 'We prioritize open communication with our clients, ensuring that you are informed of our progress and any adjustments made to your SEO strategy throughout the process.'
        },
        {
            header: 'Long-Term Growth Focus',
            text: 'Our services are designed to not only achieve immediate results but also to promote sustainable growth, helping your business maintain its competitive edge in the digital landscape over time.'
        },
    ];
    offerings = [
        {
            text: 'In-Depth Website Analysis'
        },
        {
            text: 'Customized SEO Strategies'
        },
        {
            text: 'On-Page and Technical Optimization'
        },
        {
            text: 'High-Quality Content Creation'
        },
        {
            text: 'Off-Page SEO Tactics'
        },
    ];

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private contactService: ContactUsService,
        private alertService: AlertService,
        private title : Title,
        private meta :Meta
    ) {
    }

    ngOnInit(): void {
        this.updateMetaTags()
        this.refresh();
    }

    ngAfterViewInit() {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // Add the "visible" class when the element enters the viewport
                    entry.target.classList.add('visible');
                } else {
                    // Remove the "visible" class when the element leaves the viewport
                    entry.target.classList.remove('visible');
                }
            });
        });
        // Select all elements with the class "animate-on-scroll"
        const elements = document.querySelectorAll('.animate-on-scroll, .animate-on-scroll-right');

        // Observe each element
        elements.forEach(element => {
            observer.observe(element);
        });
    }

    refresh() {
        this.contactForm = this.fb.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            contact: [''],
            subject: ['', Validators.required],
            message: ['', Validators.required]
        });
    }

    openModal() {
        this.isModalOpen = true;
    }

    closeModal() {
        this.isModalOpen = false;
    }

    handleOverlayClick(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (!target.closest('.modal-dialog')) {
            this.closeModal();
        }
    }

    submit() {
        this.submitted = true;
        if (!this.contactForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().name) ? this.contactForm.getRawValue().name : '',
                'email': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().email) ? this.contactForm.getRawValue().email : '',
                'contact': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().contact) ? this.contactForm.getRawValue().contact : '',
                'subject': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().subject) ? this.contactForm.getRawValue().subject : '',
                'message': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().message) ? this.contactForm.getRawValue().message : '',
            };
            this.contactService.create(params).subscribe(data => {
                this.successMessage = 'Your form has been submitted successfully!';
                this.submitted = false;
                this.contactForm.patchValue({
                    'name': '',
                    'email': '',
                    'contact': '',
                    'subject': '',
                    'message': ''
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                    if (error['error']['errors'].subject && error['error']['errors'].subject.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].subject[0]);
                        return;
                    }
                    if (error['error']['errors'].message && error['error']['errors'].message.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].message[0]);
                        return;
                    }
                }
            });
        }
    }

    playVideo() {
        const video = this.videoElement.nativeElement;
        const thumbnail = document.getElementById('video-thumbnail');
        if (video) {
            video.classList.remove('d-none');
            video.play();
            if (thumbnail) {
                thumbnail.style.display = 'none';
            }
            const playButton = document.querySelector('.play-button');
            if (playButton) {
                playButton.classList.add('d-none');
            }
        }
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }
    updateMetaTags() {
        this.title.setTitle('SEO Company in India - Volmint, Result Driven SEO Company');
        this.meta.updateTag({name: 'description', content: 'The top-notch SEO company in India, Volmint Technologies will improve your website\'s visibility, drive traffic, and enhance your online presence with expert strategies'});
        this.meta.updateTag({name: 'keyword', content: 'angular, seo, meta tags'});
    }

}
