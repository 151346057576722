import {Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {ContactUsService} from "../../../shared/services/contact-us.service";
import {AlertService} from "../../../shared/services/alert.service";

@Component({
    selector: 'app-website-development',
    templateUrl: './website-development.component.html',
    styleUrls: ['./website-development.component.scss']
})
export class WebsiteDevelopmentComponent implements OnInit {

    @ViewChild('contactFormModal') contactFormModal!: TemplateRef<any>;
    @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
    isModalOpen = false;
    contactForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    successMessage: string;
    powerfullToUse = [
        {text: 'Customized Solutions'},
        {text: 'Modern Technologies'},
        {text: 'User-Centric Design'},
        {text: 'SEO Optimization'},
        {text: 'Dynamic Functionality'},
        {text: 'Ongoing Support and Maintenance'},
    ];
    modules = [
        {text: 'Admissions Management'},
        {text: 'Attendance Management'},
        {text: 'Fees Management'},
        {text: 'Exams Management'},
        {text: 'Library Management'},
        {text: 'Payroll Management'},
        {text: 'Organized Department'},
        {text: 'Record Management'},
    ];
    features = [
        {icon: 'assets/img/landing-new/argus/monitoring.png', text: 'Tailored Designs'},
        {icon: 'assets/img/landing-new/argus/analytics.png', text: 'Responsive Layouts'},
        {icon: 'assets/img/landing-new/argus/custom.png', text: 'Robust Functionality'},
        {icon: 'assets/img/landing-new/argus/user.png', text: 'User-Friendly Interface'},
        {icon: 'assets/img/landing-new/argus/multi.png', text: 'SEO-Optimized'},
        {icon: 'assets/img/landing-new/argus/hybrid.png', text: 'Fast Loading Speeds'},
        {icon: 'assets/img/landing-new/argus/automated.png', text: 'Secure and Scalable'},
        {icon: 'assets/img/landing-new/argus/scalability.png', text: 'Integrated Analytics'},
        {icon: 'assets/img/services/ongoing_support.jpeg', text: 'Ongoing Support'}

    ];
    whyChoose = [
        {
            header: 'Tailored Solutions',
            text: 'We believe that every business is unique. Our website development process is fully customized to reflect your brand\'s identity and meet your specific goals, ensuring that you receive a solution that perfectly aligns with your vision.'
        },
        {
            header: 'Cutting-Edge Technologies',
            text: 'We utilize the latest frameworks and tools, such as Angular, Node.js, and Tailwind CSS, to build high-performance websites. This allows us to deliver modern, responsive designs that are not only visually appealing but also optimized for speed and efficiency.'
        },
        {
            header: 'User-Centric Design',
            text: 'Our approach focuses on creating intuitive, user-friendly interfaces that enhance the customer experience. We prioritize usability to ensure that visitors can easily navigate your site, find the information they need, and engage with your content effectively.'
        },
        {
            header: 'Robust Functionality',
            text: 'We go beyond aesthetics by integrating advanced features tailored to your business needs, including e-commerce capabilities, booking systems, and custom applications, transforming your website into a powerful tool for growth and engagement.'
        },
        {
            header: 'Comprehensive Support',
            text: 'Our commitment to our clients doesn’t end at launch. We provide ongoing maintenance, updates, and support to ensure your website remains secure, up-to-date, and functioning optimally, allowing you to focus on what you do best—running your business.'
        },
        {
            header: 'Proven Track Record',
            text: 'With a portfolio of successful projects and satisfied clients, we have established ourselves as a trusted partner in website development. Our dedication to quality and customer satisfaction drives us to continually innovate and exceed expectations, setting us apart from the competition.'
        },
    ];
    offerings = [
        {
            text: 'Custom Design and Branding'
        },
        {
            text: 'Content Management System (CMS)'
        },
        {
            text: 'E-commerce Functionality'
        },
        {
            text: 'SEO and Performance Optimization'
        },
        {
            text: 'Analytics and Reporting'
        },
    ];

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private contactService: ContactUsService,
        private alertService: AlertService
    ) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.contactForm = this.fb.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            contact: [''],
            subject: ['', Validators.required],
            message: ['', Validators.required]
        });
    }

    openModal() {
        this.isModalOpen = true;
    }

    closeModal() {
        this.isModalOpen = false;
    }

    handleOverlayClick(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (!target.closest('.modal-dialog')) {
            this.closeModal();
        }
    }

    submit() {
        this.submitted = true;
        if (!this.contactForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().name) ? this.contactForm.getRawValue().name : '',
                'email': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().email) ? this.contactForm.getRawValue().email : '',
                'contact': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().contact) ? this.contactForm.getRawValue().contact : '',
                'subject': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().subject) ? this.contactForm.getRawValue().subject : '',
                'message': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().message) ? this.contactForm.getRawValue().message : '',
            };
            this.contactService.create(params).subscribe(data => {
                this.successMessage = 'Your form has been submitted successfully!';
                this.submitted = false;
                this.contactForm.patchValue({
                    'name': '',
                    'email': '',
                    'contact': '',
                    'subject': '',
                    'message': ''
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                    if (error['error']['errors'].subject && error['error']['errors'].subject.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].subject[0]);
                        return;
                    }
                    if (error['error']['errors'].message && error['error']['errors'].message.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].message[0]);
                        return;
                    }
                }
            });
        }
    }

    playVideo() {
        const video = this.videoElement.nativeElement;
        const thumbnail = document.getElementById('video-thumbnail');
        if (video) {
            video.classList.remove('d-none');
            video.play();
            if (thumbnail) {
                thumbnail.style.display = 'none';
            }
            const playButton = document.querySelector('.play-button');
            if (playButton) {
                playButton.classList.add('d-none');
            }
        }
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }


}
