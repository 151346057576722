import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-ad-gallery',
  templateUrl: './ad-gallery.component.html',
  styleUrls: ['./ad-gallery.component.scss']
})
export class AdGalleryComponent implements OnInit {

  constructor(private router: Router,
              private meta : Meta,
              private title : Title,) { }

  ngOnInit(): void {
      this.updateMetaTags()
  }

    goTo(url) {
        this.router.navigateByUrl(url);
    }
    updateMetaTags() {
        this.title.setTitle('Gallery - Volmint Technologies, an IT Software Company in India');
        this.meta.updateTag({name: 'description', content: `Explore our gallery showcasing impactful campaigns and success stories. Discover how Volmint technologies’ products and services drive results for your business`});
        // this.meta.updateTag({name: 'keyword', content: `IT company, education-tech, university`});
    }

}
