import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {AlertService} from '../../../shared/services/alert.service';
import {Router} from '@angular/router';
import {Meta, Title} from "@angular/platform-browser";

@Component({
    selector: 'app-projects',
    templateUrl: './projects.component.html',
    styleUrls: ['./projects.component.scss']
})

export class ProjectsComponent implements OnInit {
    products = [
        {
            logo: 'assets/img/project/project-logo/enroll.png',
            url: '/enroll',
            image: 'assets/img/landing-new/project/enroll-card.png',
            title: 'Enroll',
            description: 'Enroll, an admission management system designed to streamline and elevate your enrollment experience with cutting-edge technology. Discover features like multi-institution management session-wise tracking, real-time data sharing, comprehensive financial management, social media integration, intuitive lead management & IVR integration.'
        },
        {
            logo: 'assets/img/project/project-logo/argus.png',
            url: '/argus',
            image: 'assets/img/landing-new/argus/argus.jpg',
            title: 'Argus',
            description: 'Remote proctored exams leverage AI for real-time monitoring and fraud detection, ensuring high accuracy and security. AI analyzes behaviors and patterns, while live proctors provide human oversight. This combination enhances exam integrity and scalability, making the solution efficient and effective for large-scale assessments.'
        },
        {
            logo: 'assets/img/project/project-logo/ums.png',
            url: '/ums',
            image: 'assets/img/landing-new/project/ums.jpg',
            title: 'UMS',
            description: 'Volmint\'s University Management System (UMS) is an all-encompassing software solution crafted to effortlessly streamline and optimize your university’s operations. From managing student and teacher attendance, tracking admissions and enrollment, to overseeing examination processes & financial integrations all with precision & efficiency.'
        },
        {
            height: '88px',
            width: '90px',
            logo: 'assets/img/project/project-logo/cms-logo.png',
            url: '/cms',
            image: '/assets/img/project/cms.jpg',
            title: 'CMS',
            description: 'A College Management System (CMS) is a comprehensive software solution designed to streamline and optimize the administrative and academic functions within an educational institution. The cloud-based software caters to the needs of faculty members, learners, parents, and other departmental staff. The system also offers real-time data analytics and reporting tools to enhance decision-making.'
        },
        {
            logo: 'assets/img/project/project-logo/cgms-no-bg.png',
            url: '/cgms',
            image: '/assets/img/project/cgms.jpg',
            title: 'CGMS',
            description: 'The College Group Management System helps your institute to work in a more advanced way. It enhances collaboration and engagement among learners and faculty involved in various campus groups. The CGMS system is a group collaboration software that aims to enhance communication, coordination, and collaboration. It also features customizable group management and integrates with other institutional tools.'
        },
        {
            height: '70px',
            width: '40px',
            logo: 'assets/img/project/project-logo/IMS.jpg',
            url: '/ims',
            image: 'assets/img/landing-new/project/ims.jpg',
            title: 'IMS',
            description: 'Volmint’s Institute Management System is created to enhance the efficiency of administrative tasks within educational institutions. With our software, effortlessly manage student data, attendance, fee collection, academic records, and other key operations. Automate routine processes such as report generation, reminders, & meeting scheduling, all through our comprehensive online IMS.'
        },
        {
            height: '75px',
            width: '65px',
            logo: 'assets/img/project/project-logo/lms-no-bg.png',
            url: '/lms',
            image: 'assets/img/landing-new/project/lms.jpg',
            title: 'LMS',
            description: 'The purpose of the cloud-based Learning Management System is to facilitate e-learning and increase the efficiency and productivity of academic management for institutions by keeping thorough records of classroom activities and improving teacher-student collaboration. LMS can easily develop, administer, deliver, and track online courses using its user-friendly interface. Additionally, the LMS integrates with various educational tools, enhancing the learning experience and creating a seamless digital ecosystem.'
        },
        {
            logo: 'assets/img/project/project-logo/eclinic-logo.png',
            url: '/eclinic',
            image: 'assets/img/landing-new/project/eclinic.jpg',
            title: 'Eclinic',
            description: 'Managing your clinic is made easy with E-Clinic Solution. The software helps you spend less time on administrative tasks, whether you are a big healthcare chain or an individual practitioner. By having everything you require in one place, you can improve patient satisfaction and expand your clientele efficiently. With its intuitive, user-friendly interface and advanced features, E-Clinic Solution ensures a seamless, streamlined experience for both staff and patients. Additionally, E-Clinic Solution offers powerful real-time data analytics.'
        },
        {
            height: '50px',
            width: '70px',
            logo: 'assets/img/project/project-logo/acricent-icon.png',
            url: '/acricent',
            image: 'assets/img/landing-new/acricent/acri-client3.png',
            title: 'Acricent',
            description: 'Acricent is the fastest-growing platform for all Tax services and solutions. As one of the leading financial service software platforms, we are committed to saving every penny you deserve. Our advanced tools ensure accuracy and efficiency, maximizing your tax savings & streamlining the entire process. We continually innovate to stay ahead of tax regulations, providing you with the effective solutions. Acricent also offers personalized support and guidance to help you navigate complex tax scenarios with confidence.'
        },
        {
            logo: 'assets/img/project/project-logo/v-calling-black.png',
            url: '/vcalling',
            image: 'assets/img/landing-new/project/vcalling.jpg',
            title: 'V-calling',
            description: ' V-Calling is your all-in-one solution for crystal-clear voice and video calls, integrating cutting-edge technology with user-friendly features. Whether you’re looking to enhance team collaboration, streamline customer interactions, or simply enjoy a high-quality call experience, V-Calling provides unmatched reliability.'
        },
    ];
    @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
    @ViewChild('videoElementUms') videoElementUms!: ElementRef<HTMLVideoElement>;
    @ViewChild('videoElementCms') videoElementCms!: ElementRef<HTMLVideoElement>;
    @ViewChild('videoElementCgms') videoElementCgms!: ElementRef<HTMLVideoElement>;
    @ViewChild('videoElementIms') videoElementIms!: ElementRef<HTMLVideoElement>;
    @ViewChild('videoElementLms') videoElementLms!: ElementRef<HTMLVideoElement>;
    @ViewChild('videoElementEclinic') videoElementEclinic!: ElementRef<HTMLVideoElement>;
    @ViewChild('videoElementAcricent') videoElementAcricent!: ElementRef<HTMLVideoElement>;

    constructor(public fb: FormBuilder,
                public alertService: AlertService,
                private router: Router,
                private titles : Title,
                private meta : Meta) {
    }

    ngOnInit() {
        this.updateMetaTags()
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }

    updateMetaTags() {
        this.titles.setTitle('Case Studies and Success Stories - Volmint Technologies \n');
        this.meta.updateTag({
            name: 'description',
            content: `Volmint Technologies' IT case studies and success stories speak about real-world challenges and how our innovative solutions have transformed businesses and institutions.`
        });
        this.meta.updateTag({name: 'keyword', content: `Volminttechnologies, ITCompany`});
    }
}
