<section class="banner">
    <div class="container-fluid bg-sea-green mt-5">
        <div class="container mt-5 pt-5">
            <div class="row g-5">
                <div class="col-md-6 d-flex text-center text-md-start align-items-center">
                    <div>
                        <h1 class="fw-bolder font-56"><span
                            class="brand-primary-text-color">Maximize Your Reach with </span>
                            Expert Advertising Solutions</h1>
                        <p class="my-4 fs-6 text-secondary text-wrap f-20 justify-text">At Volmint Technologies, we
                            specialize in delivering comprehensive advertising services that encompass every aspect of
                            digital marketing. From Search Engine Advertising and Display Advertising to Social Media
                            Advertising and Email Marketing, our multifaceted approach ensures your brand is effectively
                            positioned in the digital landscape. We create targeted campaigns that engage your audience,
                            drive traffic, and boost conversions. With a focus on data-driven strategies and innovative
                            techniques, we help you maximize your reach and achieve your marketing goals. Partner with
                            us to elevate your brand and turn your advertising investment into measurable results.
                        </p>
                        <button class="btn bg-white px-5 rounded-0 border-0 shadow-lg fw-bold hover-effect"
                                (click)="openModal()">View Demo <i
                            class="ms-3 fa-solid fa-arrow-right"></i>
                        </button>
                        <ng-template #contactFormModal>
                            <app-contact-form [isModalOpen]="isModalOpen"
                                              (closeModal)="closeModal()"></app-contact-form>
                        </ng-template>
                        <div *ngIf="isModalOpen" class="modal-overlay" (click)="handleOverlayClick($event)">
                            <ng-container *ngTemplateOutlet="contactFormModal"></ng-container>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex justify-content-end py-5 d-flex justify-content-center">
                    <div
                        class=" md:mr-0 position-relative h-100 w-75 border-0  border-radius-10">
                        <img src="assets/img/landing-new/advertising-service/intro-advertising.jpg"
                             alt="Image"
                             class="img-fluid border-radius-10 w-100"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container-fluid">
    <div class="container py-5">
        <div class="row">
            <div class="col-md-6 m-0 p-0 text-right">
                <img src="assets/img/landing-new/advertising-service/about.abvertising.jpg"
                     class="img-fluid border-radius-10"
                     alt="Image">
            </div>
            <div class="col-md-6 ps-md-5 py-md-5 py-4">
                <h2 class=" fs-1 fw-bolder"> Comprehensive Advertising Services
                    <span class="brand-primary-text-color"> Drive Results with Volmint Technologies</span></h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">At Volmint Technologies, we offer a robust
                    suite of advertising services designed to empower businesses in the digital landscape. With the
                    rapid evolution of online marketing, leveraging effective advertising strategies is crucial for
                    reaching and engaging your target audience. Our advertising services cover all facets of digital
                    marketing, including Search Engine Advertising, Display Advertising, Social Media Advertising,
                    Content Marketing, Email Marketing, and more.

                </p>
            </div>
        </div>

        <div class="row mt-md-5">
            <div class="col-md-6 py-md-5">
                <h2 class=" fs-1 fw-bolder"> Ignite Your Business Growth with
                    <span class="brand-primary-text-color">  Strategic Advertising Solutions</span></h2>
                <p class="fs-6 text-secondary text-wrap justify-text">At Volmint Technologies, we believe that strategic
                    advertising is the catalyst for driving sustainable business growth. Our innovative advertising
                    solutions are meticulously crafted to align with your unique goals and target audience, ensuring
                    maximum impact across all digital platforms. By leveraging data-driven insights and creative
                    strategies, we enhance your brand visibility and engagement, turning potential customers into loyal
                    advocates. Let us empower your business with tailored advertising campaigns that not only capture
                    attention but also drive meaningful results, propelling you toward long-term success in a
                    competitive marketplace.
                </p>
                <div class="d-lg-flex justify-content-between w-90 flex-wrap d-none">
                    <div class="fw-bold my-1 px-5 py-3 brand-primary-bg text-white fixed-width text-center"
                         *ngFor="let item of powerfullToUse">
                        {{ item.text }}
                    </div>
                </div>
                <div class="col-12 d-block d-lg-none mt-4">
                    <p class="fs-6 text-secondary" *ngFor="let item of powerfullToUse"><img
                        src="assets/img/project/check.png"
                        alt="Image"
                        class="img-fluid"> {{ item.text }}
                    </p>
                </div>
            </div>
            <div class="col-md-6 pt-5">
                <img src="assets/img/landing-new/advertising-service/business-advertising.jpg"
                     class="img-fluid w-100 border-radius-10" alt="">
            </div>
        </div>
    </div>
</div>

<div class="container-fluid bg-purple">
    <div class="container">
        <div class="row py-5">
            <div class="col-md-6 py-5">
                <h2 class=" fs-1 fw-bolder">
                    Explore the Transformative Features of <span class="brand-primary-text-color">  Our Advertising Services</span>
                </h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">At Volmint Technologies, our advertising
                    services are designed to revolutionize your marketing approach and deliver exceptional results. With
                    a focus on innovation and effectiveness, we offer a range of transformative features that set us
                    apart. Our data-driven targeting strategies ensure that your campaigns reach the right audience at
                    the right time, maximizing engagement and conversion rates. We harness cutting-edge technologies and
                    creative content to craft compelling advertisements that resonate with your target market. From
                    comprehensive analytics to continuous optimization, our services provide the insights and
                    flexibility needed to adapt to the ever-changing digital landscape. Discover how our advertising
                    solutions can elevate your brand and drive sustainable growth in today's competitive environment.
                </p>
                <div class="row g-3">
                    <div class="col-sm-4 col-6 px-2 my-2"
                         *ngFor="let item of features">
                        <div class="card bg-white text-center py-2 fixed-width-span border-0 shadow-lg p-2">
                               <span>
                            <img src="{{item.icon}}" alt="" class="img-fluid w-25">
                        </span>
                            <span class="ms-2 text-secondary pt-2">
                            {{ item.text }}
                        </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6  justify-content-md-end my-2">
                <img src="assets/img/landing-new/advertising-service/explore-adversing.jpg" alt="Image"
                     class="img-fluid border-radius-10">
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="container">
        <div class="row gx-4">
            <div class="col-md-6 py-5">
                <h2 class=" fs-1 fw-bolder">
                    Precision Advertising Customized<span class="brand-primary-text-color">   for Your Audience</span>
                </h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">
                    At Volmint Technologies, we specialize in precision advertising that is meticulously customized to
                    resonate with your specific audience. Our approach leverages advanced data analytics and consumer
                    insights to create targeted campaigns that engage potential customers at every stage of their
                    journey. By understanding the unique preferences and behaviors of your audience, we craft compelling
                    messages and select the optimal channels to deliver them. This ensures that your advertising efforts
                    not only capture attention but also drive meaningful interactions and conversions. Trust us to
                    enhance your marketing strategy with precision-targeted advertising that maximizes your brand's
                    impact in a competitive marketplace.
                </p>
                <button class="btn text-white brand-primary-bg border px-5 rounded-0 my-5 hover-white fw-bold"
                        (click)="openModal()">Book Appointment
                    <i
                        class="ms-3 fa-solid fa-arrow-right"></i>
                </button>
            </div>
            <div class="col-md-6 pt-5 text-right">
                <img src="assets/img/landing-new/advertising-service/Customized-advertising.jpg"
                     class="ms-2 img-fluid border-radius-10" alt="Image"/>
            </div>

            <div class="col-md-6 pt-5 text-right">
                <img src="assets/img/landing-new/social-media-marketing/Targeted-Advertising.png"
                     class="w-100 border-radius-10" alt="">
            </div>
            <div class="col-md-6 py-5 ps-md-5">
                <h2 class="fs-1 fw-bolder">
                    <span
                        class="brand-primary-text-color">Holistic Advertising Strategies for </span> Enhanced Online
                    Visibility</h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">At Volmint Technologies, we offer holistic
                    advertising strategies designed to enhance your online visibility and elevate your brand in the
                    digital landscape. Our comprehensive approach integrates various advertising channels, including
                    search engine marketing, social media campaigns, and content marketing, to create a cohesive
                    presence that resonates with your target audience. By leveraging data-driven insights and innovative
                    techniques, we ensure that your message reaches the right people at the right time, maximizing
                    engagement and driving conversions. Our commitment to continuous optimization means that your
                    advertising efforts are always aligned with evolving market trends, providing you with sustained
                    visibility and growth in a competitive environment. Partner with us to unlock the full potential of
                    your brand through effective and strategic advertising solutions.
                </p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="container">
        <div class="row gx-4">
            <div class="col-md-6 py-5">
                <h2 class=" fs-1 fw-bolder">
                     <span
                         class="brand-primary-text-color"> The Essential Role of Advertising</span>
                    in Fueling Business Success</h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">Advertising plays a pivotal role in driving
                    business success by connecting brands with their target audiences and effectively communicating
                    their value propositions. At Volmint Technologies, we understand that strategic advertising not only
                    enhances brand awareness but also fosters customer engagement and loyalty. In today's competitive
                    marketplace, a well-crafted advertising strategy can differentiate your business from competitors,
                    ensuring your message stands out. By leveraging data-driven insights and innovative techniques, we
                    help businesses optimize their advertising efforts, reaching the right customers at the right time.
                    Ultimately, effective advertising serves as the backbone of growth, enabling companies to expand
                    their market reach, increase sales, and achieve long-term success.
                </p>
                <button class="btn text-white brand-primary-bg border px-5 rounded-0 my-5 hover-white fw-bold"
                        (click)="openModal()">Book Appointment
                    <i
                        class="ms-3 fa-solid fa-arrow-right"></i>
                </button>
            </div>
            <div class="col-md-6 pt-5 text-right">
                <img src="assets/img/landing-new/social-media-marketing/business-social-media.jpg"
                     class="ms-2 img-fluid border-radius-10" alt="Image"/>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid brand-primary-bg mt-5">
    <div class="container py-5">
        <h1 class="text-white text-center mt-5 fw-bold justify-text">What Sets Our Advertising Marketing Services
            Apart? </h1>
        <p class="text-white text-center">At Volmint Technologies, our advertising marketing services stand out due to
            our unwavering commitment to delivering tailored solutions that drive measurable results. What distinguishes
            us is our holistic approach, integrating advanced data analytics, creative strategies, and industry
            expertise to develop campaigns that resonate with your target audience. We prioritize collaboration and
            communication, ensuring that our strategies align seamlessly with your business goals. Additionally, our
            focus on continuous optimization allows us to adapt and refine campaigns in real time, maximizing their
            effectiveness. With a dedicated team of professionals passionate about your success, we empower your brand
            to achieve exceptional visibility and engagement in an increasingly competitive digital landscape.
        </p>
        <div class="row gy-5 py-5">
            <div class="col-md-4" *ngFor="let item of whyChoose; let i = index">
                <div class="h-w-40 text-center rounded-circle bg-white pt-2 fw-bold text-black ">{{ i + 1 }}</div>
                <h3 class="text-white my-4 fw-bold">{{ item.header }}</h3>
                <p class="text-white justify-text">{{
                        item.text
                    }}</p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid py-5">
    <div class="container">
        <div class="row py-5 gx-5">
            <div class="col-md-6 py-md-5">
                <h2 class=" fs-1 fw-bolder">Discover Our Comprehensive
                    <span class="brand-primary-text-color">   Advertising Marketing Solutions </span>
                </h2>
                <p class="my-5 fs-6 text-secondary text-wrap justify-text">At Volmint Technologies, we offer a
                    comprehensive suite of advertising marketing solutions designed to elevate your brand and drive
                    impactful results. Our services encompass a wide range of strategies, including search engine
                    advertising, social media campaigns, display advertising, and content marketing, ensuring a
                    well-rounded approach to your advertising needs. By leveraging data-driven insights and innovative
                    techniques, we create targeted campaigns that engage your audience and enhance brand visibility. Our
                    team of experts is dedicated to understanding your unique business goals, crafting tailored
                    solutions that resonate with your target market. Partner with us to unlock the full potential of
                    your advertising efforts and achieve sustained growth in the digital landscape.</p>
                <div class="row g-3 mt-5">
                    <div class="col-12 px-0 my-3 "
                         *ngFor="let item of offerings">
                        <span>
                            <img src="assets/img/project/check.png" alt="Image" class="img-fluid">
                        </span>
                        <span class="ms-2 fw-bold text-secondary">
                            {{ item.text }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-md-6 pt-5  justify-content-end">
                <img src="assets/img/landing-new/content-marketing/explore-content-marketing.png"
                     class="img-fluid border-radius-10"
                     alt="Image">
            </div>
        </div>

    </div>
</div>

<div class="container-fluid">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="col-12">
                    <img src="assets/img/landing-new/mobile-development/faq.png" alt="Image" class="img-fluid w-50"/>
                    <p class="fs-1 fw-bold">Frequently Asked Questions</p>
                    <p class="text-secondary">Have a question that is not answered? You can contact us at</p>
                    <p class="brand-primary-text-color fw-bold">Email: <a href="mailto:support@volmint.com"
                                                                          class="text-decoration-underline brand-primary-text-color">support@volmint.com</a>
                    </p>
                    <button type="submit"
                            class="brand-primary-bg border-0 color-white px-5 py-2 fs-6 mt-4 fw-bold hover-effect"
                            (click)="goTo('/faq')">
                        View All Faqs
                    </button>
                </div>
            </div>
            <div class="col-12 col-md-6 mt-4">
                <div class="accordion" id="faqAccordion">
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span class="fs-6 fw-bold" style="color: #03738C;">
                                    <div class="row">
                                        <div class="col-1">
                                          <div class="encircled-icon float-start">
                                                <i class="fas fa-question fw-bold"></i>
                                          </div>
                                        </div>
                                        <div class="col-11">
                                        What types of advertising marketing services do you offer?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                We offer a wide range of advertising marketing services, including search engine
                                advertising, social media campaigns, display advertising, content marketing, email
                                marketing, and more. Our comprehensive approach ensures that we can meet all your
                                advertising needs under one roof.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <span
                            class="fs-6 fw-bold" style="color: #03738C;">
                             <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon float-start">
                                                <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                 <div class="col-11">
                                   How do you determine the best advertising strategy for my business?
                                 </div>
                             </div>
                            </span>
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Our process begins with a thorough analysis of your business goals, target audience, and
                                industry trends. We then leverage data-driven insights to create a tailored advertising
                                strategy that aligns with your specific objectives, ensuring maximum relevance and
                                effectiveness.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <span class="fs-6 fw-bold" style="color: #03738C;">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon float-start">
                                                <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                        <div class="col-11">
                                           How will I know if my advertising campaigns are successful?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                We provide regular performance reports that outline key metrics such as engagement
                                rates, click-through rates, conversions, and return on investment (ROI). Our team will
                                also conduct ongoing analysis and optimization to ensure your campaigns are meeting
                                their goals.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <span class="fs-6 fw-bold" style="color: #03738C;">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon">
                                                <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                        <div class="col-11">
                                           How quickly can I expect to see results from my advertising campaigns?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h2>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                The timeline for seeing results can vary based on the type of campaign and your specific
                                goals. However, many clients begin to see increased engagement and brand visibility
                                within the first few weeks of launching their campaigns. We focus on both short-term
                                wins and long-term growth strategies.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingFive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <span class="fs-6 fw-bold" style="color: #03738C;">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon">
                                        <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                        <div class="col-11">
                                           What sets your advertising services apart from competitors?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h2>
                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                What distinguishes our advertising services is our holistic approach, combining diverse
                                strategies and data-driven insights to create customized campaigns. We prioritize
                                collaboration and communication, ensuring our solutions align with your vision. Our
                                commitment to continuous optimization and innovative techniques further enhances the
                                effectiveness of our advertising efforts.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid ums-bottom-bg ps-5 mt-5">
    <div class="container">
        <div class="row align-items-center py-5">
            <div class="col-md-10 col-12">
                <h1 class="text-white">Ready to Take Your Business Growth to the Next Level?</h1>
            </div>
            <div class="col-md-2 col-12">
                <button class="btn bg-white rounded-0 px-5 py-3 text-black fw-bold hover-effect"
                        (click)="goTo('/contact')">Contact Us
                </button>
            </div>
        </div>
    </div>
</div>
