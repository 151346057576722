<div class="page-title-area item-bg1">
    <div class="container">
        <app-background-animation></app-background-animation>
        <div class="page-title-content">
            <p class="fs-5 color-white text-center glowing-heading">Careers</p>
            <p class="fs-1 color-white text-center fw-bold glowing-sub-heading">{{ selectedJob?.title }}</p>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>

<ng-template #careerModal>
    <div class="modal fade show d-block" tabindex="-1" aria-labelledby="careerModal"
         aria-hidden="true" style="display: block;">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header bg-grey">
                    <p class="modal-title fs-5 fw-bolder primary-blue" id="careerModal">Apply</p>
                    <button type="button" class="btn-close-icon shadow-lg primary-blue border-0" aria-label="Close"
                            (click)="modalService.dismissAll()">
                        <i class="fa fa-times"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <form [formGroup]="careerForm" class="border-0 p-4">
                        <div class="mb-3">
                            <label for="name" class="form-label fw-bold">Name</label>
                            <input type="text" id="name" class="form-control rounded-3 py-3 shadow-sm fw-bold"
                                   formControlName="name"
                                   placeholder="Enter Your Name" required>
                            <small *ngIf="submitted && careerForm.get('name').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                        <div class="mb-3">
                            <label for="email" class="form-label fw-bold">Email</label>
                            <input type="email" id="email" class="form-control rounded-3 py-3 shadow-sm fw-bold"
                                   placeholder="Enter Your Email" formControlName="email" required>
                            <small *ngIf="submitted && careerForm.get('email').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                        <div class="mb-3">
                            <label for="phone" class="form-label fw-bold">Phone Number</label>
                            <input type="tel" id="phone" class="form-control rounded-3 py-3 shadow-sm fw-bold"
                                   placeholder="Enter Your Phone Number" formControlName="contact">
                            <small *ngIf="submitted && careerForm.get('contact').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                        <div class="mb-3">
                            <label for="experience" class="form-label fw-bold">Experience</label>
                            <input type="number" id="experience" class="form-control rounded-3 py-3 shadow-sm fw-bold"
                                   placeholder="Enter Your Experience"
                                   formControlName="experience" required>
                            <small *ngIf="submitted && careerForm.get('experience').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                        <div class="col-md-6 col-sm-12 mt-3">
                            <div class="form-group">
                                <div class="file-upload-container">
                                    <input type="file" id="fileInput" class="file-input"
                                           (change)="onFileChange($event, 'VOLMINT_RESUME_DOC')">
                                    <button type="button" class="btn file-upload-button bg-color-blue"
                                            (click)="triggerFileInput()">
                                        Upload Resume <i class="fas fa-upload"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer d-flex justify-content-between bg-grey">
                    <button type="button" class="btn bg-white fs-6 fw-bolder shadow-sm"
                            (click)="modalService.dismissAll()">Close
                    </button>
                    <button type="submit" class="btn bg-color-blue color-white fs-6 fw-bolder shadow-sm"
                            (click)="submit()">Submit
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #successModal>
    <div class="modal fade show d-block" tabindex="-1" aria-labelledby="successModal"
         aria-hidden="true" style="display: block;">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header bg-grey justify-content-between">
                    <p class="modal-title fs-5 fw-bolder primary-blue" id="successModal">Applied</p>
                    <button type="button" class="btn-close-icon shadow-lg primary-blue border-0" aria-label="Close"
                            (click)="modalService.dismissAll()">
                        <i class="fa fa-times"></i>
                    </button>
                </div>
                <div class="modal-body p-4">
                    <p class="fs-1 fw-bolder primary-blue">Successfully Applied!</p>
                </div>
                <div class="modal-footer d-flex justify-content-end bg-grey">
                    <button type="button" class="btn bg-color-blue color-white fs-6 fw-bolder shadow-sm"
                            (click)="modalService.dismissAll()">Close
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<div class="container-fluid mt-5">
    <div class="container">
        <div class="row text-center text-md-start">
            <div class="col-md-6 col-12">
                <div class="row">
                    <div class="col-12 w-100 w-md-75 d-flex justify-content-end mb-2">
                        <div class="card px-5 py-3 border-0 shadow-lg fixed-card">
                            <p class="fs-4 fw-bold text-center primary-blue">Job Overview</p>
                            <p class="fs-6"><span class="fw-bold">Vacancy: </span><span
                                class="text-secondary"> {{ selectedJob?.title }}</span></p>
                            <p class="fs-6"><span class="fw-bold">Job Type: </span><span class="text-secondary"> Full Time</span></p>
                            <p class="fs-6"><span class="fw-bold">Experience: </span><span
                                class="text-secondary"> {{ selectedJob?.experience }}</span></p>
                            <p class="fs-6"><span class="fw-bold">Job Location: </span><span
                                class="text-secondary"> {{ selectedJob?.region }}</span></p>
                            <p class="fs-6"><span class="fw-bold">Category: </span><span
                                class="text-secondary"> {{ selectedJob?.industry }}</span></p>
                        </div>
                    </div>
                    <div class="col-12 w-100 w-md-75 d-flex justify-content-end">
                        <div class="card px-5 py-3 border-0 shadow-lg fixed-card">
                            <p class="fs-4 fw-bold text-center primary-blue">Company Overview</p>
                            <p class="fs-6"><span class="fw-bold">Categories: </span><span
                                class="text-secondary"> {{ selectedJob?.industry }}</span></p>
                            <p class="fs-6"><span class="fw-bold">Established: </span><span class="text-secondary"> 2022</span></p>
                            <p class="fs-6"><span class="fw-bold">Employees: </span><span class="text-secondary"> 50+</span></p>
                            <p class="fs-6"><span class="fw-bold">Location: </span><span
                                class="text-secondary"> {{ selectedJob?.region }}</span></p>
                            <p class="fs-6 "><span class="fw-bold">Phone Number: </span><span class="text-secondary"> +91 9310828501</span>
                            </p>
                            <p class="fs-6"><span class="fw-bold">Email: </span><span class="text-secondary"> support@volmint.com</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-12">
                <p class="fs-1 fw-bold primary-blue">Skills Required:</p>
                <div class="text-secondary justify-text" [innerHTML]="selectedJob?.skills_required"></div>
                <button class="bg-color-blue fs-6 rounded-4 color-white border-0 py-2 px-3 fw-bold hover-effect"
                        (click)="openApplyModal(selectedJob,careerModal)">Apply Now
                </button>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5 bg-light-pink py-5">
    <div class="container">
        <p class="fs-1 fw-bold text-center">Need any further assistance?</p>
        <p class="fs-6 text-center text-secondary">Feel free to reach out for any inquiries or assistance.</p>
        <div class="text-center">
            <button class="bg-color-blue px-5 py-3 border-0 fs-6 mt-5 color-white fw-bold hover-effect"
                    (click)="goto('/contact')">
                Book Appointment <i class="fa fa-arrow-right" aria-hidden="true"></i>
            </button>
        </div>
    </div>
</div>

<div class="container-fluid mt-5 py-5"
     style="background-image: url('/assets/img/landing-new/footer/footer-bg.png');">
    <div class="container">
        <div class="row text-center text-md-start">
            <div class="col-md-6 col-12 fs-1 color-white fw-bold mb-3 mb-md-0">
                Ready to take your Business’s IT to the next level?
            </div>
            <div class="col-md-6 col-12 text-md-end">
                <button class="bg-F5FCFF px-5 py-3 border-0 fs-6 fw-bold hover-effect" (click)="goto('/contact')">
                    Contact Us <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    </div>
</div>
