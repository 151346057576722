import {Component, OnInit} from '@angular/core';
import {BlogService} from '../../../shared/services/blog.service';
import {ActivatedRoute, Router} from '@angular/router';
import {error, logging} from "protractor";
import {Meta, Title} from "@angular/platform-browser";

@Component({
    selector: 'app-blog-detail',
    templateUrl: './blog-detail.component.html',
    styleUrls: ['./blog-detail.component.scss']
})
export class BlogDetailComponent implements OnInit {
    title: any;
    id: any;
    selectedData: any;
    slug: any;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private blogServices: BlogService,
                private meta: Meta,
                private titles: Title) {
        this.route.paramMap.subscribe(params => {
            this.slug = params.get('blogName');
            this.getByTitle();
        });
        if (this.router.getCurrentNavigation() != null && this.router.getCurrentNavigation().extras != null && this.router.getCurrentNavigation().extras.state != null) {
            this.id = this.router.getCurrentNavigation().extras.state.id;
            console.log(this.id);
        }
    }

    ngOnInit(): void {
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }

    getByTitle() {
        this.blogServices.getByTitle(this.slug).subscribe(data => {
            this.selectedData = data;
            this.updateMetaTags();
        });
    }

    updateMetaTags() {
        this.titles.setTitle(this.selectedData.meta_title || this.selectedData.heading);
        this.meta.updateTag({
            name: 'description',
            content: `${this.selectedData.meta_description || this.selectedData.heading}`
        });
        this.meta.updateTag({name: 'keyword', content: `${this.selectedData.meta_keyword || 'volminttechnologies'}`});
    }
}
