import {Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {ContactUsService} from "../../../shared/services/contact-us.service";
import {AlertService} from "../../../shared/services/alert.service";
import {Meta, Title} from "@angular/platform-browser";

@Component({
    selector: 'app-enroll',
    templateUrl: './enroll.component.html',
    styleUrls: ['./enroll.component.scss']
})
export class EnrollComponent implements OnInit {
    @ViewChild('contactFormModal') contactFormModal!: TemplateRef<any>;
    @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
    isModalOpen = false;
    contactForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    powerfullToUse = [
        {text: 'User-friendly'},
        {text: 'Automated'},
        {text: 'Real-time Report'},
        {text: 'Accuracy'},
        {text: 'Reliability'},
        {text: 'Security'},
    ];
    modules = [
        {text: 'Admissions Management'},
        {text: 'Attendance Management'},
        {text: 'Fees Management'},
        {text: 'Exams Management'},
        {text: 'Library Management'},
        {text: 'Payroll Management'},
        {text: 'Organized Department'},
        {text: 'Record Management'},
    ];
    features = [
        {icon: 'assets/img/project/admissions.png', text: 'Student Admission Portal'},
        {icon: 'assets/img/project/enrollment.png', text: 'Online Application Management'},
        {icon: 'assets/img/project/examination.png', text: 'Analytics and Reporting'},
        {icon: 'assets/img/project/finance.png', text: 'User Management'},
        {icon: 'assets/img/project/Recognitions.png', text: 'Lead Management'},
        {icon: 'assets/img/project/student-tracking.png', text: 'Add Multiple Institutions'},
        {icon: 'assets/img/project/notifications.png', text: 'Accounts Management'},
        {icon: 'assets/img/project/branch-icon.png', text: 'Payment Management'}

    ];
    whyChoose = [
        {
            header: 'Application Management',
            text: 'Simplify the submission, review, and approval process with an intuitive application portal. Track applications in real-time, automate workflows, and ensure a smooth experience for applicants.'
        },
        {
            header: 'Communication Hub',
            text: 'Centralize all communications with prospective students through various channels such as email, SMS, and social media. Keep track of interactions, send personalized messages, and ensure consistent follow-ups.'
        },
        {
            header: 'Analytics and Reporting',
            text: 'Gain actionable insights with advanced analytics and customizable reports. Monitor key metrics like application trends, conversion rates, and enrollment statistics to make data-driven decisions.'
        },
        {
            header: 'Social Media Leads',
            text: 'Enroll places a high priority on data security by implementing a robust role-based login system. This approach ensures that sensitive information is protected through regulated access to minimize errors.'
        },
        {
            header: 'Role-based Login for Data Security',
            text: ' Manage, distribute, and centralize all incoming leads and inquiries that are obtained from various sources to seize every chance and solve the widespread issue of lead duplication and leakage in the industry.'
        },
        {
            header: '24*7 Support',
            text: 'Our round-the-clock committed support staff is always available to help you at every stage, offering rapid assistance and swift resolution of any problems. Ensuring a seamless user experience.'
        },
    ];
    offerings = [
        {
            text: 'Automate Selection'
        },
        {
            text: 'Tracking of Students'
        },
        {
            text: 'Application Tracking'
        },
        {
            text: 'Gather Inquires'
        },
        {
            text: 'Manage Counselors'
        },
    ];

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private contactService: ContactUsService,
        private alertService: AlertService,
        private title : Title,
        private meta : Meta,
    ) {
    }

    ngOnInit(): void {
        this.refresh();
        this.updateMetaTags()
    }

    refresh() {
        this.contactForm = this.fb.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            contact: [''],
            message: ['', Validators.required]
        });
    }

    openModal() {
        this.isModalOpen = true;
    }

    closeModal() {
        this.isModalOpen = false;
    }

    handleOverlayClick(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (!target.closest('.modal-dialog')) {
            this.closeModal();
        }
    }

    submit() {
        this.submitted = true;
        if (!this.contactForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().name) ? this.contactForm.getRawValue().name : '',
                'email': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().email) ? this.contactForm.getRawValue().email : '',
                'contact': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().contact) ? this.contactForm.getRawValue().contact : '',
                // 'subject': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().subject) ? this.contactForm.getRawValue().subject : '',
                'message': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().message) ? this.contactForm.getRawValue().message : '',
            };
            this.contactService.create(params).subscribe(data => {
                this.submitted = false;
                this.contactForm.patchValue({
                    'name': '',
                    'email': '',
                    'contact': '',
                    'subject': '',
                    'message': ''
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                    // if (error['error']['errors'].subject && error['error']['errors'].subject.length > 0) {
                    //     this.alertService.showErrors(error['error']['errors'].subject[0]);
                    //     return;
                    // }
                    if (error['error']['errors'].message && error['error']['errors'].message.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].message[0]);
                        return;
                    }
                }
            });
        }
    }

    playVideo() {
        const video = this.videoElement.nativeElement;
        const thumbnail = document.getElementById('video-thumbnail');
        if (video) {
            video.classList.remove('d-none');
            video.play();
            if (thumbnail) {
                thumbnail.style.display = 'none';
            }
            const playButton = document.querySelector('.play-button');
            if (playButton) {
                playButton.classList.add('d-none');
            }
        }
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }


    updateMetaTags() {
        this.title.setTitle('Enroll - An Admission Management System Software From Volmint');
        this.meta.updateTag({name: 'description', content: 'Simplify all your admissions needs with Volmint’s Enroll, an admission management system that automates processes, save time, and enhances the applicant experience'});
        this.meta.updateTag({name: 'keyword', content: 'angular, seo, meta tags'});
    }
}
