import {Component, ElementRef, HostListener, OnInit} from '@angular/core';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class HeaderComponent implements OnInit {
    isMenuOpen = false;
    location: any;
    navClass: any;
    listClass: any;
    logo: any;
    navContainer: any;
    selectedImage: any;
    selectedText: any;
    activeMenuItem: string = '';
    isServiceMenuOpen = false;
    selectedServiceText = null;
    selectedServiceImage: any;

    constructor(
        public router: Router,
        location: Location,
        private elementRef: ElementRef,
        private route: ActivatedRoute
    ) {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.location = this.router.url;
                    if (this.location == '/') {
                        this.navClass = 'navbar-area';
                        this.listClass = '';
                        this.navContainer = 'container-fluid';
                        this.logo = 'assets/img/v001 WHITE TEXT LOGO-01.png';
                    } else if (this.location == '/about') {
                        this.navClass = 'navbar-area';
                        this.listClass = '';
                        this.navContainer = 'container-fluid';
                        this.logo = 'assets/img/v001 WHITE TEXT LOGO-01.png';
                    } else if (this.location == '/contact') {
                        this.navClass = 'navbar-area';
                        this.listClass = '';
                        this.navContainer = 'container-fluid';
                        this.logo = 'assets/img/v001 WHITE TEXT LOGO-01.png';
                    } else if (this.location == '/ad-gallery') {
                        this.navClass = 'navbar-area';
                        this.listClass = '';
                        this.navContainer = 'container-fluid';
                        this.logo = 'assets/img/v001 WHITE TEXT LOGO-01.png';
                    } else if (this.location == '/faq') {
                        this.navClass = 'navbar-area';
                        this.listClass = '';
                        this.navContainer = 'container-fluid';
                        this.logo = 'assets/img/v001 WHITE TEXT LOGO-01.png';
                    } else if (this.location == '/careers') {
                        this.navClass = 'navbar-area';
                        this.listClass = '';
                        this.navContainer = 'container-fluid';
                        this.logo = 'assets/img/v001 WHITE TEXT LOGO-01.png';
                    } else if (this.location == '/services') {
                        this.navClass = 'navbar-area';
                        this.listClass = '';
                        this.navContainer = 'container-fluid';
                        this.logo = 'assets/img/v001 WHITE TEXT LOGO-01.png';
                    } else if (this.location == '/projects') {
                        this.navClass = 'navbar-area';
                        this.listClass = '';
                        this.navContainer = 'container-fluid';
                        this.logo = 'assets/img/v001 WHITE TEXT LOGO-01.png';
                    } else if (this.location == '/blog') {
                        this.navClass = 'navbar-area';
                        this.listClass = '';
                        this.navContainer = 'container-fluid';
                        this.logo = 'assets/img/v001 WHITE TEXT LOGO-01.png';
                    } else if (this.location == '/ums') {
                        this.navClass = 'bg-white navbar-area';
                        this.listClass = 'text-black';
                        this.navContainer = 'container-fluid';
                        this.logo = 'assets/img/white-volmint-logo.png';
                    } else if (this.location == '/acricent') {
                        this.navClass = 'bg-white navbar-area';
                        this.listClass = 'text-black';
                        this.navContainer = 'container-fluid';
                        this.logo = 'assets/img/white-volmint-logo.png';
                    } else if (this.location == '/enroll') {
                        this.navClass = 'bg-white navbar-area';
                        this.listClass = 'text-black';
                        this.navContainer = 'container-fluid';
                        this.logo = 'assets/img/white-volmint-logo.png';
                    } else if (this.location == '/ad-gallery') {
                        this.navClass = 'bg-white navbar-area';
                        this.listClass = 'text-black';
                        this.navContainer = 'container-fluid';
                        this.logo = 'assets/img/white-volmint-logo.png';
                    } else if (this.location == '/vcalling') {
                        this.navClass = 'bg-white navbar-area';
                        this.listClass = 'text-black';
                        this.navContainer = 'container-fluid';
                        this.logo = 'assets/img/white-volmint-logo.png';
                    } else if (this.location == '/cms') {
                        this.navClass = 'bg-white navbar-area';
                        this.listClass = 'text-black';
                        this.navContainer = 'container-fluid';
                        this.logo = 'assets/img/white-volmint-logo.png';
                    } else if (this.location == '/careers/careers-details') {
                        this.navClass = 'bg-white navbar-area';
                        this.listClass = 'text-black';
                        this.navContainer = 'container-fluid';
                        this.logo = 'assets/img/white-volmint-logo.png';
                    } else if (this.location == '/cgms') {
                        this.navClass = 'bg-white navbar-area';
                        this.listClass = 'text-black';
                        this.navContainer = 'container-fluid';
                        this.logo = 'assets/img/white-volmint-logo.png';
                    } else if (this.location == '/argus') {
                        this.navClass = 'bg-white navbar-area';
                        this.listClass = 'text-black';
                        this.navContainer = 'container-fluid';
                        this.logo = 'assets/img/white-volmint-logo.png';
                    } else if (this.location == '/ims') {
                        this.navClass = 'bg-white navbar-area';
                        this.listClass = 'text-black';
                        this.navContainer = 'container-fluid';
                        this.logo = 'assets/img/white-volmint-logo.png';
                    } else if (this.location == '/lms') {
                        this.navClass = 'bg-white navbar-area';
                        this.listClass = 'text-black';
                        this.navContainer = 'container-fluid';
                        this.logo = 'assets/img/white-volmint-logo.png';
                    } else if (this.location == '/eclinic') {
                        this.navClass = 'bg-white navbar-area';
                        this.listClass = 'text-black';
                        this.navContainer = 'container-fluid';
                        this.logo = 'assets/img/white-volmint-logo.png';
                    } else if (this.location == '/machine-learning') {
                        this.navClass = 'navbar-area navbar-style-three';
                        this.navContainer = 'container';
                        this.logo = 'assets/img/v001 WHITE TEXT LOGO-01.png';
                    } else if (this.location == '/machine-learning-2') {
                        this.navClass = 'navbar-area navbar-style-four';
                        this.navContainer = 'container-fluid';
                        this.logo = 'assets/img/v001 WHITE TEXT LOGO-01.png';
                    } else if (this.location == '/home-5') {
                        this.navClass = 'navbar-area navbar-style-three';
                        this.navContainer = 'container';
                        this.logo = 'assets/img/v001 WHITE TEXT LOGO-01.png';
                    } else if (this.location == '/home-6' || this.location == '/home-7') {
                        this.navClass = 'navbar-area navbar-area-with-position-relative';
                        this.navContainer = 'container-fluid';
                        this.logo = 'assets/img/v001 WHITE TEXT LOGO-01.png';
                    } else if (this.location == '/home-8') {
                        this.navClass = 'navbar-area navbar-style-three navbar-area-with-position-relative';
                        this.navContainer = 'container-fluid';
                        this.logo = 'assets/img/v001 WHITE TEXT LOGO-01.png';
                    } else if (this.location == '/mobile-application-development') {
                        this.navClass = 'bg-white navbar-area';
                        this.listClass = 'text-black';
                        this.navContainer = 'container-fluid';
                        this.logo = 'assets/img/white-volmint-logo.png';
                    } else if (this.location == '/website-development') {
                        this.navClass = 'bg-white navbar-area';
                        this.listClass = 'text-black';
                        this.navContainer = 'container-fluid';
                        this.logo = 'assets/img/white-volmint-logo.png';
                    } else if (this.location == '/server-management') {
                        this.navClass = 'bg-white navbar-area';
                        this.listClass = 'text-black';
                        this.navContainer = 'container-fluid';
                        this.logo = 'assets/img/white-volmint-logo.png';
                    } else if (this.location == '/search-engine-optimization-service') {
                        this.navClass = 'bg-white navbar-area';
                        this.listClass = 'text-black';
                        this.navContainer = 'container-fluid';
                        this.logo = 'assets/img/white-volmint-logo.png';
                    } else if (this.location == '/social-media-marketing-service') {
                        this.navClass = 'bg-white navbar-area';
                        this.listClass = 'text-black';
                        this.navContainer = 'container-fluid';
                        this.logo = 'assets/img/white-volmint-logo.png';
                    } else if (this.location == '/content-marketing') {
                        this.navClass = 'bg-white navbar-area';
                        this.listClass = 'text-black';
                        this.navContainer = 'container-fluid';
                        this.logo = 'assets/img/white-volmint-logo.png';
                    } else if (this.location == '/advertising-service') {
                        this.navClass = 'bg-white navbar-area';
                        this.listClass = 'text-black';
                        this.navContainer = 'container-fluid';
                        this.logo = 'assets/img/white-volmint-logo.png';
                    } else if (this.location == '/database-devops-serverless') {
                        this.navClass = 'bg-white navbar-area';
                        this.listClass = 'text-black';
                        this.navContainer = 'container-fluid';
                        this.logo = 'assets/img/white-volmint-logo.png';
                    } else if (this.location == '/server-migration-monitoring') {
                        this.navClass = 'bg-white navbar-area';
                        this.listClass = 'text-black';
                        this.navContainer = 'container-fluid';
                        this.logo = 'assets/img/white-volmint-logo.png';
                    } else if (this.location == '/cloud-db-cdn') {
                        this.navClass = 'bg-white navbar-area';
                        this.listClass = 'text-black';
                        this.navContainer = 'container-fluid';
                        this.logo = 'assets/img/white-volmint-logo.png';
                    }
                    else if (this.location == '/case-study') {
                        this.navClass = 'bg-white navbar-area';
                        this.listClass = 'text-black';
                        this.navContainer = 'container-fluid';
                        this.logo = 'assets/img/white-volmint-logo.png';
                    }else {
                        this.navClass = 'navbar-area navbar-style-two';
                        this.navContainer = 'container-fluid';
                        this.logo = 'assets/img/v001 WHITE TEXT LOGO-01.png';
                    }
                }
            });
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.closeMenu();
                this.activeMenuItem = '';
            }
        });
    }

    ngOnInit() {
    }

    toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
        this.isServiceMenuOpen = false;
    }

    closeMenu() {
        this.isMenuOpen = false;
        this.isServiceMenuOpen = false;
    }

    changeContent(image: string, text: string) {
        this.selectedImage = image;
        this.selectedText = text;
    }

    @HostListener('window:scroll', ['$event'])
        onscroll(){
            this.closeMenu()
        }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: Event) {
        const clickedElement = event.target as HTMLElement;

        if (!this.elementRef.nativeElement.contains(clickedElement) && this.isMenuOpen) {
            this.closeMenu();
        }
    }

    toggleServiceMenu() {
        this.isServiceMenuOpen = !this.isServiceMenuOpen;
        this.isMenuOpen = false;
        if (this.selectedServiceText == null) {
            this.selectedServiceText = 'DIGITAL_MARKETING';
        }
    }

    changeServiceContent(text: string) {
        // this.selectedServiceImage = image;
        this.selectedServiceText = text;
    }
}
