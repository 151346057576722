<section class="banner">
    <div class="container-fluid bg-sea-green mt-5">
        <div class="container mt-5 pt-5">
            <div class="row g-5">
                <div class="col-md-6 d-flex text-center text-md-start align-items-center">
                    <div>
                        <h1 class="fw-bolder font-56"><span
                            class="brand-primary-text-color">Maximize Your Online Presence with </span>
                            Comprehensive Digital Marketing Solutions</h1>
                        <p class="my-4 fs-6 text-secondary text-wrap f-20 justify-text">At Volmint Technologies, our
                            digital marketing services are designed to boost your online visibility, with a strong focus
                            on Search Engine Optimization (SEO). We understand that SEO is the backbone of any
                            successful online presence, which is why we employ proven strategies to improve your
                            website's ranking on search engines like Google. From thorough keyword research and on-page
                            optimization to building quality backlinks, we ensure that your website attracts the right
                            audience. Our SEO experts help drive organic traffic, increase your brand's visibility, and
                            ultimately grow your business. Let us optimize your website for success and keep you ahead
                            of the competition.
                        </p>
                        <button class="btn bg-white px-5 rounded-0 border-0 shadow-lg fw-bold hover-effect"
                                (click)="openModal()">View Demo <i
                            class="ms-3 fa-solid fa-arrow-right"></i>
                        </button>
                        <ng-template #contactFormModal>
                            <app-contact-form [isModalOpen]="isModalOpen"
                                              (closeModal)="closeModal()"></app-contact-form>
                        </ng-template>
                        <div *ngIf="isModalOpen" class="modal-overlay" (click)="handleOverlayClick($event)">
                            <ng-container *ngTemplateOutlet="contactFormModal"></ng-container>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex justify-content-end py-5 d-flex justify-content-center">
                    <div
                        class=" md:mr-0 position-relative h-100 w-100 border-0  d-flex justify-content-center border-radius-10">
                        <img src="assets/img/landing-new/seo/intro-seo.png"
                             alt="Image"
                             class="img-fluid border-radius-10 w-100"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container-fluid">
    <div class="container mt-5">
        <div class="row">
            <div class="col-md-6 m-0 p-0 text-right animate-on-scroll-right">
                <img src="assets/img/landing-new/seo/about-seo.png"
                     class="img-fluid border-radius-10"
                     alt="Image">
            </div>
            <div class="col-md-6  animate-on-scroll">
                <h2 class=" fs-1 fw-bolder">Tailored, Results-Driven
                    <span class="brand-primary-text-color"> SEO Solutions </span></h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">At Volmint Technologies, our digital
                    marketing services prioritize Search Engine Optimization (SEO) as a critical component of online
                    success. We specialize in developing customized SEO strategies that enhance your website's
                    visibility on search engines, ensuring that you reach your target audience effectively. Our
                    comprehensive approach includes in-depth keyword research, on-page optimization, and quality
                    backlink building, all designed to drive organic traffic to your site. By continuously analyzing
                    performance metrics and adapting our strategies, we help you achieve sustained growth and a strong
                    online presence. Let us elevate your brand's visibility and unlock the full potential of your
                    digital marketing efforts through effective SEO.</p>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 py-md-5 mt-5 animate-on-scroll">
                <h2 class=" fs-1 fw-bolder">Supercharge Your Business Growth with Advanced
                    <span class="brand-primary-text-color"> Search Engine Optimization</span></h2>
                <p class="fs-6 text-secondary text-wrap justify-text">In today’s competitive digital landscape,
                    effective Search Engine Optimization (SEO) is essential for driving business growth. At Volmint
                    Technologies, we specialize in advanced SEO strategies designed to enhance your online visibility
                    and attract targeted traffic. Our team of experts conducts comprehensive keyword research, optimizes
                    your website’s structure, and creates high-quality content that resonates with your audience. By
                    focusing on both on-page and off-page SEO techniques, we help you achieve higher search engine
                    rankings, improve user engagement, and ultimately increase conversions. Let us partner with you to
                    supercharge your business growth through innovative SEO solutions that deliver measurable results.
                </p>
                <div class="d-lg-flex justify-content-between w-90 flex-wrap d-none">
                    <div class="fw-bold my-1 px-5 py-3 brand-primary-bg text-white fixed-width text-center"
                         *ngFor="let item of powerfullToUse">
                        {{ item.text }}
                    </div>
                </div>
                <div class="col-12 d-block d-lg-none mt-4">
                    <p class="fs-6 text-secondary" *ngFor="let item of powerfullToUse"><img
                        src="assets/img/project/check.png"
                        alt="Image"
                        class="img-fluid"> {{ item.text }}
                    </p>
                </div>
            </div>
            <div class="col-md-6 pt-5 d-flex justify-content-center animate-on-scroll-right">
                <img src="assets/img/landing-new/seo/business-growth-seo.png"
                     class="img-fluid w-100 border-radius-10" alt="">
            </div>
        </div>
    </div>
</div>

<div class="container-fluid bg-purple">
    <div class="container mt-5 pt-5 pb-5">
        <div class="row ">
            <div class="col-md-6">
                <h2 class=" fs-1 fw-bolder">
                    <span class="brand-primary-text-color">Key Features of</span> Search Engine Optimization</h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">Search Engine Optimization (SEO) is essential
                    for improving your website's visibility and driving targeted traffic. At Volmint Technologies, we
                    focus on several key features that make our SEO strategies effective. First, we conduct thorough
                    keyword research, identifying the terms and phrases your potential customers are searching for. This
                    ensures that your content aligns with user intent, enhancing the likelihood of conversion.
                </p>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">Next, our on-page optimization techniques
                    improve the structure and content of your website. We optimize title tags, meta descriptions, header
                    tags, and internal linking to enhance user experience and search engine crawlability. Additionally,
                    we emphasize high-quality content creation, producing engaging and relevant articles, blogs, and
                    landing pages that not only rank well but also resonate with your audience.
                </p>
                <div class="row g-3">
                    <div class="col-sm-4 col-6 px-2 my-2"
                         *ngFor="let item of features">
                        <div class="card bg-white text-center py-2 fixed-width-span border-0 shadow-lg p-2">
                               <span>
                            <img src="{{item.icon}}" alt="" class="img-fluid w-25">
                        </span>
                            <span class="ms-2 text-secondary pt-2">
                            {{ item.text }}
                        </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6  justify-content-md-end">
                <img src="assets/img/landing-new/seo/key-feature-seo.jpg" alt="Image"
                     class="img-fluid border-radius-10">

                <p class="my-4 fs-6 text-secondary text-wrap justify-text">Off-page SEO is another crucial aspect of our
                    strategy, where we focus on building high-quality backlinks from reputable sources. This not only
                    boosts your website's authority but also enhances its trustworthiness in the eyes of search engines.
                    Lastly, we utilize advanced analytics and reporting tools to monitor your website’s performance
                    continuously. This allows us to make data-driven adjustments to your SEO strategy, ensuring
                    sustained growth and visibility in a constantly evolving digital landscape. With these key features,
                    we empower your business to achieve its online goals effectively.
                </p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="container">
        <div class="row gx-4">
            <div class="col-md-6 py-5 animate-on-scroll">
                <h2 class=" fs-1 fw-bolder">
                     <span
                         class="brand-primary-text-color"> On-Page Optimization </span>
                    : Elevate Your Website's Performance</h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">On-page optimization is a fundamental aspect
                    of effective Search Engine Optimization (SEO) that focuses on enhancing individual web pages to rank
                    higher and earn more relevant traffic. At Volmint Technologies, we specialize in fine-tuning key
                    elements of your website, such as title tags, meta descriptions, header tags, and keyword placement,
                    to ensure they align with search engine algorithms and user intent. Our team employs best practices
                    to improve site structure and navigation, making it easier for both users and search engines to
                    access your content. By optimizing images, improving page load speeds, and ensuring mobile
                    responsiveness, we create a seamless user experience that encourages visitors to stay longer and
                    engage more. Investing in our on-page optimization services not only enhances your website’s
                    visibility but also drives meaningful traffic that can lead to increased conversions and business
                    growth. Let us help you unlock your website’s full potential today!
                </p>
            </div>
            <div class="col-md-6 pt-5 text-right animate-on-scroll-right">
                <img src="assets/img/landing-new/seo/on-page-seo.png"
                     class="ms-2 img-fluid border-radius-10" alt="Image"/>
            </div>

            <div class="col-md-6 text-right animate-on-scroll-right">
                <img src="assets/img/landing-new/seo/off-page-seo.png"
                     class="w-100 border-radius-10" alt="">
            </div>
            <div class="col-md-6 ps-md-5 animate-on-scroll">
                <h2 class="fs-1 fw-bolder">
                    <span
                        class="brand-primary-text-color">Off-Page SEO Strategies </span> : Build Authority and Drive
                    Traffic</h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">Off-page SEO strategies are crucial for
                    establishing your website's authority and credibility in the digital landscape. At Volmint
                    Technologies, we implement targeted tactics designed to enhance your brand's online presence beyond
                    your website. Our approach includes building high-quality backlinks from reputable websites, which
                    not only improves your search engine rankings but also drives referral traffic. We focus on creating
                    engaging content that encourages sharing across social media platforms, fostering genuine
                    interactions and increasing your reach. Additionally, we leverage influencer collaborations and
                    guest posting opportunities to enhance your brand's visibility in your industry. By investing in our
                    off-page SEO services, you will strengthen your website's authority, improve its reputation, and
                    attract more potential customers, ultimately driving sustainable growth for your business. Let us
                    help you elevate your online presence and connect with your audience effectively!
                </p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="container mt-5">
        <div class="row gx-4">
            <div class="col-md-6 animate-on-scroll">
                <h2 class=" fs-1 fw-bolder">
                     <span
                         class="brand-primary-text-color">  Technical SEO </span>
                    : Optimize Your Website for Success</h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">Technical SEO is a vital component of your
                    website’s overall performance, ensuring that search engines can efficiently crawl, index, and rank
                    your pages. At Volmint Technologies, we specialize in comprehensive technical SEO solutions designed
                    to enhance your site’s infrastructure and functionality. Our team conducts in-depth audits to
                    identify and resolve issues such as slow page load times, broken links, and duplicate content, all
                    of which can negatively impact user experience and search rankings. We also implement essential
                    practices like structured data markup, XML sitemaps, and secure HTTPS protocols to boost your
                    website's visibility in search results. By prioritizing technical SEO, we help you create a robust
                    foundation for your online presence, making it easier for both search engines and users to navigate
                    your site. Invest in our technical SEO services today to ensure your website operates at peak
                    performance and stays ahead of the competition!
                </p>
            </div>
            <div class="col-md-6 text-right animate-on-scroll-right">
                <img src="assets/img/landing-new/seo/technical-seo.png"
                     class="ms-2 img-fluid border-radius-10" alt="Image"/>
            </div>

            <div class="col-md-6 mt-5 text-right animate-on-scroll-right">
                <img src="assets/img/landing-new/seo/local-seo.png"
                     class="w-100 border-radius-10" alt="">
            </div>
            <div class="col-md-6 py-5 ps-md-5 animate-on-scroll">
                <h2 class="fs-1 fw-bolder">
                    <span
                        class="brand-primary-text-color">Local SEO Optimization </span> : Connect with Your Community
                </h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">Local SEO optimization is essential for
                    businesses looking to attract customers in their geographic area. At Volmint Technologies, we
                    specialize in strategies that enhance your visibility in local search results, helping you connect
                    with your community and drive foot traffic to your physical location. Our team optimizes your Google
                    My Business profile, ensuring that your business information is accurate and engaging, which
                    improves your chances of appearing in local searches and Google Maps. We also focus on building
                    local citations and encouraging customer reviews to strengthen your online reputation and
                    credibility. By targeting location-specific keywords and creating locally relevant content, we help
                    you rank higher in local search queries. Investing in our local SEO services will not only increase
                    your visibility but also position your business as a trusted option within your community. Let us
                    help you grow your local presence and attract more customers today!
                </p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="container">
        <div class="row gx-4">
            <div class="col-md-6 animate-on-scroll">
                <h2 class=" fs-1 fw-bolder">
                     <span
                         class="brand-primary-text-color">  Why SEO is Essential </span>
                    for Your Business</h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">Search Engine Optimization (SEO) is a
                    critical component of any successful digital marketing strategy, as it directly impacts your online
                    visibility and customer engagement. In today’s competitive market, having a well-optimized website
                    ensures that your business stands out from the crowd. SEO helps improve your search engine rankings,
                    making it easier for potential customers to find your products or services when searching online. By
                    driving organic traffic to your site, SEO not only increases brand awareness but also enhances
                    credibility and trust among users. Moreover, effective SEO strategies lead to higher conversion
                    rates, as targeted traffic is more likely to engage with your content and make purchases. Investing
                    in SEO is not just about attracting more visitors; it's about attracting the right visitors who are
                    genuinely interested in what you offer. With our expert SEO services at Volmint Technologies, you
                    can unlock your business's full potential and achieve long-term growth in the digital landscape.
                    Don’t miss out on the opportunity to elevate your brand—partner with us today!
                </p>
                <button class="btn text-white brand-primary-bg border px-5 rounded-0 my-5 hover-white fw-bold"
                        (click)="openModal()">Book Appointment
                    <i
                        class="ms-3 fa-solid fa-arrow-right"></i>
                </button>
            </div>
            <div class="col-md-6 text-right animate-on-scroll-right">
                <img src="assets/img/landing-new/seo/why-seo.png"
                     class="ms-2 img-fluid border-radius-10" alt="Image"/>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid brand-primary-bg mt-5">
    <div class="container py-5">
        <h1 class="text-white text-center mt-5 fw-bold justify-text">What Makes Our SEO Services Stand Out from the
            Competition?</h1>
        <p class="text-white text-center">At Volmint Technologies, our SEO services stand out from the competition due
            to our personalized approach and commitment to delivering measurable results. We take the time to understand
            your unique business goals and target audience, allowing us to develop customized SEO strategies tailored to
            your specific needs. Our team of experienced professionals employs the latest industry techniques and tools,
            ensuring that we stay ahead of search engine algorithm changes. We prioritize transparency and
            communication, providing regular performance reports and insights to keep you informed of our progress.
            Additionally, our holistic approach combines on-page optimization, technical SEO, content creation, and
            off-page strategies, ensuring that every aspect of your online presence is optimized for success. With a
            focus on long-term growth and sustainability, we empower your business to achieve its full potential in the
            digital landscape.
        </p>
        <div class="row gy-5 py-5">
            <div class="col-md-4" *ngFor="let item of whyChoose; let i = index">
                <div class="h-w-40 text-center rounded-circle bg-white pt-2 fw-bold text-black ">{{ i + 1 }}</div>
                <h3 class="text-white my-4 fw-bold">{{ item.header }}</h3>
                <p class="text-white justify-text">{{
                        item.text
                    }}</p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid py-5">
    <div class="container">
        <div class="row py-1 gx-5">
            <div class="col-md-6">
                <h2 class=" fs-1 fw-bolder">Explore Our All-Encompassing
                    <span class="brand-primary-text-color"> SEO Services </span>
                </h2>
                <p class="my-5 fs-6 text-secondary text-wrap justify-text">At Volmint Technologies, we invite you to
                    explore our all-encompassing SEO services designed to elevate your online presence and drive
                    meaningful results for your business. Our comprehensive approach begins with an in-depth analysis of
                    your website and industry, allowing us to identify key opportunities for improvement. We specialize
                    in customized strategies that include thorough keyword research, on-page optimization, and technical
                    SEO to ensure your site is fully optimized for search engines. Our expert team also focuses on
                    high-quality content creation that resonates with your target audience and enhances user engagement.
                    Additionally, our off-page SEO tactics, including link building and social media integration, help
                    establish your brand’s authority and expand your reach. With our continuous monitoring and
                    data-driven insights, we adapt and refine our strategies to ensure sustained growth and success in a
                    competitive digital landscape. Partner with us to unlock the full potential of your business through
                    our comprehensive SEO services!</p>
                <div class="row g-3 mt-5">
                    <div class="col-12 px-0 my-3 "
                         *ngFor="let item of offerings">
                        <span>
                            <img src="assets/img/project/check.png" alt="Image" class="img-fluid">
                        </span>
                        <span class="ms-2 fw-bold text-secondary">
                            {{ item.text }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-md-6 d-flex justify-content-end">
                <img src="assets/img/landing-new/seo/explore-seo.jpg" class="img-fluid"
                     alt="Image">
            </div>
        </div>

    </div>
</div>

<div class="container-fluid">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="col-12">
                    <img src="assets/img/landing-new/mobile-development/faq.png" alt="Image" class="img-fluid w-50"/>
                    <p class="fs-1 fw-bold">Frequently Asked Questions</p>
                    <p class="text-secondary">Have a question that is not answered? You can contact us at</p>
                    <p class="brand-primary-text-color fw-bold">Email: <a href="mailto:support@volmint.com"
                                                                          class="text-decoration-underline brand-primary-text-color">support@volmint.com</a>
                    </p>
                    <button type="submit"
                            class="brand-primary-bg border-0 color-white px-5 py-2 fs-6 mt-4 fw-bold hover-effect"
                            (click)="goTo('/faq')">
                        View All Faqs
                    </button>
                </div>
            </div>
            <div class="col-12 col-md-6 mt-4 animate-on-scroll-right">
                <div class="accordion" id="faqAccordion">
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span class="fs-6 fw-bold" style="color: #03738C;">
                                    <div class="row">
                                        <div class="col-1">
                                          <div class="encircled-icon float-start">
                                                <i class="fas fa-question fw-bold"></i>
                                          </div>
                                        </div>
                                        <div class="col-11">
                                        What is SEO and why is it important for my business?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                SEO, or Search Engine Optimization, is the process of optimizing your website to improve
                                its visibility on search engines like Google. It is essential for your business because
                                higher visibility leads to increased organic traffic, better brand awareness, and higher
                                conversion rates, ultimately driving growth and revenue.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <span
                            class="fs-6 fw-bold" style="color: #03738C;">
                             <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon float-start">
                                                <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                 <div class="col-11">
                                    How long does it take to see results from SEO efforts?
                                 </div>
                             </div>
                            </span>
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                SEO is a long-term strategy, and the time it takes to see significant results can vary
                                based on several factors, including the competitiveness of your industry and the
                                effectiveness of the strategies implemented. Generally, clients can expect to see
                                noticeable improvements in their search rankings and traffic within three to six months.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <span class="fs-6 fw-bold" style="color: #03738C;">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon float-start">
                                                <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                        <div class="col-11">
                                            What is the difference between on-page and off-page SEO?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                On-page SEO refers to the optimization of individual web pages to improve their ranking
                                in search results, focusing on elements like content, keywords, meta tags, and internal
                                linking. Off-page SEO, on the other hand, involves external factors that influence your
                                site's authority and ranking, such as backlinks, social media engagement, and online
                                reputation management.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <span class="fs-6 fw-bold" style="color: #03738C;">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon">
                                                <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                        <div class="col-11">
                                          How do you measure the success of SEO campaigns?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h2>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                We measure the success of our SEO campaigns through various metrics, including keyword
                                rankings, organic traffic growth, conversion rates, and user engagement metrics. We
                                provide clients with regular performance reports that highlight key insights and
                                progress, enabling us to adjust strategies as needed for optimal results.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingFive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <span class="fs-6 fw-bold" style="color: #03738C;">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon">
                                        <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                        <div class="col-11">
                                            Can SEO help my business compete in local markets?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h2>
                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Absolutely! Local SEO is designed specifically to help businesses increase their
                                visibility in local search results. By optimizing your Google My Business profile,
                                targeting local keywords, and building local citations, we can enhance your chances of
                                attracting customers in your geographic area, driving more foot traffic to your
                                business.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid ums-bottom-bg ps-5 mt-5">
    <div class="container">
        <div class="row align-items-center py-5">
            <div class="col-md-10 col-12">
                <h1 class="text-white">Ready to Take Your Business Growth to the Next Level?</h1>
            </div>
            <div class="col-md-2 col-12">
                <button class="btn bg-white rounded-0 px-5 py-3 text-black fw-bold hover-effect"
                        (click)="goTo('/contact')">Contact Us
                </button>
            </div>
        </div>
    </div>
</div>
