<!-- Start Footer Area -->
<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-4 col-12">
                <div class="single-footer-widget text-md-start text-center">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/img/landing-new/white-volmint-logo%20.png" alt="image"
                                 style="height: 75px;width: 195px;">
                        </a>
                        <p class="fs-6">Our goal is to deliver a comprehensive software ecosystem that streamlines the entire operational management of businesses. We are committed to simplifying processes for our clients while ensuring their satisfaction with our solutions.</p>
                    </div>
                    <ul class="social">
                        <li><a href="https://www.linkedin.com/company/volmint-technologies/" target="_blank"><img
                            src="assets/img/landing-new/footer/social1.png" alt="Image" class="img-fluid"/></a></li>
                        <li><a href="https://twitter.com/TechVolmint" target="_blank"><img
                            src="assets/img/landing-new/footer/social2.png" alt="Image" class="img-fluid"/></a></li>
                        <li><a href="https://www.instagram.com/volminttechnologies/" target="_blank"><img
                            src="assets/img/landing-new/footer/social3.png" alt="Image" class="img-fluid"/></a></li>
                        <li><a href="https://www.facebook.com/VolmintTechnologies" target="_blank"><img
                            src="assets/img/landing-new/footer/social4.png" alt="Image" class="img-fluid"/></a></li>
                        <li><a href="https://www.youtube.com/channel/UChR9FpeL0Jm1a4ks-rOowPA" target="_blank"><img
                            src="assets/img/landing-new/footer/social6.png" alt="Image" class="img-fluid"/></a></li>
                    </ul>
                </div>
            </div>

            <div
                class="col-lg-2 col-md-2 col-12 d-flex justify-content-lg-end justify-content-md-end justify-content-center">
                <div class="single-footer-widget">
                    <p class="fs-5 fw-bold text-center text-md-start">Explore</p>
                    <ul class="footer-services-list list-unstyled text-center text-md-start">
                        <li class="fs-6"><a routerLink="/about">About</a></li>
                         <li class="fs-6"><a routerLink="/projects">Projects</a></li>
                        <li class="fs-6"><a routerLink="/contact">Contact</a></li>
                        <!-- <li class="fs-6"><a routerLink="/team">Our Team</a></li>-->
                        <li class="fs-6"><a routerLink="/careers">Careers</a></li>
                        <li class="fs-6"><a routerLink="/blog">Blogs</a></li>
                        <li class="fs-6"><a routerLink="/faq">FAQ</a></li>
                        <li class="fs-6"><a routerLink="/ad-gallery">Gallery</a></li>
<!--                        <li class="fs-6"><a routerLink="/case-study">Case Study</a></li>-->

                    </ul>
                </div>
            </div>

            <div
                class="col-lg-3 col-md-2 col-12 d-flex justify-content-lg-end justify-content-md-end justify-content-center">
                <div class="single-footer-widget">
                    <p class="fs-5 fw-bold text-center text-md-start">Products</p>
                    <ul class="quick-links-list text-center text-md-start">
                        <li><a routerLink="/ums">University Management System</a></li>
                        <li><a routerLink="/enroll">Admission Management System</a></li>
                        <li><a routerLink="/cms">College Management System</a></li>
                        <li><a routerLink="/cgms">College Group Management System</a></li>
                        <li><a routerLink="/lms">Learning Management System</a></li>
                        <li><a routerLink="/acricent">Income Tax Return Filing</a></li>
                        <li><a routerLink="/ims">Institute Management System</a></li>
                        <li><a routerLink="/eclinic">Digital Clinic Management System</a></li>
                        <li><a routerLink="/vcalling">Volmint Calling System</a></li>
                    </ul>
                </div>
            </div>

            <div
                class="col-lg-3 col-md-4 col-12 d-flex justify-content-lg-end justify-content-md-end justify-content-center">
                <div class="single-footer-widget">
                    <p class="fs-5 fw-bold text-center text-md-start">Contacts</p>
                    <ul class="list-unstyled footer-contact-list">
                        <li class="d-flex align-items-center mb-3">
                            <img src="assets/img/landing-new/footer/location.png" alt="Location" class="img-fluid me-3">
                            <div>
                                <strong>Address:</strong><br/>
                                29/2, Vijay Enclave,<br/> Dwarka-Palam Road, <br/>Delhi-110045
                            </div>
                        </li>
                        <li class="d-flex align-items-center mb-3">
                            <img src="assets/img/landing-new/footer/email.png" alt="Email" class="img-fluid me-3">
                            <div>
                                <strong>Email:</strong><br/> <a
                                href="mailto:support@volmint.com">support@volmint.com</a>
                            </div>
                        </li>
                        <li class="d-flex align-items-center mb-3">
                            <img src="assets/img/landing-new/footer/contact.png" alt="Phone" class="img-fluid me-3">
                            <div>
                                <strong>Phone:</strong><br/> <a href="tel:+919310828501">+91 9310828501</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <p> © Copyright 2024. All rights reserved.</p>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <ul>
                        <li><a routerLink="terms-and-conditions">Terms & Conditions</a></li>
                        <li><a routerLink="privacy-policy">Privacy Policy</a></li>
                        <!-- <li><a routerLink="refund-and-Cancellation-Policy">Refund & Cancellation Policy</a></li>-->
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="circle-map"><img src="assets/img/circle-map.png" alt="image"></div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>
<!-- End Footer Area -->


<div class="whatsapp-btn-wrapper">
    <div class="wave"></div>
    <div class="wave"></div>
    <a
        href="https://wa.me/+919310828501"
        class="whatsapp-btn"
        target="_blank"
        rel="noopener noreferrer"
    >
        <i class="fab fa-whatsapp fs-2"></i>
    </a>
</div>

<div class="go-top"><i class="fas fa-arrow-up"></i><i class="fas fa-arrow-up"></i></div>
