<section class="banner">
    <div class="container-fluid bg-sea-green mt-5">
        <div class="container mt-5 pt-5">
            <div class="row g-5">
                <div class="col-md-6 d-flex text-center text-md-start align-items-center">
                    <div>
                        <h1 class="fw-bolder font-56"><span
                            class="brand-primary-text-color"> Unleash Your Brand’s Potential </span>
                            Comprehensive Social Media Marketing Solutions</h1>
                        <p class="my-4 fs-6 text-secondary text-wrap f-20 justify-text">At Volmint Technologies, we
                            believe that effective social media marketing is the key to unlocking your brand's full
                            potential. Our comprehensive SMM services cover every aspect of digital marketing, ensuring
                            that your brand gains enhanced visibility and recognition across all platforms. From
                            targeted advertising that reaches your ideal audience to strategies designed to improve
                            customer engagement, we tailor our approach to meet your unique needs. Our team provides
                            valuable insights and analytics, enabling you to make data-driven decisions that drive
                            growth. Let us help you elevate your online presence and transform your brand into a
                            powerful force in the digital landscape.
                        </p>
                        <button class="btn bg-white px-5 rounded-0 border-0 shadow-lg fw-bold hover-effect"
                                (click)="openModal()">View Demo <i
                            class="ms-3 fa-solid fa-arrow-right"></i>
                        </button>
                        <ng-template #contactFormModal>
                            <app-contact-form [isModalOpen]="isModalOpen"
                                              (closeModal)="closeModal()"></app-contact-form>
                        </ng-template>
                        <div *ngIf="isModalOpen" class="modal-overlay" (click)="handleOverlayClick($event)">
                            <ng-container *ngTemplateOutlet="contactFormModal"></ng-container>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex justify-content-end py-5 d-flex justify-content-center">
                    <div
                        class=" md:mr-0 position-relative h-100 w-100 border-0  d-flex justify-content-center border-radius-10">
                        <img src="assets/img/landing-new/social-media-marketing/info-social-media.png"
                             alt="Image"
                             class="img-fluid border-radius-10 w-100"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container-fluid">
    <div class="container py-5">
        <div class="row">
            <div class="col-md-6 m-0 p-0 text-right animate-on-scroll">
                <img src="assets/img/landing-new/social-media-marketing/about-social-media.jpg"
                     class="img-fluid border-radius-10"
                     alt="Image">
            </div>
            <div class="col-md-6 ps-md-5 py-md-5 py-4 animate-on-scroll-right">
                <h2 class=" fs-1 fw-bolder"> Elevate Your Brand
                    <span class="brand-primary-text-color"> Comprehensive Social Media Marketing Services</span></h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">In today's digital age, having a strong
                    social media presence is essential for any business looking to thrive. At Volmint Technologies, we
                    specialize in Social Media Marketing (SMM) that empowers your brand to stand out in a crowded
                    marketplace. Our all-encompassing SMM services are designed to enhance brand awareness, ensuring
                    your message reaches the right audience at the right time. We utilize targeted advertising
                    strategies that maximize your reach and return on investment, driving meaningful engagement with
                    your customers.</p>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">
                    Our expert team harnesses the power of social media to foster improved customer relationships,
                    providing you with the tools to connect and interact with your audience effectively. With our
                    in-depth insights and analytics, we equip you with the knowledge needed to refine your marketing
                    efforts and achieve sustained growth. Let us partner with you to create a compelling social media
                    presence that not only captivates your audience but also elevates your brand to new heights.
                </p>
            </div>
        </div>

        <div class="row mt-md-5">
            <div class="col-md-6 py-md-5 animate-on-scroll-right">
                <h2 class=" fs-1 fw-bolder">Ignite Your Business Growth with
                    <span class="brand-primary-text-color">  Powerful Social Media Marketing Solutions!</span></h2>
                <p class="fs-6 text-secondary text-wrap justify-text">In today’s digital landscape, harnessing the power
                    of social media marketing is crucial for driving business growth and achieving long-term success.
                    Studies show that 73% of marketers believe that their efforts through social media marketing have
                    been “somewhat effective” or “very effective” for their business. With over 4.7 billion active
                    social media users worldwide, your brand has the potential to reach a vast audience, connect with
                    customers, and cultivate lasting relationships. At Volmint Technologies, we leverage data-driven
                    strategies to create tailored social media campaigns that not only enhance brand awareness but also
                    drive engagement and conversions. Businesses that actively engage with their audience on social
                    media can see a 20-40% increase in customer retention rates. Let us help you capitalize on these
                    opportunities, supercharge your marketing efforts, and propel your business toward unprecedented
                    growth and success.
                </p>
                <div class="d-lg-flex justify-content-between w-90 flex-wrap d-none">
                    <div class="fw-bold my-1 px-5 py-3 brand-primary-bg text-white fixed-width text-center"
                         *ngFor="let item of powerfullToUse">
                        {{ item.text }}
                    </div>
                </div>
                <div class="col-12 d-block d-lg-none mt-4">
                    <p class="fs-6 text-secondary" *ngFor="let item of powerfullToUse"><img
                        src="assets/img/project/check.png"
                        alt="Image"
                        class="img-fluid"> {{ item.text }}
                    </p>
                </div>
            </div>
            <div class="col-md-6 pt-5 animate-on-scroll">
                <img src="assets/img/landing-new/social-media-marketing/business-social-media.png"
                     class="img-fluid w-100 border-radius-10" alt="">
            </div>
        </div>
    </div>
</div>

<div class="container-fluid bg-purple">
    <div class="container">
        <div class="row py-5">
            <div class="col-md-6 py-5">
                <h2 class=" fs-1 fw-bolder">
                    Key Features of <span class="brand-primary-text-color">Social Media Marketing</span></h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">Social media marketing encompasses several
                    key features designed to enhance brand presence and engagement. It begins with the creation and
                    curation of engaging content tailored to the audience's interests, coupled with advanced audience
                    targeting to reach specific demographics. Effective ad campaign management ensures maximum
                    visibility and return on investment. Additionally, social listening allows brands to monitor
                    conversations and trends, gaining valuable insights into customer sentiment. Performance analytics
                    provide actionable insights for continuous improvement, while community management fosters
                    engagement by responding to audience interactions. Collaborating with influencers amplifies brand
                    messages, and cross-platform integration ensures a cohesive brand experience. Furthermore,
                    reputation management maintains a positive image by addressing negative feedback, and staying
                    abreast of trends enables brands to adapt their strategies for maximum relevance and impact.
                </p>
                <div class="row g-3">
                    <div class="col-sm-4 col-6 px-2 my-2"
                         *ngFor="let item of features">
                        <div class="card bg-white text-center py-2 fixed-width-span border-0 shadow-lg p-2">
                               <span>
                            <img src="{{item.icon}}" alt="" class="img-fluid w-25">
                        </span>
                            <span class="ms-2 text-secondary pt-2">
                            {{ item.text }}
                        </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6  justify-content-md-end">
                <img src="assets/img/landing-new/social-media-marketing/key-feature-social-media.jpg" alt="Image"
                     class="img-fluid border-radius-10">
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="container">
        <div class="row gx-4">
            <div class="col-md-6 py-5 animate-on-scroll">
                <h2 class=" fs-1 fw-bolder">
                    Boost Your<span class="brand-primary-text-color"> Brand Visibility</span></h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">
                    In the competitive digital landscape, boosting your brand visibility is essential for standing out
                    and capturing your audience's attention. At Volmint Technologies, our social media marketing
                    services are designed to elevate your brand's presence across multiple platforms, ensuring that your
                    message reaches a wider audience. By leveraging targeted advertising, engaging content, and
                    strategic influencer partnerships, we create campaigns that resonate with your ideal customers. Our
                    comprehensive approach not only increases awareness but also fosters trust and credibility,
                    positioning your brand as a leader in your industry. With our expertise, you can transform your
                    social media channels into powerful tools for brand growth, driving both engagement and loyalty
                    while enhancing your overall online visibility.
                </p>
            </div>
            <div class="col-md-6 pt-5 text-right animate-on-scroll-right">
                <img src="assets/img/landing-new/social-media-marketing/Enhanced-Brand-Awareness.png"
                     class="ms-2 img-fluid border-radius-10" alt="Image"/>
            </div>

            <div class="col-md-6  text-right animate-on-scroll-right">
                <img src="assets/img/landing-new/social-media-marketing/Targeted-Advertising.png"
                     class="w-100 border-radius-10" alt="">
            </div>
            <div class="col-md-6  ps-md-5 animate-on-scroll">
                <h2 class="fs-1 fw-bolder">
                    <span
                        class="brand-primary-text-color">Precision Targeting </span>for Maximum Impact</h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">In today's dynamic social media landscape,
                    precision targeting is key to maximizing the impact of your advertising campaigns. At Volmint
                    Technologies, we utilize advanced targeting techniques to ensure your ads reach the right audience
                    at the right time. By analyzing user demographics, interests, and behaviors, we craft tailored
                    campaigns that resonate with potential customers, significantly improving engagement and conversion
                    rates. Our data-driven approach allows us to optimize ad spend effectively, ensuring that every
                    dollar is invested wisely. Whether you aim to generate leads, drive traffic, or boost sales, our
                    precision targeting strategies empower your brand to connect authentically with your audience,
                    ultimately driving meaningful results and fostering lasting relationships.
                </p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="container">
        <div class="row gx-4">
            <div class="col-md-6 py-5 animate-on-scroll">
                <h2 class=" fs-1 fw-bolder">
                     <span
                         class="brand-primary-text-color"> Elevating </span>
                    Customer Engagement</h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">
                    Elevating customer engagement is at the heart of effective social media marketing, and at Volmint
                    Technologies, we excel in creating authentic connections between your brand and its audience. Our
                    strategic approach involves crafting compelling content that encourages interaction, from
                    thought-provoking posts to engaging polls and contests. By actively responding to comments,
                    messages, and reviews, we foster a sense of community and belonging, making your customers feel
                    valued and heard. We also leverage insights from audience interactions to tailor our strategies
                    further, ensuring that our messaging resonates deeply with your target demographic. With our focus
                    on elevating customer engagement, we not only enhance brand loyalty but also create advocates who
                    are eager to share their positive experiences with others, ultimately driving sustainable growth for
                    your business.
                </p>
            </div>
            <div class="col-md-6 pt-5 text-right animate-on-scroll-right">
                <img src="assets/img/landing-new/social-media-marketing/customer-engagment.jpg"
                     class="ms-2 img-fluid border-radius-10" alt="Image"/>
            </div>

            <div class="col-md-6 pt-5 text-right animate-on-scroll-right">
                <img src="assets/img/landing-new/social-media-marketing/valuable-insights.jpg"
                     class="w-100 border-radius-10" alt="">
            </div>
            <div class="col-md-6 py-5 ps-md-5 animate-on-scroll">
                <h2 class="fs-1 fw-bolder">
                    <span
                        class="brand-primary-text-color">Actionable Insights  </span> and Analytics
                </h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">In the realm of social media marketing,
                    actionable insights and analytics are crucial for informed decision-making and strategy refinement.
                    At Volmint Technologies, we utilize advanced analytics tools to track and measure the performance of
                    your social media campaigns in real time. By analyzing key metrics such as engagement rates,
                    audience demographics, and conversion statistics, we provide you with a comprehensive understanding
                    of what works and what doesn’t. These insights empower you to make data-driven adjustments, optimize
                    your marketing strategies, and allocate resources more effectively. With our focus on delivering
                    actionable insights, we help you stay ahead of trends, capitalize on emerging opportunities, and
                    ensure that your social media efforts yield maximum results, ultimately driving sustained growth for
                    your brand.
                </p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="container">
        <div class="row gx-4">
            <div class="col-md-6 py-5">
                <h2 class=" fs-1 fw-bolder">
                     <span
                         class="brand-primary-text-color">  The Importance of Social Media Marketing</span>
                    for Your Business </h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">Social media marketing (SMM) has become an
                    indispensable component of any successful business strategy in today's digital world. With billions
                    of active users across platforms like Facebook, Instagram, and Twitter, SMM provides unparalleled
                    access to a vast audience, allowing brands to connect with potential customers where they spend much
                    of their time. By leveraging social media, businesses can enhance brand awareness, foster customer
                    loyalty, and drive website traffic, all while engaging directly with their audience through
                    meaningful interactions. Furthermore, SMM enables businesses to gather valuable insights into
                    customer preferences and behaviors, informing product development and marketing strategies. In a
                    landscape where competition is fierce, integrating social media marketing into your business model
                    not only boosts visibility but also cultivates a community of loyal customers who advocate for your
                    brand, ultimately leading to sustainable growth and success.
                </p>
                <button class="btn text-white brand-primary-bg border px-5 rounded-0 my-5 hover-white fw-bold"
                        (click)="openModal()">Book Appointment
                    <i
                        class="ms-3 fa-solid fa-arrow-right"></i>
                </button>
            </div>
            <div class="col-md-6 pt-5 text-right">
                <img src="assets/img/landing-new/social-media-marketing/business-social-media.jpg"
                     class="ms-2 img-fluid border-radius-10" alt="Image"/>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid brand-primary-bg mt-5">
    <div class="container py-5">
        <h1 class="text-white text-center mt-5 fw-bold justify-text">What Sets Our Social Media Marketing Services
            Apart?</h1>
        <p class="text-white text-center">At Volmint Technologies, our social media marketing services stand out from
            the competition due to our commitment to delivering tailored, results-driven strategies that align with your
            unique business goals. We take the time to understand your brand, audience, and industry landscape, enabling
            us to create personalized campaigns that resonate deeply with your target market. Our team of experienced
            professionals employs cutting-edge tools and analytics to track performance and optimize campaigns in real
            time, ensuring maximum impact. Additionally, we emphasize transparent communication and collaboration,
            keeping you informed every step of the way. With a focus on creativity, innovation, and measurable results,
            we not only elevate your brand’s online presence but also foster genuine engagement and loyalty among your
            customers, setting us apart as a leader in the social media marketing space.
        </p>
        <div class="row gy-5 py-5">
            <div class="col-md-4" *ngFor="let item of whyChoose; let i = index">
                <div class="h-w-40 text-center rounded-circle bg-white pt-2 fw-bold text-black ">{{ i + 1 }}</div>
                <h3 class="text-white my-4 fw-bold">{{ item.header }}</h3>
                <p class="text-white justify-text">{{
                        item.text
                    }}</p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid py-5">
    <div class="container">
        <div class="row py-5 gx-5">
            <div class="col-md-6 py-md-5">
                <h2 class=" fs-1 fw-bolder">Discover Our Comprehensive
                    <span class="brand-primary-text-color"> Social Media Marketing Services </span>
                </h2>
                <p class="my-5 fs-6 text-secondary text-wrap justify-text">At Volmint Technologies, we offer a
                    comprehensive suite of social media marketing services designed to elevate your brand and drive
                    meaningful engagement. Our approach encompasses everything from strategic planning and content
                    creation to targeted advertising and community management, ensuring that every aspect of your social
                    media presence is optimized for success. We leverage the latest tools and trends to develop
                    data-driven campaigns that resonate with your audience, enhancing brand visibility and fostering
                    loyalty. With our expert team by your side, you’ll have access to personalized strategies that align
                    with your unique business goals, delivering measurable results across multiple platforms. Discover
                    how our all-encompassing SMM services can transform your online presence and propel your business
                    toward sustained growth and success.</p>
                <div class="row g-3 mt-5">
                    <div class="col-12 px-0 my-3 "
                         *ngFor="let item of offerings">
                        <span>
                            <img src="assets/img/project/check.png" alt="Image" class="img-fluid">
                        </span>
                        <span class="ms-2 fw-bold text-secondary">
                            {{ item.text }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-md-6 pt-5 d-flex justify-content-end">
                <img src="assets/img/landing-new/social-media-marketing/discover-social-media.jpg" class="img-fluid"
                     alt="Image">
            </div>
        </div>

    </div>
</div>

<div class="container-fluid">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="col-12">
                    <img src="assets/img/landing-new/mobile-development/faq.png" alt="Image" class="img-fluid w-50"/>
                    <p class="fs-1 fw-bold">Frequently Asked Questions</p>
                    <p class="text-secondary">Have a question that is not answered? You can contact us at</p>
                    <p class="brand-primary-text-color fw-bold">Email: <a href="mailto:support@volmint.com"
                                                                          class="text-decoration-underline brand-primary-text-color">support@volmint.com</a>
                    </p>
                    <button type="submit"
                            class="brand-primary-bg border-0 color-white px-5 py-2 fs-6 mt-4 fw-bold hover-effect"
                            (click)="goTo('/faq')">
                        View All Faqs
                    </button>
                </div>
            </div>
            <div class="col-12 col-md-6 mt-4 animate-on-scroll-right">
                <div class="accordion" id="faqAccordion">
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span class="fs-6 fw-bold" style="color: #03738C;">
                                    <div class="row">
                                        <div class="col-1">
                                          <div class="encircled-icon float-start">
                                                <i class="fas fa-question fw-bold"></i>
                                          </div>
                                        </div>
                                        <div class="col-11">
                                        What types of businesses can benefit from your social media marketing services?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Our social media marketing services are designed for businesses of all sizes and
                                industries. Whether you’re a small local business or a large corporation, we tailor our
                                strategies to meet your unique goals and target audience.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <span
                            class="fs-6 fw-bold" style="color: #03738C;">
                             <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon float-start">
                                                <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                 <div class="col-11">
                                   How do you measure the success of social media marketing campaigns?
                                 </div>
                             </div>
                            </span>
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                We measure success through key performance indicators (KPIs) such as engagement rates,
                                reach, conversions, and return on investment (ROI). Our analytics tools provide detailed
                                reports that help you understand the effectiveness of our campaigns and guide future
                                strategies.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <span class="fs-6 fw-bold" style="color: #03738C;">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon float-start">
                                                <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                        <div class="col-11">
                                            How often will you post on our social media channels?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                The frequency of posts will depend on your specific goals and audience engagement
                                levels. We work with you to develop a content calendar that outlines posting schedules,
                                ensuring consistent and relevant content while optimizing engagement.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <span class="fs-6 fw-bold" style="color: #03738C;">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon">
                                                <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                        <div class="col-11">
                                          Can you help us with paid advertising on social media?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h2>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Absolutely! We specialize in creating and managing targeted advertising campaigns on
                                various social media platforms. Our team will design ads that align with your business
                                objectives and track their performance for optimal results.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingFive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <span class="fs-6 fw-bold" style="color: #03738C;">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon">
                                        <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                        <div class="col-11">
                                            What kind of content will you create for our brand?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h2>
                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                We focus on developing a diverse range of content tailored to your audience, including
                                engaging graphics, informative articles, videos, polls, and more. Our goal is to create
                                content that not only informs but also entertains and encourages interaction with your
                                brand.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid ums-bottom-bg ps-5 mt-5">
    <div class="container">
        <div class="row align-items-center py-5">
            <div class="col-md-10 col-12">
                <h1 class="text-white">Ready to Take Your Business Growth to the Next Level?</h1>
            </div>
            <div class="col-md-2 col-12">
                <button class="btn bg-white rounded-0 px-5 py-3 text-black fw-bold hover-effect"
                        (click)="goTo('/contact')">Contact Us
                </button>
            </div>
        </div>
    </div>
</div>
