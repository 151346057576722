<section class="banner">
    <div class="container-fluid bg-sea-green mt-5">
        <div class="container mt-5 pt-5">
            <div class="row g-5">
                <div class="col-md-6 d-flex text-center text-md-start align-items-center">
                    <div>
                        <h1 class="fw-bolder font-56"><span
                            class="brand-primary-text-color">Engage, Inspire, and Convert with </span>
                            Tailored Content Marketing Solutions</h1>
                        <p class="my-4 fs-6 text-secondary text-wrap f-20 justify-text">At Volmint Technologies, we
                            specialize in delivering comprehensive content marketing strategies that captivate your
                            audience and drive results. From crafting audience-centric content to optimizing it for SEO,
                            we ensure that your message resonates across diverse formats—whether blogs, videos, or
                            social media posts. Our expert team measures performance with precision, providing insights
                            and analytics that refine your content for maximum impact. Elevate your digital presence
                            with content that not only engages but converts.
                        </p>
                        <button class="btn bg-white px-5 rounded-0 border-0 shadow-lg fw-bold hover-effect"
                                (click)="openModal()">View Demo <i
                            class="ms-3 fa-solid fa-arrow-right"></i>
                        </button>
                        <ng-template #contactFormModal>
                            <app-contact-form [isModalOpen]="isModalOpen"
                                              (closeModal)="closeModal()"></app-contact-form>
                        </ng-template>
                        <div *ngIf="isModalOpen" class="modal-overlay" (click)="handleOverlayClick($event)">
                            <ng-container *ngTemplateOutlet="contactFormModal"></ng-container>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex justify-content-end py-5 d-flex justify-content-center">
                    <div
                        class=" md:mr-0 position-relative h-100 w-75 border-0  border-radius-10">
                        <img src="assets/img/landing-new/content-marketing/intro-content-marketing.png"
                             alt="Image"
                             class="img-fluid border-radius-10 w-100"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container-fluid">
    <div class="container py-5">
        <div class="row">
            <div class="col-md-6 m-0 p-0 text-right ani">
                <img src="assets/img/landing-new/content-marketing/about-content-marketing.png"
                     class="img-fluid border-radius-10"
                     alt="Image">
            </div>
            <div class="col-md-6 ps-md-5 py-md-5 py-4">
                <h2 class=" fs-1 fw-bolder"> Elevate Your Brand with
                    <span class="brand-primary-text-color"> Complete Content Marketing Excellence</span></h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">Elevate your brand to new heights with
                    Volmint Technologies' complete content marketing excellence. Our tailored approach focuses on
                    creating impactful, audience-driven content that resonates with your target market. From SEO
                    optimization to diverse content formats and in-depth performance analytics, we ensure your brand's
                    message reaches the right audience, driving engagement, trust, and conversions. Let us help you
                    build a powerful digital presence that sets you apart and fosters lasting connections with your
                    customers.</p>
            </div>
        </div>

        <div class="row mt-md-5">
            <div class="col-md-6 py-md-5">
                <h2 class=" fs-1 fw-bolder">Fuel Your Business Growth with
                    <span class="brand-primary-text-color">  Dynamic Content Strategies</span></h2>
                <p class="fs-6 text-secondary text-wrap justify-text">Fuel your business growth with Volmint
                    Technologies' dynamic content strategies that are designed to captivate, engage, and convert. We
                    craft tailored content that speaks directly to your audience, aligning with your brand’s voice and
                    objectives. From SEO-driven articles to interactive media and analytics-based refinements, our
                    approach ensures maximum reach and impact. Elevate your brand's presence with content that not only
                    informs but drives measurable growth and long-term success. Let us empower your business with
                    content that delivers results.
                </p>
                <div class="d-lg-flex justify-content-between w-90 flex-wrap d-none">
                    <div class="fw-bold my-1 px-5 py-3 brand-primary-bg text-white fixed-width text-center"
                         *ngFor="let item of powerfullToUse">
                        {{ item.text }}
                    </div>
                </div>
                <div class="col-12 d-block d-lg-none mt-4">
                    <p class="fs-6 text-secondary" *ngFor="let item of powerfullToUse"><img
                        src="assets/img/project/check.png"
                        alt="Image"
                        class="img-fluid"> {{ item.text }}
                    </p>
                </div>
            </div>
            <div class="col-md-6 pt-5">
                <img src="assets/img/landing-new/content-marketing/business-content-marketing.png"
                     class="img-fluid w-100 border-radius-10" alt="">
            </div>
        </div>
    </div>
</div>

<div class="container-fluid bg-purple">
    <div class="container">
        <div class="row py-5">
            <div class="col-md-6 py-5">
                <h2 class=" fs-1 fw-bolder">
                    Discover the game-changing features of <span class="brand-primary-text-color"> our content marketing services</span>
                </h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">Our content marketing services offer
                    game-changing features tailored to captivate your audience and elevate your brand. We deliver
                    custom-crafted, SEO-driven content across diverse formats, ensuring it aligns with your brand's
                    voice and resonates with your target audience. By focusing on audience needs and utilizing
                    data-driven insights, we optimize performance to drive real engagement, increase visibility, and
                    fuel measurable business growth. Let us transform your brand’s digital presence with content
                    strategies that deliver impactful results.
                </p>
                <div class="row g-3">
                    <div class="col-sm-4 col-6 px-2 my-2"
                         *ngFor="let item of features">
                        <div class="card bg-white text-center py-2 fixed-width-span border-0 shadow-lg p-2">
                               <span>
                            <img src="{{item.icon}}" alt="" class="img-fluid w-25">
                        </span>
                            <span class="ms-2 text-secondary pt-2">
                            {{ item.text }}
                        </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6  justify-content-md-end">
                <img src="assets/img/landing-new/content-marketing/discover-content-marketing.png" alt="Image"
                     class="img-fluid w-75 border-radius-10">
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="container">
        <div class="row gx-4">
            <div class="col-md-6 py-5">
                <h2 class=" fs-1 fw-bolder">
                    Targeted Content Creation<span class="brand-primary-text-color">  Tailored to Your Audience</span>
                </h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">
                    At Volmint Technologies, we specialize in targeted content creation that is meticulously crafted to
                    resonate with your audience. Our approach goes beyond generic messaging; we delve deep into
                    understanding your audience's preferences, pain points, and interests. By developing content that
                    speaks directly to their needs, we foster genuine connections and enhance engagement. Our team
                    ensures that every piece of content, whether it’s articles, videos, or social media posts, reflects
                    your brand’s unique voice while delivering value to your audience. Experience the power of content
                    that not only attracts but also retains and converts your target market.
                </p>
                <button class="btn text-white brand-primary-bg border px-5 rounded-0 my-5 hover-white fw-bold"
                        (click)="openModal()">Book Appointment
                    <i
                        class="ms-3 fa-solid fa-arrow-right"></i>
                </button>
            </div>
            <div class="col-md-6 pt-5 text-right">
                <img src="assets/img/landing-new/content-marketing/Targeted-Advertising.png"
                     class="ms-2 img-fluid border-radius-10" alt="Image"/>
            </div>

            <div class="col-md-6 pt-5 text-right">
                <img src="assets/img/landing-new/social-media-marketing/Targeted-Advertising.png"
                     class="w-100 border-radius-10" alt="">
            </div>
            <div class="col-md-6 py-5 ps-md-5">
                <h2 class="fs-1 fw-bolder">
                    <span
                        class="brand-primary-text-color">Comprehensive SEO Strategies for </span> Maximum Online
                    Visibility</h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">At Volmint Technologies, we implement
                    comprehensive SEO strategies designed to maximize your online visibility and drive targeted traffic
                    to your website. Our expert team conducts thorough keyword research, optimizes on-page elements, and
                    develops high-quality backlinks to enhance your search engine rankings. By staying abreast of the
                    latest SEO trends and algorithm updates, we ensure your content is not only discoverable but also
                    relevant to your audience's search intent. With our tailored approach, we focus on delivering
                    sustainable results that boost your brand's presence, attract qualified leads, and ultimately
                    contribute to your business growth.
                </p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="container">
        <div class="row gx-4">
            <div class="col-md-6 py-5">
                <h2 class=" fs-1 fw-bolder">
                     <span
                         class="brand-primary-text-color">  Varied Content Formats for </span>
                    Enhanced Engagement</h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">
                    At Volmint Technologies, we understand that engaging your audience requires a diverse range of
                    content formats. Our expertise spans various media, including compelling articles, captivating
                    videos, eye-catching infographics, and interactive social media posts. By leveraging these varied
                    content formats, we cater to different preferences and consumption habits, ensuring your message
                    resonates across all platforms. This multi-faceted approach not only enhances audience engagement
                    but also strengthens your brand's narrative, making it more relatable and impactful. Let us help you
                    create a dynamic content strategy that captivates and retains your audience’s attention.
                </p>
                <button class="btn text-white brand-primary-bg border px-5 rounded-0 my-5 hover-white fw-bold"
                        (click)="openModal()">Book Appointment
                    <i
                        class="ms-3 fa-solid fa-arrow-right"></i>
                </button>
            </div>
            <div class="col-md-6 pt-5 text-right">
                <img src="assets/img/landing-new/social-media-marketing/customer-engagment.jpg"
                     class="ms-2 img-fluid border-radius-10" alt="Image"/>
            </div>

            <div class="col-md-6 pt-5 text-right">
                <img src="assets/img/landing-new/social-media-marketing/valuable-insights.jpg"
                     class="w-100 border-radius-10" alt="">
            </div>
            <div class="col-md-6 py-5 ps-md-5">
                <h2 class="fs-1 fw-bolder">
                    <span
                        class="brand-primary-text-color">Insightful Performance Measurement and  </span> Analytics for
                    Data-Driven Decisions
                </h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">At Volmint Technologies, we believe that
                    informed decisions drive success. Our insightful performance measurement and analytics services
                    provide you with a comprehensive understanding of your content's effectiveness. By utilizing
                    advanced tracking tools and metrics, we analyze audience engagement, conversion rates, and overall
                    campaign performance. This data-driven approach allows us to identify what resonates with your
                    audience and refine strategies accordingly, ensuring optimal results. With our expertise, you can
                    make strategic decisions that enhance your content marketing efforts, maximize ROI, and propel your
                    business growth.
                </p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="container">
        <div class="row gx-4">
            <div class="col-md-6 py-5">
                <h2 class=" fs-1 fw-bolder">
                     <span
                         class="brand-primary-text-color">  The Crucial Role of Content Marketing </span>
                    in Driving Business Success </h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">Content marketing plays a crucial role in
                    driving business success by establishing a strong online presence and fostering meaningful
                    connections with your target audience. By delivering valuable, relevant content, businesses can
                    enhance brand awareness, build trust, and position themselves as industry leaders. Effective content
                    marketing not only attracts and engages potential customers but also nurtures existing
                    relationships, leading to increased loyalty and conversions. In today's digital landscape, a
                    well-executed content marketing strategy is essential for staying competitive and achieving
                    sustainable growth. By prioritizing content marketing, businesses can unlock new opportunities,
                    optimize customer engagement, and drive long-term success.
                </p>
                <button class="btn text-white brand-primary-bg border px-5 rounded-0 my-5 hover-white fw-bold"
                        (click)="openModal()">Book Appointment
                    <i
                        class="ms-3 fa-solid fa-arrow-right"></i>
                </button>
            </div>
            <div class="col-md-6 pt-5 text-right">
                <img src="assets/img/landing-new/social-media-marketing/business-social-media.jpg"
                     class="ms-2 img-fluid border-radius-10" alt="Image"/>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid brand-primary-bg mt-5">
    <div class="container py-5">
        <h1 class="text-white text-center mt-5 fw-bold justify-text">What Distinguishes Our Content Marketing
            Services? </h1>
        <p class="text-white text-center">What distinguishes our content marketing services at Volmint Technologies is
            our commitment to delivering tailored solutions that align with your unique business objectives. We combine
            creativity and strategy, leveraging in-depth market research and audience insights to craft compelling
            content that resonates. Our dedicated team of experts utilizes the latest tools and techniques to optimize
            every piece of content for maximum impact, ensuring it reaches the right audience at the right time.
            Additionally, our focus on measurable results allows us to continually refine and enhance your content
            strategy, driving sustained growth and engagement. With our personalized approach, we empower your brand to
            stand out in a competitive landscape and achieve lasting success.
        </p>
        <div class="row gy-5 py-5">
            <div class="col-md-4" *ngFor="let item of whyChoose; let i = index">
                <div class="h-w-40 text-center rounded-circle bg-white pt-2 fw-bold text-black ">{{ i + 1 }}</div>
                <h3 class="text-white my-4 fw-bold">{{ item.header }}</h3>
                <p class="text-white justify-text">{{
                        item.text
                    }}</p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid py-5">
    <div class="container">
        <div class="row py-5 gx-5">
            <div class="col-md-6 py-md-5">
                <h2 class=" fs-1 fw-bolder"> Explore Our All-Encompassing
                    <span class="brand-primary-text-color"> Content Marketing Services </span>
                </h2>
                <p class="my-5 fs-6 text-secondary text-wrap justify-text">Explore our all-encompassing content
                    marketing services at Volmint Technologies, where we offer a holistic approach to enhancing your
                    brand's digital presence. Our comprehensive suite of services includes tailored content creation,
                    strategic SEO optimization, and diverse content formats that engage your audience effectively. We
                    leverage data-driven insights to measure performance and refine strategies, ensuring that your
                    content not only resonates but also drives tangible results. With our expert team by your side, you
                    can unlock the full potential of content marketing to boost brand awareness, foster customer
                    loyalty, and achieve sustainable business growth. Let us partner with you to create impactful
                    content that elevates your brand and drives success.</p>
                <div class="row g-3 mt-5">
                    <div class="col-12 px-0 my-3 "
                         *ngFor="let item of offerings">
                        <span>
                            <img src="assets/img/project/check.png" alt="Image" class="img-fluid">
                        </span>
                        <span class="ms-2 fw-bold text-secondary">
                            {{ item.text }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-md-6 pt-5  justify-content-end">
                <img src="assets/img/landing-new/content-marketing/explore-content-marketing.png"
                     class="img-fluid border-radius-10"
                     alt="Image">
            </div>
        </div>

    </div>
</div>

<div class="container-fluid">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="col-12">
                    <img src="assets/img/landing-new/mobile-development/faq.png" alt="Image" class="img-fluid w-50"/>
                    <p class="fs-1 fw-bold">Frequently Asked Questions</p>
                    <p class="text-secondary">Have a question that is not answered? You can contact us at</p>
                    <p class="brand-primary-text-color fw-bold">Email: <a href="mailto:support@volmint.com"
                                                                          class="text-decoration-underline brand-primary-text-color">support@volmint.com</a>
                    </p>
                    <button type="submit"
                            class="brand-primary-bg border-0 color-white px-5 py-2 fs-6 mt-4 fw-bold hover-effect"
                            (click)="goTo('/faq')">
                        View All Faqs
                    </button>
                </div>
            </div>
            <div class="col-12 col-md-6 mt-4">
                <div class="accordion" id="faqAccordion">
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span class="fs-6 fw-bold" style="color: #03738C;">
                                    <div class="row">
                                        <div class="col-1">
                                          <div class="encircled-icon float-start">
                                                <i class="fas fa-question fw-bold"></i>
                                          </div>
                                        </div>
                                        <div class="col-11">
                                        What is content marketing, and how can it benefit my business?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Content marketing involves creating and distributing valuable, relevant content to
                                attract and engage your target audience. It can enhance brand awareness, build trust,
                                improve customer loyalty, and ultimately drive conversions and sales.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <span
                            class="fs-6 fw-bold" style="color: #03738C;">
                             <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon float-start">
                                                <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                 <div class="col-11">
                                   How do you determine the right content strategy for my business?
                                 </div>
                             </div>
                            </span>
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                We begin by conducting a thorough analysis of your business goals, target audience, and
                                industry trends. This research allows us to create a tailored content strategy that
                                aligns with your objectives and resonates with your audience.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <span class="fs-6 fw-bold" style="color: #03738C;">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon float-start">
                                                <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                        <div class="col-11">
                                           What types of content formats do you offer?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                We provide a diverse range of content formats, including blog posts, articles, videos,
                                infographics, social media posts, and more. This variety ensures we effectively engage
                                your audience across different platforms and preferences.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <span class="fs-6 fw-bold" style="color: #03738C;">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon">
                                                <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                        <div class="col-11">
                                          How do you measure the success of your content marketing efforts?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h2>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                We use a combination of analytics tools and key performance indicators (KPIs) to track
                                content performance. Metrics such as website traffic, engagement rates, conversion
                                rates, and ROI help us evaluate the effectiveness of our strategies and make data-driven
                                adjustments.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingFive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <span class="fs-6 fw-bold" style="color: #03738C;">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon">
                                        <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                        <div class="col-11">
                                            How long does it take to see results from content marketing?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h2>
                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Content marketing is a long-term strategy, and results may vary based on several
                                factors, including industry competitiveness and current market conditions. Generally,
                                you can expect to see noticeable improvements in brand visibility and engagement within
                                a few months, with more significant results developing over time.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid ums-bottom-bg ps-5 mt-5">
    <div class="container">
        <div class="row align-items-center py-5">
            <div class="col-md-10 col-12">
                <h1 class="text-white">Ready to Take Your Business Growth to the Next Level?</h1>
            </div>
            <div class="col-md-2 col-12">
                <button class="btn bg-white rounded-0 px-5 py-3 text-black fw-bold hover-effect"
                        (click)="goTo('/contact')">Contact Us
                </button>
            </div>
        </div>
    </div>
</div>
