import {Component, ElementRef, HostListener, OnInit} from '@angular/core';
import * as THREE from 'three';
@Component({
  selector: 'app-background-animation',
  templateUrl: './background-animation.component.html',
  styleUrls: ['./background-animation.component.scss']
})
export class BackgroundAnimationComponent implements OnInit {
    private scene: THREE.Scene;
    private camera: THREE.PerspectiveCamera;
    private renderer: THREE.WebGLRenderer;
    private points: THREE.Points;
    private mouseX = 0;
    private mouseY = 0;
    private windowHalfX = window.innerWidth / 2;
    private windowHalfY = window.innerHeight / 2;
  constructor(private elRef: ElementRef) { }

    ngOnInit() {
        this.initThreeJS();
        this.animate();
    }

    private initThreeJS() {
        const container = this.elRef.nativeElement.querySelector('#three-js-bg');

        // Set up scene, camera, and renderer
        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        this.camera.position.z = 250;

        this.renderer = new THREE.WebGLRenderer({ alpha: true });
        this.renderer.setSize(window.innerWidth, window.innerHeight);

        // Append the renderer to the container
        container.appendChild(this.renderer.domElement);

        // Geometry and material for particles
        const geometry = new THREE.BufferGeometry();
        const vertices = [];

        for (let i = 0; i < 500; i++) {
            const x = THREE.MathUtils.randFloatSpread(400); // Spread particles widely
            const y = THREE.MathUtils.randFloatSpread(400);
            const z = THREE.MathUtils.randFloatSpread(400);
            vertices.push(x, y, z);
        }

        geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));

        const material = new THREE.PointsMaterial({ color: 0xffffff, size: 1.2 });
        this.points = new THREE.Points(geometry, material);
        this.scene.add(this.points);
    }

    private animate() {
        requestAnimationFrame(() => this.animate());

        // Make particles responsive to the cursor
        this.points.rotation.x += 0.001;
        this.points.rotation.y += 0.001;

        // Apply the mouse movement to rotation
        this.points.rotation.x += (this.mouseY - this.points.rotation.x) * 0.05;
        this.points.rotation.y += (this.mouseX - this.points.rotation.y) * 0.05;

        this.renderer.render(this.scene, this.camera);
    }

    @HostListener('window:resize', [])
    onWindowResize() {
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();
    }

    @HostListener('mousemove', ['$event'])
    onMouseMove(event: MouseEvent) {
        // Calculate relative mouse position to window center
        this.mouseX = (event.clientX - this.windowHalfX) * 0.001;
        this.mouseY = (event.clientY - this.windowHalfY) * 0.001;
    }

}
