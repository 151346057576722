<div class="page-title-area item-bg1">
    <div class="container">
        <app-background-animation></app-background-animation>
        <div class="page-title-content">
            <p class="fs-5 color-white text-center glowing-heading">FAQ</p>
            <p class="fs-1 color-white text-center fw-bold glowing-sub-heading">Got Questions? We've Got Answers!</p>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>

<div class="container-fluid mt-5">
    <div class="container">
        <div class="row button-row mb-4">
            <div *ngFor="let button of buttons" class="col-md-2 col-12 bg-color-blue p-2 button-container hover-effect"
                 style="cursor: pointer"
                 (click)="changeFaq(button.key)">
                <div class="row flex-grow-1">
                    <div class="col-9 text-start">
                        <p class="fs-5 fw-bold color-white">{{ button.label }}</p>
                    </div>
                    <div class="col-3 d-flex justify-content-end">
                        <div class="icon-container">
                            <i class="fa fa-chevron-right fs-5 fw-bold color-white" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="accordion" id="faqAccordion">
                <div *ngFor="let item of currentFaq; let i = index" class="accordion-item shadow-lg border-0">
                    <h2 class="accordion-header" [id]="'heading' + i">
                        <button class="accordion-button" type="button" [attr.data-bs-toggle]="'collapse'"
                                [attr.data-bs-target]="'#collapse' + i"
                                [attr.aria-expanded]="i === 0" [attr.aria-controls]="'collapse' + i">
                        <span class="fs-6 fw-bold" style="color: #5236FF;">
                            <div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div>
                           &nbsp; {{ item.question }}
                        </span>
                        </button>
                    </h2>
                    <div [id]="'collapse' + i" class="accordion-collapse collapse"
                         [attr.aria-labelledby]="'heading' + i" data-bs-parent="#faqAccordion">
                        <div class="accordion-body fs-6 text-secondary justify-text" [innerHTML]="item.answer">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5 bg-light-pink">
    <div class="container p-4">
        <p class="fs-1 fw-bold text-center">Get in Touch</p>
        <div *ngIf="successMessage" class="alert alert-success my-2 fw-bold">
            {{ successMessage }}
        </div>
        <div class="row p-5">
            <form [formGroup]="contactForm">
                <div class="row mb-3">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control bg-white py-4 rounded-3 fs-6" id="name"
                                   formControlName="name"
                                   placeholder="Enter Your Name">
                            <small *ngIf="submitted && contactForm.get('name').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="email" class="form-control bg-white py-4 rounded-3 fs-6" id="email"
                                   formControlName="email"
                                   placeholder="Your Email">
                            <small *ngIf="submitted && contactForm.get('email').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="number" class="form-control bg-white py-4 rounded-3 fs-6" id="contact"
                                   formControlName="contact"
                                   placeholder="Enter Your Phone Number">
                            <small *ngIf="submitted && contactForm.get('contact').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control bg-white py-4 rounded-3 fs-6" id="subject"
                                   formControlName="subject"
                                   placeholder="Subject">
                            <small *ngIf="submitted && contactForm.get('subject').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-12">
                        <div class="form-group">
                            <textarea class="form-control bg-white py-4 rounded-3 fs-6" id="message" rows="8"
                                      style="resize: none;"
                                      formControlName="message"
                                      placeholder="Your Message"></textarea>
                            <small *ngIf="submitted && contactForm.get('message').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <button class="bg-color-blue px-5 py-3 border-0 fs-5 fw-bold color-white fw-bold hover-effect"
                            (click)="submit()">Ask <i
                        class="fa fa-arrow-right"
                        aria-hidden="true"></i>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="container-fluid mt-5 py-5"
     style="background-image: url('/assets/img/landing-new/footer/footer-bg.png');">
    <div class="container">
        <div class="row text-center text-md-start">
            <div class="col-md-6 col-12 fs-1 color-white fw-bold mb-3 mb-md-0">
                Ready to take your Business’s IT to the next level?
            </div>
            <div class="col-md-6 col-12 text-md-end">
                <button class="bg-F5FCFF px-5 py-3 border-0 fs-6 fw-bold hover-effect" (click)="goTo('/contact')">
                    Contact Us <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    </div>
</div>

