import {Component, ElementRef, OnInit, TemplateRef, ViewChild, AfterViewInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {ContactUsService} from "../../../shared/services/contact-us.service";
import {AlertService} from "../../../shared/services/alert.service";
import {Meta, Title} from "@angular/platform-browser";

@Component({
    selector: 'app-social-media-marketing',
    templateUrl: './social-media-marketing.component.html',
    styleUrls: ['./social-media-marketing.component.scss']
})
export class SocialMediaMarketingComponent implements OnInit, AfterViewInit {

    @ViewChild('contactFormModal') contactFormModal!: TemplateRef<any>;
    @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
    isModalOpen = false;
    contactForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    successMessage: string;
    powerfullToUse = [

        {text: 'Enhanced Brand Awareness'},
        {text: 'Targeted Advertising'},
        {text: 'Improved Customer Engagement'},
        {text: 'Valuable Insights and Analytics'},
        {text: 'Comprehensive Strategy Development'},
        {text: 'Continuous Optimization'},
    ];
    modules = [
        {text: 'Admissions Management'},
        {text: 'Attendance Management'},
        {text: 'Fees Management'},
        {text: 'Exams Management'},
        {text: 'Library Management'},
        {text: 'Payroll Management'},
        {text: 'Organized Department'},
        {text: 'Record Management'},
    ];
    features = [
        {icon: 'assets/img/landing-new/argus/monitoring.png', text: 'Content Creation and Curation'},
        {icon: 'assets/img/landing-new/argus/analytics.png', text: 'Audience Targeting'},
        {icon: 'assets/img/landing-new/argus/custom.png', text: 'Ad Campaign Management'},
        {icon: 'assets/img/landing-new/argus/user.png', text: 'Social Listening'},
        {icon: 'assets/img/landing-new/argus/multi.png', text: 'Performance Analytics'},
        {icon: 'assets/img/landing-new/argus/hybrid.png', text: 'Community Management'},
        {icon: 'assets/img/landing-new/argus/automated.png', text: 'Influencer Partnerships'},
        {icon: 'assets/img/landing-new/argus/scalability.png', text: 'Cross-Platform Integration'},
        {icon: 'assets/img/services/ongoing_support.jpeg', text: 'Reputation Management'}

    ];
    whyChoose = [
        {
            header: 'Customized Strategies',
            text: 'We develop tailored social media marketing plans that align with your specific business objectives, ensuring a personalized approach that resonates with your target audience.'
        },
        {
            header: 'Data-Driven Decision Making',
            text: 'Our team utilizes advanced analytics and performance tracking tools to monitor campaign effectiveness in real time, allowing for informed adjustments and optimizations that enhance results.'
        },
        {
            header: 'Creative Content Development',
            text: 'We prioritize high-quality, engaging content creation that captures attention and encourages interaction, utilizing various formats such as videos, graphics, and informative posts to boost engagement.'
        },
        {
            header: 'Expertise and Experience',
            text: 'Our skilled professionals bring years of industry experience and expertise, staying updated on the latest trends and best practices in social media marketing to deliver effective solutions.'
        },
        {
            header: 'Transparent Communication',
            text: 'We believe in open and honest communication, providing regular updates and insights on campaign performance, fostering trust and collaboration throughout our partnership.'
        },
        {
            header: 'Focus on Customer Engagement',
            text: 'Our strategies prioritize building strong relationships with your audience, enhancing customer loyalty and advocacy through interactive content, timely responses, and community-building efforts.'
        },
    ];
    offerings = [
        {
            text: 'Strategic Planning'
        },
        {
            text: 'Content Creation'
        },
        {
            text: 'Targeted Advertising'
        },
        {
            text: 'Community Management'
        },
        {
            text: 'Performance Tracking'
        },
    ];

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private contactService: ContactUsService,
        private alertService: AlertService,
        private title : Title,
        private meta : Meta
    ) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    ngAfterViewInit() {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // Add the "visible" class when the element enters the viewport
                    entry.target.classList.add('visible');
                } else {
                    // Remove the "visible" class when the element leaves the viewport
                    entry.target.classList.remove('visible');
                }
            });
        });
        // Select all elements with the class "animate-on-scroll"
        const elements = document.querySelectorAll('.animate-on-scroll, .animate-on-scroll-right');

        // Observe each element
        elements.forEach(element => {
            observer.observe(element);
        });
    }

    refresh() {
        this.contactForm = this.fb.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            contact: [''],
            subject: ['', Validators.required],
            message: ['', Validators.required]
        });
    }

    openModal() {
        this.isModalOpen = true;
    }

    closeModal() {
        this.isModalOpen = false;
    }

    handleOverlayClick(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (!target.closest('.modal-dialog')) {
            this.closeModal();
        }
    }

    submit() {
        this.submitted = true;
        if (!this.contactForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().name) ? this.contactForm.getRawValue().name : '',
                'email': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().email) ? this.contactForm.getRawValue().email : '',
                'contact': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().contact) ? this.contactForm.getRawValue().contact : '',
                'subject': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().subject) ? this.contactForm.getRawValue().subject : '',
                'message': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().message) ? this.contactForm.getRawValue().message : '',
            };
            this.contactService.create(params).subscribe(data => {
                this.successMessage = 'Your form has been submitted successfully!';
                this.submitted = false;
                this.contactForm.patchValue({
                    'name': '',
                    'email': '',
                    'contact': '',
                    'subject': '',
                    'message': ''
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                    if (error['error']['errors'].subject && error['error']['errors'].subject.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].subject[0]);
                        return;
                    }
                    if (error['error']['errors'].message && error['error']['errors'].message.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].message[0]);
                        return;
                    }
                }
            });
        }
    }

    playVideo() {
        const video = this.videoElement.nativeElement;
        const thumbnail = document.getElementById('video-thumbnail');
        if (video) {
            video.classList.remove('d-none');
            video.play();
            if (thumbnail) {
                thumbnail.style.display = 'none';
            }
            const playButton = document.querySelector('.play-button');
            if (playButton) {
                playButton.classList.add('d-none');
            }
        }
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }
    updateMetaTags() {
        this.title.setTitle('Volmint - Leading Social Media Marketing Company in India');
        this.meta.updateTag({name: 'description', content: 'Reach to the wider audience with the best Social Media Marketing Services in India. Drive engagement, grow your business, and increase conversions effectively.'});
        this.meta.updateTag({name: 'keyword', content: 'angular, seo, meta tags'});
    }

}
