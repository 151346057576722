<section class="banner">
    <div class="container-fluid bg-sea-green mt-5">
        <div class="container mt-5 pt-5">
            <div class="row g-5">
                <div class="col-md-6 d-flex text-center text-md-start align-items-center">
                    <div>
                        <h1 class="fw-bolder font-56"><span class="brand-primary-text-color">Argus-</span>
                            Bring Revolution in Exam Proctoring</h1>
                        <p class="my-4 fs-6 text-secondary text-wrap f-20 justify-text">Elevate your assessment
                            experience with
                            Argus, the comprehensive exam management software designed to ensure academic integrity,
                            streamline workflows, and simplify exam delivery – both online and in-person. Our
                            user-friendly platform empowers educators to create, administer, and monitor exams with
                            confidence, fostering a secure and stress-free testing environment for students. The AI
                            assistance analyzes behavior and patterns, while live proctors provide human oversight.
                        </p>
                        <button class="btn bg-white px-5 rounded-0 border-0 shadow-lg fw-bold hover-effect"
                                (click)="openModal()">View Demo <i
                            class="ms-3 fa-solid fa-arrow-right"></i>
                        </button>
                        <ng-template #contactFormModal>
                            <app-contact-form [isModalOpen]="isModalOpen"
                                              (closeModal)="closeModal()"></app-contact-form>
                        </ng-template>
                        <div *ngIf="isModalOpen" class="modal-overlay" (click)="handleOverlayClick($event)">
                            <ng-container *ngTemplateOutlet="contactFormModal"></ng-container>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex justify-content-end py-5 d-flex justify-content-center">
                    <div
                        class="card banner-bg-color md:mr-0 py-5 position-relative h-100 w-100 border-0 shadow-lg d-flex justify-content-center">
                        <img src="assets/img/landing-new/argus/argus1.png" alt="Image" class="img-fluid"/>
                    </div>
                    <!--        <div class="position-relative">
                                <img src="assets/img/landing-new/thumbnail/college%20Group%20management%20system%20banner.jpg"
                                     class="img-fluid rounded-4" alt="Banner Image" id="video-thumbnail">
                                <button class="play-button position-absolute" (click)="playVideo()"></button>
                                <video #videoElement class="d-none rounded-4" controls>
                                    <source src="assets/img/landing-new/cgms/cgms.webm" type="video/webm">
                                    Your browser does not support the video tag.
                                </video>
                            </div>-->
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container-fluid">
    <div class="container py-5">
        <div class="row">
            <div class="col-md-6 pt-5 text-right">
                <img src="assets/img/landing-new/argus/argus2.png" class="img-fluid" alt="Image">
            </div>
            <div class="col-md-6 ps-md-5 py-md-5 py-4">
                <h2 class=" fs-1 fw-bolder">About Volmint’s
                    <span class="brand-primary-text-color"> Exam Proctoring System</span></h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">Volmint Technologies is introducing
                    Argus, an
                    innovative exam proctoring system that enhances digital integrity and security in educational
                    assessments. Utilizing advanced AI and machine learning technologies, Argus provides a comprehensive
                    suite of tools for monitoring, evaluating, and securing the online examination process.</p>
            </div>
        </div>

        <div class="row mt-md-5">
            <div class="col-md-6 py-md-5">
                <h2 class=" fs-1 fw-bolder">Optimize Campus Efficiency With Our Robust Exam Proctoring Software
                    <span class="brand-primary-text-color">Argus</span></h2>
                <p class="fs-6 text-secondary text-wrap justify-text">Elevate the integrity and efficiency of
                    your academic
                    examinations with Argus, our advanced exam proctoring software. Designed specifically for
                    educational institutions, it ensures a seamless and secure testing environment, enabling you to
                    focus on delivering high-quality education.
                </p>
<!--                <div class="d-lg-flex justify-content-between w-90 flex-wrap d-none">-->
<!--                    <div class="fw-bold my-1 px-5 py-3 brand-primary-bg text-white fixed-width text-center"-->
<!--                         *ngFor="let item of powerfullToUse">-->
<!--                        {{ item.text }}-->
<!--                    </div>-->
<!--                </div>-->


                <div class="d-lg-flex justify-content-between w-90 flex-wrap d-none mt-4">
                    <div
                        class="my-2  py-3 brand-primary-bg text-white fw-bold text-center powerful-item"
                        *ngFor="let item of powerfullToUse">
                        {{ item.text }}
                    </div>
                </div>

                <div class="col-12 d-block d-lg-none mt-4">
                    <p class="fs-6 text-secondary" *ngFor="let item of powerfullToUse"><img
                        src="assets/img/project/check.png"
                        alt="Image"
                        class="img-fluid"> {{ item.text }}
                    </p>
                </div>
            </div>
            <div class="col-md-6 pt-5 d-flex justify-content-center">
                <img src="assets/img/landing-new/argus/argus3.png" class="img-fluid" alt="">
            </div>
        </div>
    </div>
</div>
<div class="container-fluid bg-purple">
    <div class="container">
        <div class="row py-5">
            <div class="col-md-6 py-5">
                <h2 class=" fs-1 fw-bolder">
                    <span class="brand-primary-text-color">Key Features of</span> Exam Proctoring System</h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">Experience unparalleled efficiency
                    and security in
                    your exam processes with Argus. Our innovative features ensure a seamless and trustworthy testing
                    environment, tailored to meet all your examination needs. From real-time monitoring to detailed
                    analytics, Argus provides the tools you need to maintain academic standards and streamline campus
                    operations and exam supervision.
                </p>
                <div class="row g-3">
                    <div class="col-sm-4 col-6 px-2 my-2"
                         *ngFor="let item of features">
                        <div class="card bg-white text-center py-2 fixed-width-span border-0 shadow-lg p-2">
                               <span>
                            <img src="{{item.icon}}" alt="" class="img-fluid w-25">
                        </span>
                            <span class="ms-2 text-secondary pt-2">
                            {{ item.text }}
                        </span>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
                <img src="assets/img/landing-new/argus/argus4.webp" alt="Image" class="img-fluid">
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="container">
        <div class="row gx-4">
            <div class="col-md-6 py-5">
                <h2 class=" fs-1 fw-bolder">
                    Manage <span class="brand-primary-text-color">Multiple Exams </span> From Single Dashboard</h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">Argus ensures the highest level of
                    exam integrity
                    with precise proctoring tools. Monitor every exam session closely to maintain a fair testing
                    environment, all on One Integrated Platform.
                </p>
                <button class="btn text-white brand-primary-bg border px-5 rounded-0 my-5 hover-white fw-bold"
                        (click)="openModal()">Book Appointment
                    <i
                        class="ms-3 fa-solid fa-arrow-right"></i>
                </button>
            </div>
            <div class="col-md-6 pt-5 text-right">
                <img src="assets/img/landing-new/argus/argus5.png" class="ms-2 img-fluid" alt="Image"/>
            </div>
            <div class="col-md-6 pt-5 text-right">
                <img src="assets/img/landing-new/argus/argus6.png" class="ms-2 mt-2" alt="">
            </div>
            <div class="col-md-6 py-5 ps-md-5">
                <h2 class="fs-1 fw-bolder">
                    Why Do You Need <span class="brand-primary-text-color">Argus Software </span></h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text"> The exam proctoring software offers
                    features to
                    enhance academic integrity, simplify exam management, automate tasks, reduce administrative burden,
                    and provide a dedicated support team. It features real-time intervention and AI monitoring to deter
                    cheating, protect content, and verify identities. The platform also simplifies workflow management,
                    and schedules, and provides instant auto-graded results.
                </p>
            </div>
        </div>

    </div>
</div>
<div class="container-fluid brand-primary-bg mt-5">
    <div class="container py-5">
        <h1 class="text-white text-center mt-5 fw-bold justify-text">What Makes Our Exam Proctoring System Stand
            Out</h1>
        <p class="text-white text-center">Our solution is crafted to provide unparalleled security, efficiency,
            and user experience, ensuring that every exam is conducted with the highest level of integrity and
            convenience.
        </p>
        <div class="row gy-5 py-5">
            <div class="col-md-4" *ngFor="let item of whyChoose; let i = index">
                <div class="h-w-40 text-center rounded-circle bg-white pt-2 fw-bold text-black ">{{ i + 1 }}</div>
                <h3 class="text-white my-4 fw-bold">{{ item.header }}</h3>
                <p class="text-white justify-text">{{
                        item.text
                    }}</p>
            </div>
        </div>
        <div class="text-center">
            <button class="btn bg-white px-5 brand-primary-text-color rounded-0 border-0 shadow-lg fw-bold hover-effect"
                    [routerLink]="'/contact'">Contact Us</button>
        </div>

    </div>
</div>

<div class="container-fluid py-5">
    <div class="container">
        <div class="row py-5 gx-5">
            <div class="col-md-7 py-md-5">
                <h2 class=" fs-1 fw-bolder">Explore Our Comprehensive
                    <span class="brand-primary-text-color">Argus Modules </span>
                </h2>
                <p class="my-5 fs-6 text-secondary text-wrap justify-text">Volmint Exam Proctoring System offers
                    prominent
                    modules, designed precisely for the needs and requirements of the institute, students, and
                    faculty.</p>

                <div class="row  ">
                    <div class="col-sm-6 col-12 px-0 px" *ngFor="let item of offerings">
                        <div class="offer-item d-flex align-items-center p-4 rounded shadow-lg">
      <span class="offer-icon">

          <i class="fa-solid fa-check"></i>
<!--        <img src="assets/img/project/check.png" alt="Icon" class="img-fluid">-->
      </span>
                            <span class="ms-4 offer-text fs-6">
        {{ item.text }}
      </span>
                        </div>
                    </div>
                </div>


<!--                <div class="row g-3 mt-5">-->
<!--                    <div class="col-12 px-0 my-3 "-->
<!--                         *ngFor="let item of offerings">-->
<!--                        <span>-->
<!--                            <img src="assets/img/project/check.png" alt="Image" class="img-fluid">-->
<!--                        </span>-->
<!--                        <span class="ms-2 fw-bold text-secondary">-->
<!--                            {{ item.text }}-->
<!--                        </span>-->
<!--                    </div>-->
<!--                </div>-->

            </div>
            <div class="col-md-5 pt-5 d-flex justify-content-end">
                <img src="assets/img/project/ums-5.png" class="img-fluid" alt="Image">
            </div>
        </div>
        <!--  <div class="row py-5 gx-5">
              <div class="col-md-6 py-md-5">
                  <h2 class=" fs-1 fw-bolder">
                      What Our Customers Says?
                  </h2>
                  <div class="card rounded-4 g-3 mt-5 p-5">
                      <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                          <div class="carousel-inner">
                              <div class="carousel-item active mb-5">
                                  <div class="d-flex border-bottom pb-4 justify-content-between align-items-center">
                                      <div class="d-flex w-50 ">
                                          <img src="assets/img/landing-new/reviews/cgms2.png" alt="Image"
                                               class="rounded-circle w-25">
                                          <span class="ms-4 mt-2">
                                               <h4>Kunal Sharma</h4>
                                               <span>New Delhi</span>
                                          </span>
                                      </div>
                                      <div>
                                          <img src="assets/img/project/comma.png" alt="Image">
                                      </div>
                                  </div>
                                  <p class="text-secondary text-wrap fw-bold justify-text">Argus has been a game-changer
                                      for our
                                      institution. The automated scheduling and real-time monitoring features have
                                      significantly reduced our administrative workload. it's a robust and reliable tool
                                      that has greatly enhanced our exam management system.
                                  </p>
                              </div>
                              <div class="carousel-item mb-5">
                                  <div class="d-flex border-bottom pb-4 justify-content-between align-items-center">
                                      <div class="d-flex w-50 ">
                                          <img src="assets/img/landing-new/reviews/cgms1.avif" alt="Image"
                                               class="rounded-circle w-25">
                                          <span class="ms-4 mt-2">
                                               <h4>Shreya Rana</h4>
                                               <span>Gurugram</span>
                                          </span>
                                      </div>
                                      <div>
                                          <img src="assets/img/project/comma.png" alt="Image">
                                      </div>
                                  </div>
                                  <p class="text-secondary text-wrap fw-bold justify-text">Setting up exams is now a
                                      breeze, and the
                                      integrity reports give us peace of mind knowing our exams are secure. The
                                      user-friendly interface is a big plus, making it easy for both staff and students to
                                      navigate. Highly recommended for any educational institution looking to streamline
                                      its exam processes!
                                  </p>
                              </div>
                              <div class="carousel-item mb-5">
                                  <div class="d-flex border-bottom pb-4 justify-content-between align-items-center">
                                      <div class="d-flex w-50 ">
                                          <img src="assets/img/landing-new/reviews/cgms3.jpeg" alt="Image"
                                               class="rounded-circle w-25">
                                          <span class="ms-4 mt-2">
                                               <h4>Siddharth Sharma</h4>
                                               <span>Noida</span>
                                          </span>
                                      </div>
                                      <div>
                                          <img src="assets/img/project/comma.png" alt="Image">
                                      </div>
                                  </div>
                                  <p class="text-secondary text-wrap fw-bold justify-text">We have been working with
                                      Volmint
                                      Technologies since last year and have tried their software to manage our educational
                                      institute tasks. We must say they are very innovative and helpful! We have navigated
                                      and streamlined our online exam proctoring with Argus and find it incredibly unique,
                                      accurate and supportive.
                                  </p>
                              </div>
                          </div>
                      </div>
                      <a class="carousel-control-prev bg-purple rounded-circle h-w-40 bottom-0"
                         href="#carouselExampleIndicators" role="button"
                         data-bs-slide="prev">
                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                          <span class="visually-hidden">Previous</span>
                      </a>
                      <a class="carousel-control-next bg-purple  rounded-circle h-w-40" href="#carouselExampleIndicators"
                         role="button"
                         data-bs-slide="next">
                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                          <span class="visually-hidden text-black">Next</span>
                      </a>
                  </div>
                  <div class="card brand-primary-bg py-5 mt-4">
                      <p class="fs-1 fw-bold text-center color-white pt-4">Any Questions?</p>
                      <div class="d-flex justify-content-center py-5">
                          <button class="btn bg-white rounded-0 py-3 fs-5 text-black w-50 rounded-4"
                                  (click)="goTo('/contact')">Contact Us
                          </button>
                      </div>
                  </div>
              </div>
              <div class="col-md-6 pt-5">
                  <div class=" w-100 ps-md-5">
                      <h2 class=" fs-1 fw-bolder">
                          Book Appointment Now!
                      </h2>
                      <div *ngIf="successMessage" class="alert alert-success my-2 fw-bold">
                          {{ successMessage }}
                      </div>
                      <form [formGroup]="contactForm" action="" class="mt-5 w-100 rounded-4 px-3 py-4 p-md-5"
                            style="background: #F4F4F4">
                          <div class="form-floating mb-5 rounded-3 outline-0 shadow border-0">
                              <input type="name" class="form-control" id="name" formControlName="name"
                                     placeholder="Enter Your Name">
                              <label for="name">Name</label>
                              <small *ngIf="submitted && contactForm.get('name').hasError('required')"
                                     class="text-enrolldanger">
                                  Field is required.
                              </small>
                          </div>
                          <div class="form-floating mb-5 rounded-3 outline-0 shadow border-0">
                              <input type="email" class="form-control" id="email" formControlName="email"
                                     placeholder="name@example.com">
                              <label for="email">Email</label>
                              <small *ngIf="submitted && contactForm.get('email').hasError('required')"
                                     class="text-danger">
                                  Field is required.
                              </small>
                          </div>
                          <div class="form-floating mb-5 rounded-3 outline-0 shadow border-0">
                              <input type="number" class="form-control" id="contact" formControlName="contact"
                                     placeholder="Enter Your Phone Number">
                              <label for="contact">Phone Number</label>
                              <small *ngIf="submitted && contactForm.get('contact').hasError('required')"
                                     class="text-danger">
                                  Field is required.
                              </small>
                          </div>
                          <div class="form-floating mb-5 rounded-3 outline-0 shadow border-0">
                              <input type="text" class="form-control" id="subject" formControlName="subject"
                                     placeholder="Subject">
                              <label for="subject">Subject</label>
                              <small *ngIf="submitted && contactForm.get('subject').hasError('required')"
                                     class="text-danger">
                                  Field is required.
                              </small>
                          </div>
                          <div class="form-floating mb-3 rounded-3 outline-0 shadow border-0">
                              <textarea class="form-control" placeholder="Leave a message here" id="message"
                                        style="resize: none;"
                                        formControlName="message"></textarea>
                              <label for="message">Message</label>
                              <small *ngIf="submitted && contactForm.get('message').hasError('required')"
                                     class="text-danger">
                                  Field is required.
                              </small>
                          </div>

                          <button class="btn rounded-pill brand-primary-bg text-white mt-5 w-100 hover-white"
                                  (click)="submit()">Submit
                          </button>
                      </form>
                  </div>
              </div>
          </div>-->
    </div>
</div>

<div class="container-fluid">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="col-12">
                    <img src="assets/img/landing-new/contact-us/faq.png" alt="Image" class="img-fluid"/>
                    <p class="fs-1 fw-bold">Frequently Asked Questions</p>
                    <p class="text-secondary">Have a question that is not answered? You can contact us at</p>
                    <p class="brand-primary-text-color fw-bold">Email: <a href="mailto:support@volmint.com"
                                                                          class="text-decoration-underline brand-primary-text-color">support@volmint.com</a>
                    </p>
                    <button type="submit"
                            class="brand-primary-bg border-0 color-white px-5 py-2 fs-6 mt-4 fw-bold hover-effect"
                            (click)="goTo('/faq')">
                        View All Faqs
                    </button>
                </div>
            </div>
            <div class="col-12 col-md-6 mt-4">
                <div class="accordion" id="faqAccordion">
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span class="fs-6 fw-bold" style="color: #03738C;">  <div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp;What is Volmint’s Argus?</span>
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Argus is an innovative exam proctor software, designed to streamline every aspect of the
                                examination process, from scheduling and proctoring to grading and reporting. With
                                features like AI monitoring, real-time interaction, and comprehensive analytics, we
                                ensure a secure and efficient exam experience.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <span
                            class="fs-6 fw-bold" style="color: #03738C;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp;Why Argus is important for educational institutes?</span>
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Argus is essential for maintaining academic integrity and optimizing productivity,
                                making it a must-have for educational institutions.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <span class="fs-6 fw-bold" style="color: #03738C;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; Why Choose the Argus system to integrate with your organization?</span>
                            </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                We pride ourselves on delivering top-notch service with user-friendly interfaces and
                                24/7 support, ensuring that your exam management is both stress-free and effective.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <span class="fs-6 fw-bold" style="color: #03738C;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; Is the software customizable?</span>
                            </button>
                        </h2>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Yes, one can customize the software to fit their needs and requirements. Also, the admin
                                can assign an invigilator, check results, and monitor student behavior through an AI
                                mechanism.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingFive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <span class="fs-6 fw-bold" style="color: #03738C;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; What are the three key benefits of Exam Proctor?</span>
                            </button>
                        </h2>
                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                <ul>
                                    <li>AI-Powered Monitoring</li>
                                    <li>Comprehensive Reporting and Analytics</li>
                                    <li>Customizable Exam Creation</li>
                                    <li>User-Friendly Interface</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid ums-bottom-bg ps-5 mt-5">
    <div class="container">
        <div class="row align-items-center py-5">
            <div class="col-md-6 col-12">
                <h1 class="text-white">Ready to elevate your university management to next level?</h1>
            </div>
            <div class="col-md-6 col-12 text-center">
                <span class="fs-4 fw-bold  text-white">Fill out the form, and we’ll be in touch soon!
                </span>
                <div class=" shadow py-5 px-4 mt-2" style="background: #dff9fe">
                    <form [formGroup]="contactForm" class="">
                        <div class="mb-4 d-sm-flex g-3 mx">
                            <input type="name" class="form-control bg-white rounded-3 text-secondary me-sm-1 py-3" id="name"
                                   formControlName="name"
                                   placeholder="Enter Your Name" required>
                            <small *ngIf="submitted && contactForm.get('name').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                            <input type="email" class="form-control bg-white rounded-3 text-secondary py-3"
                                   id="email" formControlName="email"
                                   placeholder="Enter Your Email" required>
                            <small *ngIf="submitted && contactForm.get('email').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                        <div class="mb-4">

                        </div>
                        <div class="mb-4">
                            <input type="tel" class="form-control bg-white rounded-3 text-secondary py-3"
                                   id="contact" formControlName="contact"
                                   placeholder="Enter Your Phone No." required>
                            <small *ngIf="submitted && contactForm.get('contact').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                        <div class="mb-4">
                                <textarea class="form-control rounded-3 bg-white text-secondary" id="message"
                                          formControlName="message"
                                          rows="4" style="resize: none;"
                                          placeholder="Your Message" required></textarea>
                            <small *ngIf="submitted && contactForm.get('message').hasError('required')"
                                   class="text-danger">
                                Field is required.
                            </small>
                        </div>
                        <div class="text-center mt-4">
                            <button type="submit"
                                    class="brand-primary-bg border-0  color-white px-5 py-2 w-50 fs-6 fw-bold hover-effect"
                                    (click)="submit()">
                                Submit
                            </button>
                        </div>                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
