<div class="page-title-area item-bg1">
    <app-background-animation></app-background-animation>
    <div class="container">
        <div class="page-title-content">
            <p class="fs-5 color-white text-center glowing-heading">Careers</p>
            <p class="fs-1 color-white text-center fw-bold glowing-sub-heading">Grab The Great Opportunities Today </p>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>

<div class="container-fluid mt-5 bg-light-pink py-5">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                <img src="assets/img/landing-new/careers/career-one.jpg" alt="Image" class="img-fluid"/>
            </div>
            <div class="col-12 text-center pt-4 mb-5">
                <p class="fs-1 fw-bold text-center">Your dream career starts here—let’s build it together!</p>
                <div class="row align-item-center">
                    <div class="col-1"></div>
                    <div class="col-10">
                        <p class="text-secondary text-center text-wrap justify-text">Ready to ignite your
                            career? At Volmint Technologies, we offer more than jobs—we open doors to endless
                            possibilities. Join us to grow, innovate, and make an impact. Explore our openings and be
                            part of redefining the future.
                        </p>
                    </div>
                    <div class="col-1"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="values">
    <div class="container-fluid mt-5">
        <div class="container">
            <p class="fs-1 fw-bold text-center">Values That Defines Us</p>
            <div class="row g-4">
                <div class="col-md-4 col-12">
                    <div class="card p-2 border-0 shadow-lg rounded-4">
                        <img src="assets/img/landing-new/careers/career-two.png" class="card-img-top ps-2" alt="Image">
                        <div class="card-body">
                            <h5 class="card-title fw-bold">Impactful Work Culture</h5>
                            <p class="card-text text-secondary justify-text">Elevate your career with us and be
                                part of a team making a real impact.
                                Join us to create transformative solutions, and shape a tech-driven world that matters.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 col-12">
                    <div class="card p-2 border-0 shadow-lg rounded-4">
                        <img src="assets/img/landing-new/careers/career-three.png" class="card-img-top ps-2"
                             alt="Image">
                        <div class="card-body">
                            <h5 class="card-title fw-bold">Professional Development</h5>
                            <p class="card-text text-secondary justify-text">Fuel your career growth with
                                tailored professional development
                                opportunities. Unlock your potential and chart a path to success with our dedicated
                                support.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 col-12">
                    <div class="card border-0 shadow-lg p-2 rounded-4">
                        <img src="assets/img/landing-new/careers/career-four.png" class="card-img-top ps-2" alt="Image">
                        <div class="card-body">
                            <h5 class="card-title fw-bold">Growth Opportunities</h5>
                            <p class="card-text text-secondary justify-text">Endless possibilities for personal
                                and professional growth. Elevate your
                                career with abundant opportunities to learn, innovate, and thrive in our dynamic
                                environment.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row g-4 mt-md-5 mt-2">
                <div class="col-md-4 col-12">
                    <div class="card p-2 border-0 shadow-lg rounded-4">
                        <img src="assets/img/landing-new/careers/career-five.png" class="card-img-top ps-2" alt="Image">
                        <div class="card-body">
                            <h5 class="card-title fw-bold">Work-Life Balance</h5>
                            <p class="card-text text-secondary justify-text"> Experience the perfect blend of
                                career ambition and life harmony. We
                                prioritize your well-being, empowering you to excel at work and savor your moments.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 col-12">
                    <div class="card p-2 border-0 shadow-lg rounded-4">
                        <img src="assets/img/landing-new/careers/career-six.png" class="card-img-top ps-2" alt="Image">
                        <div class="card-body">
                            <h5 class="card-title fw-bold">Innovative Projects</h5>
                            <p class="card-text text-secondary justify-text"> Thrive on exciting, innovative
                                projects that challenge your skills and
                                inspire groundbreaking solutions. Join us in shaping the future.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 col-12">
                    <div class="card p-2 border-0 shadow-lg rounded-4">
                        <img src="assets/img/landing-new/careers/career-seven.png" class="card-img-top ps-2"
                             alt="Image">
                        <div class="card-body">
                            <h5 class="card-title fw-bold">Medical Insurance</h5>
                            <p class="card-text text-secondary justify-text">We believe your health and
                                well-being are our top priorities. We offer
                                medical insurance to acquire benefits that are relevant to your health.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container-fluid mt-5">
    <div class="container">
        <p class="fs-1 fw-bold text-center">Open Positions</p>
        <div class="row g-4">
            <div class="col-md-6 col-12" *ngFor="let item of jobOpenings">
                <div class="card rounded-4 card-height shadow-lg border-0 p-3 hover-effect" style="cursor: pointer;">
                    <div class="card-body">
                        <p class="card-title fs-5 fw-bold primary-blue">{{ item.title }}</p>
                        <p class="fs-6"><span class="fw-bold">Qualification:</span> <span
                            class="text-secondary"> {{ item.qualification }}</span></p>
                        <p class="fs-6"><span class="fw-bold">Years of Experience:</span> <span
                            class="text-secondary"> {{ item.experience }}</span></p>
                        <p class="fs-6"><span class="fw-bold">Industry:</span> <span
                            class="text-secondary"> {{ item.industry }}</span></p>
                        <p class="mt-5 primary-blue fs-6 fw-bold" (click)="goTo(item?.id)">
                            Learn More <i class="fa fa-arrow-right" aria-hidden="true"></i>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid mt-5 bg-light-pink py-5">
        <div class="container">
            <p class="fs-1 fw-bold text-center">Need any further assistance?</p>
            <p class="fs-6 text-center text-secondary">Feel free to reach out for any inquiries or
                assistance.</p>
            <div class="text-center">
                <button class="bg-color-blue px-5 py-3 border-0 fs-6 mt-5 color-white hover-effect"
                        (click)="goto('/contact')">
                    Book Appointment <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    </div>

    <div class="container-fluid mt-5 py-5"
         style="background-image: url('/assets/img/landing-new/footer/footer-bg.png');">
        <div class="container">
            <div class="row text-center text-md-start">
                <div class="col-md-6 col-12 fs-1 color-white fw-bold mb-3 mb-md-0">
                    Ready to take your Business’s IT to the next level?
                </div>
                <div class="col-md-6 col-12 text-md-end">
                    <button class="bg-F5FCFF px-5 py-3 border-0 fs-6 fw-bold hover-effect" (click)="goto('/contact')">
                        Contact Us <i class="fa fa-arrow-right" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>

