import {Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {ContactUsService} from "../../../shared/services/contact-us.service";
import {AlertService} from "../../../shared/services/alert.service";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-argus',
  templateUrl: './argus.component.html',
  styleUrls: ['./argus.component.scss']
})
export class ArgusComponent implements OnInit {
    @ViewChild('contactFormModal') contactFormModal!: TemplateRef<any>;
    @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
    isModalOpen = false;
    contactForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    successMessage: string;
    powerfullToUse = [
        {text: 'Communication'},
        {text: 'Task Automation'},
        {text: 'Optimization'},
        {text: 'Centralized Information'},
        {text: 'Collaboration'},
        {text: 'Security'},
    ];
    modules = [
        {text: 'Admissions Management'},
        {text: 'Attendance Management'},
        {text: 'Fees Management'},
        {text: 'Exams Management'},
        {text: 'Library Management'},
        {text: 'Payroll Management'},
        {text: 'Organized Department'},
        {text: 'Record Management'},
    ];
    features = [
        {icon: 'assets/img/landing-new/argus/monitoring.png', text: 'AI-Powered Monitoring'},
        {icon: 'assets/img/landing-new/argus/analytics.png', text: 'Reporting and Analytics'},
        {icon: 'assets/img/landing-new/argus/custom.png', text: 'Customizable Exam Creation'},
        {icon: 'assets/img/landing-new/argus/user.png', text: 'User-Friendly Interface'},
        {icon: 'assets/img/landing-new/argus/multi.png', text: 'Multi-Device Compatibility'},
        {icon: 'assets/img/landing-new/argus/hybrid.png', text: 'Hybrid Scheduling'},
        {icon: 'assets/img/landing-new/argus/automated.png', text: 'Automated Proctoring'},
        {icon: 'assets/img/landing-new/argus/scalability.png', text: 'Scalability'}

    ];
    whyChoose = [
        {
            header: 'Enhance Academic Integrity',
            text: 'Proctoring features, including real-time intervention and AI monitoring, deter cheating, ensure exam validity, protect content, and verify identities with ID verification tools.'
        },
        {
            header: 'Simplify Exam Management',
            text: 'The intuitive interface simplifies exam creation, configuration, and deployment, while our secure, scalable cloud-based platform ensures reliable access and data protection.'
        },
        {
            header: 'Workflow Management',
            text: 'Automate exam delivery, schedule in advance, and receive instant auto-graded results. Create centralized detailed reports to refine assessments and improve learning outcomes.'
        },
        {
            header: 'Reduce Administrative Burden',
            text: 'Centralize exam management by automating tasks like scheduling, delivery, & grading, allowing for personalized student feedback & eliminating the need for multiple software programs.'
        },
        {
            header: 'Dedicated Support Team',
            text: 'The team of experts is available to address technical questions, ensure a smooth exam experience, and offer cost-effective and flexible EMS software compared to other providers.'
        },
        {
            header: 'Comprehensive Reporting',
            text: 'Gain valuable insights with our detailed analytics and reporting tools. Track performance, identify trends, and make informed decisions to improve exam outcomes.'
        },
    ];
    offerings = [
        {
            text: 'Accounting Module'
        },
        {
            text: 'Academic Team Management'
        },
        {
            text: 'Group Collaboration Software'
        },
        {
            text: 'Online Examination Platform'
        },
        {
            text: 'Mobile Application'
        },
    ];

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private contactService: ContactUsService,
        private alertService: AlertService,
        private title : Title,
        private meta : Meta,

    ) {
    }

    ngOnInit(): void {
        this.refresh();
        this.updateMetaTags()
    }

    refresh() {
        this.contactForm = this.fb.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            contact: [''],
            message: ['', Validators.required]
        });
    }

    openModal() {
        this.isModalOpen = true;
    }

    closeModal() {
        this.isModalOpen = false;
    }

    handleOverlayClick(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (!target.closest('.modal-dialog')) {
            this.closeModal();
        }
    }

    submit() {
        this.submitted = true;
        if (!this.contactForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().name) ? this.contactForm.getRawValue().name : '',
                'email': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().email) ? this.contactForm.getRawValue().email : '',
                'contact': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().contact) ? this.contactForm.getRawValue().contact : '',
                'message': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().message) ? this.contactForm.getRawValue().message : '',
            };
            this.contactService.create(params).subscribe(data => {
                this.successMessage = 'Your form has been submitted successfully!';
                this.submitted = false;
                this.contactForm.patchValue({
                    'name': '',
                    'email': '',
                    'contact': '',
                    'message': ''
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                    if (error['error']['errors'].message && error['error']['errors'].message.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].message[0]);
                        return;
                    }
                }
            });
        }
    }

    playVideo() {
        const video = this.videoElement.nativeElement;
        const thumbnail = document.getElementById('video-thumbnail');
        if (video) {
            video.classList.remove('d-none');
            video.play();
            if (thumbnail) {
                thumbnail.style.display = 'none';
            }
            const playButton = document.querySelector('.play-button');
            if (playButton) {
                playButton.classList.add('d-none');
            }
        }
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }

    updateMetaTags() {
        this.title.setTitle('Argus - Secured Exam Management System Software From Volmint ');
        this.meta.updateTag({name: 'description', content: 'Ensure a fair examination process with Argus, an advanced exam management software. Simplify proctoring, monitor exams, and safeguard academic integrity'});
        this.meta.updateTag({name: 'keyword', content: 'angular, seo, meta tags'});
    }
}
